import React, { useEffect, useState } from "react"
import Breadcrumbs from "../../../../components/Common/Breadcrumb"
import {
    Container,
    Row,
    Col,
    Nav,
    NavItem,
    NavLink,
    TabContent,
    TabPane,
} from "reactstrap"
import classnames from "classnames"
import Profile from "./Profile"
import Invoices from "./Invoices"
import DietPlan from "./DietPlan"

import StickyNote from "./StickyNote"
import Notes from "./Notes"
import Files from "./Files"
import FollowUp from "./FollowUp"
import { useLocation, useNavigate, useParams } from "react-router-dom"

// import Loader from "pages/Separate/Loader"

// components

const EditClient = () => {
    const [fixedSideTab, setFixedSideTab] = useState(false)

    const { client_id } = useParams()

    const location = useLocation();
    const receivedData = location.state?.data;

    let tabIndex = "1";
    if (receivedData) {
        if (receivedData?.noteId == '0') {
            tabIndex = "4"
        } else if (receivedData?.noteId != '0') {
            tabIndex = "3"
        }
    }

    const [verticalActiveTab, setverticalActiveTab] = useState(tabIndex)

    const navigate = useNavigate()

    useEffect(() => {
        if (localStorage.getItem("sAdminUser") && localStorage.getItem("superId")) {
            navigate('/dashboard')
        }
    }, [])


    document.addEventListener("scroll", () => {
        if (window.scrollY > 68) {
            setFixedSideTab(true)
        } else {
            setFixedSideTab(false)
        }
    })

    document.title = "Edit Client | Nutri Connect Pro"

    const toggleVertical = tab => {
        if (verticalActiveTab !== tab) {
            setverticalActiveTab(tab)
        }
    }

    return (
        <React.Fragment>
            {/* {loading && <Loader />} */}
            <div className="page-content mb-4">
                <Container fluid>
                    <Breadcrumbs title={"Dashboard"} breadcrumbItem={"Edit Client"} />

                    <Row className="gx-4 justify-content-between di-flex align-items-top">
                        <Col md="2" className="px-0">
                            <Nav
                                pills
                                className={`side-tab flex-column px-2 py-3
                            ${fixedSideTab ? "fixed-side-tab" : ""} h-100`}
                            >
                                <NavItem>
                                    <NavLink
                                        style={{ cursor: "pointer" }}
                                        className={classnames({
                                            "mb-2": true,
                                            active: verticalActiveTab === "1",
                                        })}
                                        onClick={() => {
                                            toggleVertical("1")
                                        }}
                                    >
                                        Profile
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink
                                        style={{ cursor: "pointer" }}
                                        className={classnames({
                                            "mb-2": true,
                                            active: verticalActiveTab === "2",
                                        })}
                                        onClick={() => {
                                            toggleVertical("2")
                                        }}
                                    >
                                        Diet Plan
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink
                                        style={{ cursor: "pointer" }}
                                        className={classnames({
                                            "mb-2": true,
                                            active: verticalActiveTab === "3",
                                        })}
                                        onClick={() => {
                                            toggleVertical("3")
                                        }}
                                    >
                                        Notes
                                    </NavLink>
                                </NavItem>

                                <NavItem>
                                    <NavLink
                                        style={{ cursor: "pointer" }}
                                        className={classnames({
                                            "mb-2": true,
                                            active: verticalActiveTab === "4",
                                        })}
                                        onClick={() => {
                                            toggleVertical("4")
                                        }}
                                    >
                                        Follow Up
                                    </NavLink>
                                </NavItem>

                                <NavItem>
                                    <NavLink
                                        style={{ cursor: "pointer" }}
                                        className={classnames({
                                            "mb-2": true,
                                            active: verticalActiveTab === "5",
                                        })}
                                        onClick={() => {
                                            toggleVertical("5")
                                        }}
                                    >
                                        Invoices
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink
                                        style={{ cursor: "pointer" }}
                                        className={classnames({
                                            "mb-2": true,
                                            active: verticalActiveTab === "6",
                                        })}
                                        onClick={() => {
                                            toggleVertical("6")
                                        }}
                                    >
                                        Files
                                    </NavLink>
                                </NavItem>
                            </Nav>
                        </Col>
                        <Col md="10 pe-0">
                            <div className=" bg-transparent mb-0">
                                <TabContent
                                    activeTab={verticalActiveTab}
                                    className="text-muted mt-4 mt-md-0"
                                >
                                    <TabPane tabId="1">
                                        <Profile client_id={client_id} />
                                    </TabPane>
                                    <TabPane tabId="2">
                                        <DietPlan client_id={client_id} verticalActiveTab={verticalActiveTab} />
                                    </TabPane>
                                    <TabPane tabId="3">
                                        <Notes client_id={client_id} receivedData={receivedData} />
                                    </TabPane>
                                    <TabPane tabId="4">
                                        <FollowUp client_id={client_id} verticalActiveTab={verticalActiveTab} receivedData={receivedData} />
                                    </TabPane>
                                    <TabPane tabId="5">
                                        <Invoices client_id={client_id} />
                                    </TabPane>
                                    <TabPane tabId="6">
                                        <Files client_id={client_id} />
                                    </TabPane>
                                </TabContent>
                            </div>
                        </Col>
                    </Row>
                </Container>
                <StickyNote verticalActiveTab={verticalActiveTab} client_id={client_id} />

            </div>
        </React.Fragment>
    )
}

export default EditClient
