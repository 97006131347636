import React, { useState, useEffect, Fragment, useMemo } from "react"
import { Col, Container, Row, Table, Button, Modal } from "reactstrap"
//Import Breadcrumb
import Breadcrumbs from "../../../../components/Common/Breadcrumb"
import { Card, Form } from "reactstrap"

import { Link, useNavigate } from "react-router-dom"
import { CardBody } from "reactstrap"
import SearchFilter from "components/Common/SearchFilter"

import {
  useTable,
  useGlobalFilter,
  useSortBy,
  useFilters,
  useExpanded,
  usePagination,
} from "react-table"
import axios from "axios"
import Loader from "pages/Loader"
import moment from "moment"
import { getDecryptedData, getEncryptedData } from "pages/Config"

const Invoices = () => {
  const [allInvoices, setAllInvoices] = useState([])
  const [loading, setLoading] = useState(false)

  const [paymentModal, setPaymentModal] = useState(false)
  const [paymentHistoryModal, setPaymentHistoryModal] = useState(false)
  const [paymentDate, setPaymentDate] = useState(
    moment(new Date()).format("YYYY-MM-DD")
  )
  const [paymentAmount, setPaymentAmount] = useState("")
  const [paymentType, setPaymentType] = useState("")

  const [invoiceId, setInvoiceId] = useState("")
  const [clientId, setClientId] = useState("")
  const [addedBy, setAddedBy] = useState("")

  const [totalAmt, setTotalAmt] = useState(0)
  const [balanceAmt, setBalanceAmt] = useState(0)

  const [paymentHistory, setPaymentHistory] = useState([])

  const [unPaidAmount, setUnpaidAmount] = useState("")

  const navigate = useNavigate()

  useEffect(() => {
    if (localStorage.getItem("sAdminUser") && localStorage.getItem("superId")) {
      navigate("/dashboard")
    }
  }, [])

  // getting all invoices
  useEffect(() => {
    setLoading(true)

    const formData = new FormData()

    formData.append("added_by", localStorage.getItem("adminId"))

    axios
      .post("https://api.nutriconnectpro.com/api/getinvoices", formData, {
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      })
      .then(response => {
        setLoading(false)

        // console.log(response)

        const originalData = response.data.data.map(invoice => ({
          ...invoice,
          customer_name: getDecryptedData(invoice.customer_name),
          terms: getDecryptedData(invoice.terms),
          customer_note: getDecryptedData(invoice.customer_note),
          discount: getDecryptedData(invoice.discount),
          subtotal: getDecryptedData(invoice.subtotal),
          service_details: getDecryptedData(invoice.service_details),
          invoice_date: getDecryptedData(invoice.invoice_date),
          duedate: getDecryptedData(invoice.duedate),
          startdate: getDecryptedData(invoice.startdate),
          enddate: getDecryptedData(invoice.enddate),
          total: Number(getDecryptedData(invoice.total)),
          unpaid_amount: Number(getDecryptedData(invoice.unpaid_amount)),
        }))

        setAllInvoices(originalData)
      })
      .catch(err => console.log(err))
  }, [])

  useEffect(() => {
    let tempTotal = 0
    let tempBalance = 0
    allInvoices?.forEach(invoice => {
      tempTotal += Number(invoice.total)
      tempBalance += Number(invoice.unpaid_amount)
    })

    setTotalAmt(tempTotal)
    setBalanceAmt(tempBalance)
  }, [allInvoices])

  document.title = "Invoices | Nutri Connect Pro"

  const onDeleteInvoice = invoice_id => {
    swal({
      title: "Are you sure?",
      text: "do you really want to delete this Invoice",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then(willDelete => {
      if (willDelete) {
        const formData = new FormData()

        formData.append("invoice_id", invoice_id)
        formData.append("added_by", localStorage.getItem("adminId"))

        axios
          .post(
            "https://api.nutriconnectpro.com/api/delete_invoice",
            formData,
            {
              headers: {
                Accept: "application/json",
                "Content-Type": "multipart/form-data",
              },
            }
          )
          .then(response => {
            const originalData = response.data.data.map(invoice => ({
              ...invoice,
              customer_name: getDecryptedData(invoice.customer_name),
              terms: getDecryptedData(invoice.terms),
              customer_note: getDecryptedData(invoice.customer_note),
              discount: getDecryptedData(invoice.discount),
              subtotal: getDecryptedData(invoice.subtotal),
              service_details: getDecryptedData(invoice.service_details),
              invoice_date: getDecryptedData(invoice.invoice_date),
              duedate: getDecryptedData(invoice.duedate),
              startdate: getDecryptedData(invoice.startdate),
              enddate: getDecryptedData(invoice.enddate),
              total: Number(getDecryptedData(invoice.total)),
              unpaid_amount: Number(getDecryptedData(invoice.unpaid_amount)),
            }))

            setAllInvoices(originalData)
            swal("Invoice has been deleted successfully!", {
              icon: "success",
            })
          })
          .catch(err => {
            swal("Something Went Wront", "", "error")
            console.log(err)
          })
      }
    })
  }

  const columns = useMemo(
    () => [
      {
        Header: "ID",
        accessor: "id",
        filterable: true,
        disableFilters: true,
        className: "col-md-1 text-center",
        Cell: cellProps => <span>{cellProps.row.index + 1}</span>,
      },
      {
        Header: "Invoice No",
        accessor: "invoice_number",
        disableFilters: true,
        filterable: true,
        className: "col-md-2 text-center",
      },
      {
        Header: "Customer Name",
        accessor: "customer_name",
        disableFilters: true,
        filterable: true,
      },
      {
        Header: "Total Amount",
        accessor: "total",
        disableFilters: true,
        filterable: true,
        className: "col-md-1 text-center",
        Cell: cellProps => (
          <span>{Number(cellProps.row.original.total).toFixed(2)}</span>
        ),
      },
      {
        Header: "Balance Amount",
        accessor: "unpaid_amount",
        disableFilters: true,
        filterable: true,
        className: "col-md-1 text-center",
        Cell: cellProps => (
          <span>{Number(cellProps.row.original.unpaid_amount).toFixed(2)}</span>
        ),
      },
      {
        Header: "Action",
        disableFilters: true,
        accessor: "view",
        className: "col-md-3 text-center",
        Cell: cellProps => {
          return (
            <div>
              {cellProps.row.original.unpaid_amount == 0 ? (
                <button className="btn btn-success btn-sm">
                  <i className="fas fa-money-bill-wave me-1"></i> Paid
                </button>
              ) : (
                <button
                  onClick={() => {
                    setAddedBy(cellProps.row.original.added_by)
                    setClientId(cellProps.row.original.client_id)
                    setInvoiceId(cellProps.row.original.id)
                    setPaymentDate(moment(new Date()).format("YYYY-MM-DD"))
                    setPaymentModal(true)
                    setUnpaidAmount(cellProps.row.original.unpaid_amount)
                  }}
                  className="btn bg-light-blue btn-sm"
                >
                  <i className="fas fa-money-bill-wave me-1"></i> Payment
                </button>
              )}

              <button
                onClick={() => {
                  if (
                    viewPaymentHistory(
                      cellProps.row.original.added_by,
                      cellProps.row.original.client_id,
                      cellProps.row.original.id
                    )
                  ) {
                    setPaymentHistoryModal(true)
                  }
                }}
                title="payment history"
                className="btn btn-secondary btn-sm ms-2"
              >
                <i className="fas fa-history"></i>
              </button>

              <Link
                to={`/view-invoice/${cellProps.row.original.id}`}
                className="btn btn-outline-secondary btn-sm ms-2"
              >
                <i className="fas fa-eye"></i>
              </Link>
              <Link
                to={`/edit-invoice/${cellProps.row.original.id}`}
                className="btn btn-outline-secondary btn-sm ms-2"
                title="Edit Invoice"
              >
                <i className="fas fa-pen"></i>
              </Link>
              <button
                onClick={() => onDeleteInvoice(cellProps.row.original.id)}
                className="btn btn-red btn-sm ms-2"
              >
                <i className="fas fa-trash"></i>
              </button>
            </div>
          )
        },
      },
    ],
    []
  )

  const data = useMemo(() => allInvoices, [allInvoices])

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state,
    preGlobalFilteredRows,
    setGlobalFilter,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      initialState: {
        sortBy: [
          {
            desc: true,
          },
        ],
      },
    },
    useGlobalFilter,
    useFilters,
    useSortBy,
    useExpanded,
    usePagination
  )

  const generateSortingIndicator = column => {
    return column.isSorted ? (column.isSortedDesc ? " 🔽" : " 🔼") : ""
  }

  const onChangeInSelect = event => {
    setPageSize(Number(event.target.value))
  }

  // make payment
  const makePayment = async e => {
    e.preventDefault()

    try {
      if (paymentAmount > 0) {
        const formData = new FormData()
        setPaymentAmount

        formData.append("invoice_id", invoiceId)
        formData.append("added_by", addedBy)
        formData.append("client_id", clientId)
        formData.append("amount", getEncryptedData(paymentAmount))
        formData.append("payment_method", getEncryptedData(paymentType))
        formData.append("date", getEncryptedData(paymentDate))
        formData.append(
          "unpaid_amount",
          getEncryptedData(unPaidAmount - paymentAmount)
        )

        const response = await axios.post(
          "https://api.nutriconnectpro.com/api/add_payment",
          formData,
          {
            headers: {
              Accept: "application/json",
              "Content-Type": "multipart/form-data",
            },
          }
        )

        const originalData = response.data.data.map(invoice => ({
          ...invoice,
          customer_name: getDecryptedData(invoice.customer_name),
          terms: getDecryptedData(invoice.terms),
          customer_note: getDecryptedData(invoice.customer_note),
          discount: getDecryptedData(invoice.discount),
          subtotal: getDecryptedData(invoice.subtotal),
          service_details: getDecryptedData(invoice.service_details),
          invoice_date: getDecryptedData(invoice.invoice_date),
          duedate: getDecryptedData(invoice.duedate),
          startdate: getDecryptedData(invoice.startdate),
          enddate: getDecryptedData(invoice.enddate),
          total: Number(getDecryptedData(invoice.total)),
          unpaid_amount: Number(getDecryptedData(invoice.unpaid_amount)),
        }))

        setAllInvoices(originalData)

        setPaymentModal(false)

        swal("Payment done successfully!", {
          icon: "success",
        }).then(() => {
          setPaymentAmount("")
          setPaymentType("")
          setPaymentDate("")
        })
      } else {
        swal("", `Invalid Payment Amount (${paymentAmount})`, "error")
      }
    } catch (error) {
      swal("Something Went Wront", "", "error")
      console.log(error)
    }
  }

  const viewPaymentHistory = async (added_by, client_id, inv_id) => {
    try {
      const formData = new FormData()

      formData.append("invoice_id", inv_id)
      formData.append("added_by", added_by)
      formData.append("client_id", client_id)

      const response = await axios.post(
        "https://api.nutriconnectpro.com/api/payments",
        formData,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "multipart/form-data",
          },
        }
      )

      const OriginalData = response.data.data.map(history => ({
        ...history,
        payment_method: getDecryptedData(history.payment_method),
        amount: getDecryptedData(history.amount),
        date: getDecryptedData(history.date),
      }))

      setPaymentHistory(OriginalData)

      return true
    } catch (error) {
      swal("Something Went Wront", "", "error")
      console.log(error)

      return false
    }
  }

  return (
    <React.Fragment>
      {loading && <Loader />}
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title={"Dashboard"} breadcrumbItem={"Invoices"} />
          <Row>
            <Card>
              <CardBody className="py-4 px-3">
                <Row className=" justify-space-between flex-md-row flex-column-reverse">
                  <Col md={1} className="px-0">
                    <select
                      className="form-select border-radius d-md-block d-none"
                      value={pageSize}
                      onChange={onChangeInSelect}
                    >
                      {[10, 20, 30, 40, 50].map(pageSize => (
                        <option key={pageSize} value={pageSize}>
                          Show {pageSize}
                        </option>
                      ))}
                    </select>
                  </Col>

                  <div className="d-flex col-md-4 ps-5 justify-content-center">
                    <div className="records record-light-purple">
                      <h5>{totalAmt.toFixed(2)}</h5>
                      <h5 className="mb-0">Total</h5>
                    </div>

                    <div className="records record-light-green">
                      <h5>{(totalAmt - balanceAmt).toFixed(2)}</h5>
                      <h5 className="mb-0">Received</h5>
                    </div>

                    <div className="records me-4 record-light-pink">
                      <h5>{balanceAmt.toFixed(2)}</h5>
                      <h5 className="mb-0">Balance</h5>
                    </div>
                  </div>

                  <div className="d-flex col-md-7 justify-content-md-end ps-md-0 justify-content-between align-items-start ">
                    <div className="ms-md-2">
                      <SearchFilter
                        preGlobalFilteredRows={preGlobalFilteredRows}
                        globalFilter={state.globalFilter}
                        setGlobalFilter={setGlobalFilter}
                      />
                    </div>

                    <Link
                      to="/admin-dashboard"
                      className="btn btn-dark border-radius ms-4"
                    >
                      <i className="fas fa-arrow-left me-3"></i>
                      Back
                    </Link>
                    <Link
                      to="/add-invoice"
                      className="btn btn-green bg-light-yellow border-radius ms-3"
                    >
                      <i className="fas fa-plus me-2"></i>
                      Add Invoice
                    </Link>
                  </div>
                </Row>

                <div className="table-responsive mt-4">
                  <Table
                    bordered
                    {...getTableProps()}
                    className="w-800 responsive"
                    style={{ verticalAlign: "middle" }}
                  >
                    <thead className="table-green table-nowrap">
                      {headerGroups.map(headerGroup => (
                        <tr
                          key={headerGroup.id}
                          {...headerGroup.getHeaderGroupProps()}
                        >
                          {headerGroup.headers.map(column => (
                            <th key={column.id} className={column.className}>
                              <div {...column.getSortByToggleProps()}>
                                {column.render("Header")}
                                {generateSortingIndicator(column)}
                              </div>
                              {/* <Filter column={column} /> */}
                            </th>
                          ))}
                        </tr>
                      ))}
                    </thead>

                    <tbody className="row-hover" {...getTableBodyProps()}>
                      {page.map(row => {
                        prepareRow(row)
                        return (
                          <Fragment key={row.getRowProps().key}>
                            <tr>
                              {row.cells.map(cell => {
                                return (
                                  <td
                                    key={cell.id}
                                    data-label={cell.column.Header}
                                    {...cell.getCellProps()}
                                    className={cell.column.className}
                                  >
                                    {cell.render("Cell")}
                                  </td>
                                )
                              })}
                            </tr>
                          </Fragment>
                        )
                      })}

                      {page.length === 0 && (
                        <tr>
                          <td colSpan={7} className="text-center">
                            No Invoice Found
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </Table>
                </div>

                <Row className="justify-content-md-end justify-content-center align-items-center mt-3">
                  <Col className="col-md-auto">
                    <div className="d-flex gap-1">
                      <Button
                        className="btn-green bg-light-purple"
                        onClick={() => gotoPage(0)}
                        disabled={!canPreviousPage}
                      >
                        {"<<"}
                      </Button>
                      <Button
                        className="btn-green bg-light-purple"
                        onClick={previousPage}
                        disabled={!canPreviousPage}
                      >
                        {"<"}
                      </Button>
                    </div>
                  </Col>
                  <Col className="col-md-auto d-none d-md-block">
                    Page{" "}
                    <strong>
                      {pageIndex + 1} of {pageOptions.length}
                    </strong>
                  </Col>

                  <Col className="col-md-auto">
                    <div className="d-flex gap-1">
                      <Button
                        className="btn-green bg-light-purple"
                        onClick={nextPage}
                        disabled={!canNextPage}
                      >
                        {">"}
                      </Button>
                      <Button
                        className="btn-green bg-light-purple"
                        onClick={() => gotoPage(pageCount - 1)}
                        disabled={!canNextPage}
                      >
                        {">>"}
                      </Button>
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Row>

          {/* Payment Modal */}
          <Modal
            isOpen={paymentModal}
            size="md"
            className="bd-custom-md-3"
            centered
          >
            <div className="modal-header">
              <h5 className="modal-title mt-0">Make Payment</h5>
              <button
                type="button"
                onClick={() => {
                  setPaymentModal(false)
                }}
                className="btn-modal-close"
              >
                <span aria-hidden="true" className="btn-modal-span">
                  &times;
                </span>
              </button>
            </div>
            <div className="modal-body">
              <Form className="row" onSubmit={makePayment}>
                <div className="mb-3 col-12">
                  <label htmlFor="formrow-firstname-Input">Amount To Pay</label>
                  <input
                    type="text"
                    value={paymentAmount}
                    onChange={e => setPaymentAmount(Number(e.target.value))}
                    className="border-radius form-field"
                    placeholder="Enter Amount"
                    required
                  />
                </div>
                <div className="mb-3 col-12">
                  <label htmlFor="">Payment Method</label>
                  <select
                    value={paymentType}
                    onChange={e => setPaymentType(e.target.value)}
                    required
                    className="border-radius form-field"
                  >
                    <option value="">Select Payment Method</option>
                    <option value="Cash Payment">Cash Payment</option>
                    <option value="Debit Card">Debit Card</option>
                    <option value="Net Banking">Net Banking</option>
                  </select>
                </div>
                <div className="mb-3 col-12">
                  <label htmlFor="formrow-firstname-Input">Date</label>
                  <input
                    type="date"
                    value={paymentDate}
                    onChange={e => setPaymentDate(e.target.value)}
                    className="border-radius form-field"
                    required
                  />
                </div>
                <div className="mt-2 text-end">
                  <button
                    type="submit"
                    className="border-radius btn btn-green btn-yellow"
                  >
                    <i className="bx bx-send me-2"></i>
                    Pay Now
                  </button>
                </div>
              </Form>
            </div>
          </Modal>
          {/* -------------- */}

          {/* Payment History Modal */}
          <Modal
            isOpen={paymentHistoryModal}
            size="md"
            className="bd-custom-md-3"
            centered
          >
            <div className="modal-header">
              <h5 className="modal-title mt-0">Payment History</h5>
              <button
                type="button"
                onClick={() => {
                  setPaymentHistoryModal(false)
                }}
                className="btn-modal-close"
              >
                <span aria-hidden="true" className="btn-modal-span">
                  &times;
                </span>
              </button>
            </div>
            <div className="modal-body">
              <table className="table table-bordered text-center">
                <thead className="table-green">
                  <tr>
                    <th>ID</th>
                    <th>Date</th>
                    <th>Amount</th>
                    <th>Payment Type</th>
                  </tr>
                </thead>

                <tbody>
                  {paymentHistory.map((payment, i) => {
                    return (
                      <tr key={payment.id}>
                        <td>{i + 1}</td>
                        <td> {moment(payment.date).format("DD-MM-YYYY")}</td>
                        <td>{payment.amount}</td>
                        <td>{payment.payment_method}</td>
                      </tr>
                    )
                  })}
                  {paymentHistory.length === 0 && (
                    <tr className="text-center">
                      <td colSpan={4}> No History Found </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </Modal>
          {/* -------------- */}
        </Container>
      </div>
    </React.Fragment>
  )
}

export default Invoices
