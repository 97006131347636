export * from "./layout/actions";

// Authentication module
export * from "./auth/register/actions";
export * from "./auth/login/actions";
export * from "./auth/forgetpwd/actions";
export * from "./auth/profile/actions";

//Ecommerce
export * from "./e-commerce/actions";

//Calendar
export * from "./calendar/actions";

//chat
export * from "./chat/actions";

//crypto
export * from "./crypto/actions";

//invoices
export * from "./invoices/actions";

//jobs
export * from "./jobs/actions";

// projects
export * from "./projects/actions";

// tasks
export * from "./tasks/actions";

// contacts
export * from "./contacts/actions";

// contacts
export * from "./mails/actions";

//dashboard
export * from "./dashboard/actions";

//dashboard-crypto
export * from "./dashboard-crypto/actions";

//dashboard-saas
export * from "./dashboard-saas/actions";

//dashboard-blog
export * from "./dashboard-blog/actions";

//dashboard-jobs
export * from "./dashboard-jobs/actions";

import {
    GET_EVENTS,
    GET_EVENTS_FAIL,
    GET_EVENTS_SUCCESS,
    ADD_NEW_EVENT,
    ADD_EVENT_SUCCESS,
    ADD_EVENT_FAIL,
    UPDATE_EVENT,
    UPDATE_EVENT_SUCCESS,
    UPDATE_EVENT_FAIL,
    DELETE_EVENT,
    DELETE_EVENT_SUCCESS,
    DELETE_EVENT_FAIL,
    GET_CATEGORIES,
    GET_CATEGORIES_SUCCESS,
    GET_CATEGORIES_FAIL,
  } from "./actionTypes";
  
  export const getEvents = () => ({
    type: GET_EVENTS,
  });
  
  export const getEventsSuccess = events => ({
    type: GET_EVENTS_SUCCESS,
    payload: events,
  });
  
  export const getEventsFail = error => ({
    type: GET_EVENTS_FAIL,
    payload: error,
  });
  
  export const addNewEvent = event => ({
    type: ADD_NEW_EVENT,
    payload: event,
  });
  
  export const addEventSuccess = event => ({
    type: ADD_EVENT_SUCCESS,
    payload: event,
  });
  
  export const addEventFail = error => ({
    type: ADD_EVENT_FAIL,
    payload: error,
  });
  
  export const updateEvent = event => ({
    type: UPDATE_EVENT,
    payload: event,
  });
  
  export const updateEventSuccess = event => ({
    type: UPDATE_EVENT_SUCCESS,
    payload: event,
  });
  
  export const updateEventFail = error => ({
    type: UPDATE_EVENT_FAIL,
    payload: error,
  });
  
  export const deleteEvent = event => ({
    type: DELETE_EVENT,
    payload: event,
  });
  
  export const deleteEventSuccess = event => ({
    type: DELETE_EVENT_SUCCESS,
    payload: event,
  });
  
  export const deleteEventFail = error => ({
    type: DELETE_EVENT_FAIL,
    payload: error,
  });
  
  export const getCategories = () => ({
    type: GET_CATEGORIES,
  });
  
  export const getCategoriesSuccess = categories => ({
    type: GET_CATEGORIES_SUCCESS,
    payload: categories,
  });
  
  export const getCategoriesFail = error => ({
    type: GET_CATEGORIES_FAIL,
    payload: error,
  });
  