import React, { useState, useEffect } from "react"
import { Col, Container, Row } from "reactstrap"
//Import Breadcrumb
import Breadcrumbs from "../../../../components/Common/Breadcrumb"
import {
  CardTabContent,
  TabContent,
  TabPane,
  Card,
  NavItem,
  NavLink,
} from "reactstrap"

import { Link, useNavigate } from "react-router-dom"
import { CardBody } from "reactstrap"

import Select from "react-select"
import axios from "axios"
import classnames from "classnames"

import { getEncryptedData, getDecryptedData } from "pages/Config"

const AddClient = () => {
  // states for fields
  const [fullname, setFullname] = useState("")
  const [gender, setGender] = useState("")
  const [country, setCountry] = useState("")
  const [state, setState] = useState("")
  const [city, setCity] = useState("")
  const [address, setAddress] = useState("")
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const [phone_number, setPhoneNumber] = useState("")
  // const [status, setStatus] = useState('');
  const [age, setAge] = useState("") // Assuming age is a number
  const [height, setHeight] = useState("") // Assuming height is a number
  const [weight, setWeight] = useState("") // Assuming weight is a number
  const [bmi_number, setBmiNumber] = useState("") // Assuming bmi_number is a number
  const [ideal_bodyweight, setIdealBodyweight] = useState("") // Assuming ideal_bodyweight is a number
  const [job_type, setJobType] = useState("")
  const [working_hours, setWorkingHours] = useState("")
  const [client_goal, setClientGoal] = useState("")
  const [physical_activity, setPhysicalActivity] = useState(null)
  const [activity, setActivity] = useState("")
  const [supplements, setSupplements] = useState("")
  const [family_history, setFamilyHistory] = useState("")
  const [heighest_weight, setHeighestWeight] = useState("") // Assuming heighest_weight is a number
  const [stomach_issue, setStomachIssue] = useState("")
  const [s_issue, setSIssue] = useState("")
  const [medical_issue, setMedicalIssue] = useState([])
  const [m_issue, setMIssue] = useState("")
  const [tried_losing_weight, setTriedLosingWeight] = useState(null)
  const [tried_activity, setTriedActivity] = useState("")
  const [cheat_meal, setCheatMeal] = useState("")
  const [meal_description, setMealDescription] = useState("")
  const [food_preference, setFoodPreference] = useState("")
  const [food_prefer_detail, setFoodPreferDetail] = useState("")
  const [surgery, setSurgery] = useState(null)
  const [surgery_detail, setSurgeryDetail] = useState("")
  const [likes, setLikes] = useState("")
  const [dislikes, setDislikes] = useState("")
  const [allergies, setAllergies] = useState("")
  const [cravings, setCravings] = useState("")
  const [cheat_meal_day, setCheatMealDay] = useState("")
  const [bodyFats, setBodyFats] = useState([])
  const [bodyFatsDesc, setBodyFatsDesc] = useState("")
  const [outside_food_detail, setOutsideFoodDetail] = useState("")
  const [smokeAlcohol, setSmokeAlcohol] = useState(null)
  const [addiction_detail, setAddictionDetail] = useState("")
  const [water_intake, setWaterIntake] = useState(0) // Assuming water_intake is a number
  const [sleep_cycle, setSleepCycle] = useState("")
  const [oil, setOil] = useState("")
  const [salt, setSalt] = useState("")
  const [flour, setFlour] = useState("")
  const [stress_level, setStressLevel] = useState("")
  const [sleeps_at, setSleepsAt] = useState("")
  const [wake_up, setWakeUp] = useState("")
  const [breakfast, setBreakfast] = useState({ foods: "", time: "" })
  const [mid_morning, setMidMorning] = useState({ foods: "", time: "" })
  const [lunch, setLunch] = useState({ foods: "", time: "" })
  const [evening_snack, setEveningSnack] = useState({ foods: "", time: "" })
  const [dinner, setDinner] = useState({ foods: "", time: "" })
  const [post_dinner, setPostDinner] = useState({ foods: "", time: "" })
  const [startdate_of_package, setStartdateOfPackage] = useState("")
  const [packageName, setPackageName] = useState("")
  const [menstrual_cycle, setMensCycle] = useState("")

  const [clientId, setClientId] = useState("")
  const [show_pass, setShowPass] = useState(true)

  // other states

  const [countries, setCountries] = useState([])
  const [states, setStates] = useState([])
  const [cities, setCities] = useState([])

  // states for Tabs

  const [activeTab, setactiveTab] = useState(1)
  const [passedSteps, setPassedSteps] = useState([1])

  function toggleTab(tab) {
    if (activeTab !== tab) {
      var modifiedSteps = [...passedSteps, tab]
      if (tab >= 1 && tab <= 3) {
        setactiveTab(tab)
        setPassedSteps(modifiedSteps)
      }
    }
  }

  const [fixedContent, setFixedContent] = useState(false)

  const [loading, setLoading] = useState(false)

  const [allPackages, setAllPackages] = useState([])

  const navigate = useNavigate()

  useEffect(() => {
    if (localStorage.getItem("sAdminUser") && localStorage.getItem("superId")) {
      navigate("/dashboard")
    }
  }, [])

  useEffect(() => {
    // getting Packages details
    const formData = new FormData()
    formData.append("added_by", localStorage.getItem("adminId"))

    axios
      .post("https://api.nutriconnectpro.com/api/getpackages", formData)
      .then(res => {
        setAllPackages(res.data.data)
      })
      .catch(err => console.log(err))
  }, [])

  // get all countries name
  useEffect(() => {
    axios
      .get("https://api.nutriconnectpro.com/api/country", {
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      })
      .then(response => {
        setCountries(response.data.data)
      })
      .catch(err => console.log(err))
  }, [])

  // get all states name
  const getStatesList = e => {
    setCountry(e.target.value)

    const formData = new FormData()
    formData.append("country_id", e.target.value)

    axios
      .post("https://api.nutriconnectpro.com/api/country_state", formData, {
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      })
      .then(response => {
        setStates(response.data.data)
      })
      .catch(err => console.log(err))
  }

  // get all cities name
  const getCitiesList = e => {
    setState(e.target.value)

    const formData = new FormData()
    formData.append("state_id", e.target.value)

    axios
      .post("https://api.nutriconnectpro.com/api/state_city", formData, {
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      })
      .then(response => {
        setCities(response.data.data)
      })
      .catch(err => console.log(err))
  }

  document.addEventListener("scroll", () => {
    if (window.scrollY > 110) {
      setFixedContent(true)
    } else {
      setFixedContent(false)
    }
  })

  //meta title
  document.title = "Add Client | Nutri Connect Pro "

  const colourStyles = {
    menuList: styles => ({
      ...styles,
      background: "white",
    }),
    menu: base => ({
      ...base,
      zIndex: 50,
    }),
  }

  const weekDays = [
    { label: "Sunday", value: "Sunday" },
    { label: "Monday", value: "Monday" },
    { label: "Tuesday", value: "Tuesday" },
    { label: "Wednesday", value: "Wednesday" },
    { label: "Thursday", value: "Thursday" },
    { label: "Friday", value: "Friday" },
    { label: "Saturday", value: "Saturday" },
  ]

  const medicalIssues = [
    { label: "Issue 1", value: "Issue 1" },
    { label: "Issue 2", value: "Issue 2" },
    { label: "Other", value: "Other" },
  ]

  const bodyFatsOptions = [
    { label: "Option 1", value: "Option 1" },
    { label: "Option 2", value: "Option 2" },
    { label: "Other", value: "Other" },
  ]

  // first form submit
  const handleFormSubmit1 = async e => {
    e.preventDefault()

    try {
      const formData = new FormData()

      formData.append("fullname", getEncryptedData(fullname))
      formData.append("gender", getEncryptedData(gender))
      formData.append("menstrual_cycle", getEncryptedData(menstrual_cycle))
      formData.append("email", email)
      formData.append("password", password)
      formData.append("phone_number", getEncryptedData(phone_number))
      formData.append("client_goal", getEncryptedData(client_goal))
      formData.append("country", country)
      formData.append("state", state)
      formData.append("city", city)
      formData.append("address", getEncryptedData(address))
      formData.append("startdate_of_package", startdate_of_package)
      formData.append("package", packageName)
      formData.append("added_by", localStorage.getItem("adminId"))

      const response = await axios.post(
        "https://api.nutriconnectpro.com/api/add_client",
        formData,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "multipart/form-data",
          },
        }
      )

      // console.log(response)

      setClientId(response.data.data.client_id)
      setLoading(false)

      if (response.data.data !== "email already in use") {
        swal("Client Data Saved successfully", {
          icon: "success",
        }).then(() => {
          setactiveTab(2)

          setFullname("")
          setGender("")
          setCountry("")
          setState("")
          setCity("")
          setAddress("")
          setEmail("")
          setPassword("")
          setPhoneNumber("")
          setStartdateOfPackage("")
          setPackageName("")
          setMensCycle("")

          window.scrollTo(0, 0)
        })
      } else if (response.data.data === "email already in use") {
        swal("Email already in use", {
          icon: "warning",
        })
      }
    } catch (error) {
      console.log(error)
      setLoading(false)
      swal("Something went wrong", "", "warning")
    }
  }

  // second form submit
  const handleFormSubmit2 = async e => {
    e.preventDefault()

    try {
      const formData = new FormData()

      formData.append("age", getEncryptedData(age))
      formData.append("height", getEncryptedData(height))
      formData.append("weight", getEncryptedData(weight))
      formData.append("bmi_number", getEncryptedData(bmi_number))
      formData.append("ideal_bodyweight", getEncryptedData(ideal_bodyweight))
      formData.append("job_type", getEncryptedData(job_type))
      formData.append("working_hours", getEncryptedData(working_hours))

      formData.append("physical_activity", getEncryptedData(physical_activity))
      formData.append("activity", getEncryptedData(activity))
      formData.append("supplements", getEncryptedData(supplements))
      formData.append("family_history", getEncryptedData(family_history))
      formData.append("heighest_weight", getEncryptedData(heighest_weight))
      formData.append("stomach_issue", getEncryptedData(stomach_issue))
      formData.append("s_issue", getEncryptedData(s_issue))
      formData.append(
        "medical_issue",
        getEncryptedData(JSON.stringify(medical_issue))
      )
      formData.append("m_issue", getEncryptedData(m_issue))
      formData.append(
        "tried_losing_weight",
        getEncryptedData(tried_losing_weight)
      )
      formData.append("tried_activity", getEncryptedData(tried_activity))

      formData.append(
        "body_fat_distribution",
        getEncryptedData(JSON.stringify(bodyFats))
      )
      formData.append("describe", getEncryptedData(bodyFatsDesc))

      formData.append("food_preference", getEncryptedData(food_preference))
      formData.append(
        "food_prefer_detail",
        getEncryptedData(food_prefer_detail)
      )
      formData.append("surgery", getEncryptedData(surgery))
      formData.append("surgery_detail", getEncryptedData(surgery_detail))

      formData.append("client_id", clientId)

      if (clientId !== "") {
        const response = await axios.post(
          "https://api.nutriconnectpro.com/api/edit_client_second",
          formData,
          {
            headers: {
              Accept: "application/json",
              "Content-Type": "multipart/form-data",
            },
          }
        )

        setLoading(false)

        swal("Client Data Saved successfully", {
          icon: "success",
        }).then(() => {
          setactiveTab(3)
          window.scrollTo(0, 0)
        })
      } else {
        swal("Cannot save this data before saving personal details", {
          icon: "error",
        })
      }
    } catch (error) {
      console.log(error)
      setLoading(false)
      swal("Something went wrong", "", "warning")
    }
  }

  // second form submit
  const handleFormSubmit3 = async e => {
    e.preventDefault()

    try {
      const formData = new FormData()

      formData.append("likes", getEncryptedData(likes))
      formData.append("dislikes", getEncryptedData(dislikes))
      formData.append("allergies", getEncryptedData(allergies))
      formData.append("cravings", getEncryptedData(cravings))
      formData.append(
        "cheat_meal",
        getEncryptedData(JSON.stringify(cheat_meal))
      )
      formData.append(
        "outside_food_detail",
        getEncryptedData(outside_food_detail)
      )
      formData.append("addiction", getEncryptedData(smokeAlcohol))
      formData.append("addiction_detail", getEncryptedData(addiction_detail))
      formData.append("water_intake", getEncryptedData(water_intake))
      formData.append("sleep_cycle", getEncryptedData(sleep_cycle))
      formData.append("oil", getEncryptedData(oil))
      formData.append("salt", getEncryptedData(salt))
      formData.append("flour", getEncryptedData(flour))
      formData.append("stress_level", getEncryptedData(stress_level))
      formData.append("sleeps_at", getEncryptedData(sleeps_at))
      formData.append("wake_up", getEncryptedData(wake_up))
      formData.append("breakfast", getEncryptedData(JSON.stringify(breakfast)))
      formData.append(
        "mid_morning",
        getEncryptedData(JSON.stringify(mid_morning))
      )
      formData.append("lunch", getEncryptedData(JSON.stringify(lunch)))
      formData.append(
        "evening_snack",
        getEncryptedData(JSON.stringify(evening_snack))
      )
      formData.append("dinner", getEncryptedData(JSON.stringify(dinner)))
      formData.append(
        "post_dinner",
        getEncryptedData(JSON.stringify(post_dinner))
      )
      formData.append("client_id", clientId)

      // clientId

      if (clientId !== "") {
        const response = await axios.post(
          "https://api.nutriconnectpro.com/api/edit_client_third",
          formData,
          {
            headers: {
              Accept: "application/json",
              "Content-Type": "multipart/form-data",
            },
          }
        )
        // console.log(response.data)
        setLoading(false)

        swal("All Data Saved successfully", {
          icon: "success",
        }).then(() => {
          navigate("/all-clients")
        })
      } else {
        swal("Cannot save this data before saving personal details", {
          icon: "error",
        })
      }
    } catch (error) {
      console.log(error)
      setLoading(false)
      swal("Something went wrong", "", "warning")
    }
  }

  return (
    <React.Fragment>
      {/* fixed buttons */}
      {/* <div className={`flex-column fixed-buttons ${fixedContent ? 'd-flex' : 'd-none'}`}>
                <button onClick={handleFormSubmit} className='btn btn-yellow border-radius fix-btn'>
                    <i className="bx bx-send fs-5 me-2"></i>
                    <span>Submit</span>
                </button>
                <button onClick={() => navigate(-1)} className='btn btn-dark border-radius fix-btn' >
                    <i className='fas fa-arrow-left me-2 icon-center'></i>
                    <span>Back</span>
                </button>
            </div> */}
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title={"Dashboard"} breadcrumbItem={"Add Client "} />

          <Row>
            {/* <form onSubmit={handleFormSubmit} className="px-0"> */}
            <div className="wizard clearfix px-0">
              <div className="steps clearfix">
                <ul>
                  <NavItem
                    className={`${
                      activeTab === 1 && "active-plan-tab"
                    } plan-tab`}
                  >
                    <NavLink
                      className={classnames({ current: activeTab === 1 })}
                      onClick={() => {
                        setactiveTab(1)
                      }}
                      disabled={!(passedSteps || []).includes(1)}
                    >
                      <span
                        className={`number ${
                          activeTab === 1 && "active-plan-bg"
                        } tab-circle`}
                      >
                        1
                      </span>{" "}
                      Personal Information
                    </NavLink>
                  </NavItem>
                  <NavItem
                    className={`${
                      activeTab === 2 && "active-plan-tab"
                    } plan-tab`}
                  >
                    <NavLink
                      className={classnames({ active: activeTab === 2 })}
                      onClick={() => {
                        setactiveTab(2)
                      }}
                      disabled={!(passedSteps || []).includes(2)}
                    >
                      <span
                        className={`number ${
                          activeTab === 2 && "active-plan-bg"
                        } tab-circle`}
                      >
                        2
                      </span>{" "}
                      Health Related Information
                    </NavLink>
                  </NavItem>
                  <NavItem
                    className={`${
                      activeTab === 3 && "active-plan-tab"
                    } plan-tab`}
                  >
                    <NavLink
                      className={classnames({ active: activeTab === 3 })}
                      onClick={() => {
                        setactiveTab(3)
                      }}
                      disabled={!(passedSteps || []).includes(3)}
                    >
                      <span
                        className={`number ${
                          activeTab === 3 && "active-plan-bg"
                        } tab-circle`}
                      >
                        3
                      </span>{" "}
                      Food Information & Consumption
                    </NavLink>
                  </NavItem>
                  {/* <NavItem
                                        className={`${activeTab === 4 && "active-plan-tab"} plan-tab`}
                                    >
                                        <NavLink
                                            className={classnames({ active: activeTab === 4 })}
                                            onClick={() => {
                                                setactiveTab(4)
                                            }}
                                            disabled={!(passedSteps || []).includes(4)}
                                        >
                                            <span className={`number ${activeTab === 4 && "active-plan-bg"} tab-circle`}>4</span> Approval Process
                                        </NavLink>
                                    </NavItem> */}
                </ul>
              </div>
              <div className="content clearfix px-0">
                <TabContent activeTab={activeTab} className="body">
                  <TabPane tabId={1}>
                    <form onSubmit={handleFormSubmit1} className="px-0">
                      <Card>
                        <CardBody>
                          <Row className="py-2">
                            <div className="col-md-6">
                              <h4 className="fw-bold text-purple">
                                Personal Information{" "}
                              </h4>
                            </div>
                            <div className="col-md-6 mb-4 text-end">
                              <button
                                type="button"
                                onClick={() => navigate(-1)}
                                className="btn btn-dark border-radius me-3"
                              >
                                <i className="fas fa-arrow-left me-3"></i>
                                Back
                              </button>
                              <button
                                type="submit"
                                className="btn btn-green btn-yellow border-radius"
                              >
                                {" "}
                                <i className="bx bx-send me-1"></i> Submit{" "}
                              </button>
                            </div>

                            <div className="col-12">
                              <Row className="justify-content-between">
                                <div className="col-12">
                                  <Row>
                                    <div className="col-md-4">
                                      <label htmlFor="" className="fs-5">
                                        Full Name{" "}
                                        <span className="text-danger">*</span>{" "}
                                      </label>
                                      <input
                                        type="text"
                                        value={fullname}
                                        onChange={e =>
                                          setFullname(e.target.value)
                                        }
                                        placeholder="Enter Full Name"
                                        className="form-field"
                                        required
                                      />
                                    </div>

                                    <div className="col-md-4">
                                      <Row>
                                        <label htmlFor="" className="fs-5">
                                          Gender{" "}
                                          <span className="text-danger">*</span>
                                        </label>
                                        <Row className="mt-2 pt-1">
                                          <div className="col-3">
                                            <input
                                              type="radio"
                                              onChange={e =>
                                                setGender(e.target.id)
                                              }
                                              name="gender"
                                              id="male"
                                              className="fs-5 form-check-input "
                                              required
                                            />
                                            <label
                                              htmlFor="male"
                                              className="ps-2 pt-1"
                                            >
                                              Male
                                            </label>
                                          </div>

                                          <div className="col-4">
                                            <input
                                              type="radio"
                                              onChange={e =>
                                                setGender(e.target.id)
                                              }
                                              name="gender"
                                              id="female"
                                              className="form-check-input fs-5 "
                                              required
                                            />
                                            <label
                                              htmlFor="female"
                                              className="ps-2 pt-1"
                                            >
                                              Female
                                            </label>
                                          </div>
                                          <div className="col-5">
                                            <input
                                              type="radio"
                                              onChange={e =>
                                                setGender(e.target.id)
                                              }
                                              name="gender"
                                              id="transgender"
                                              className="form-check-input fs-5 "
                                              required
                                            />
                                            <label
                                              htmlFor="transgender"
                                              className="ps-2 pt-1"
                                            >
                                              Transgender
                                            </label>
                                          </div>
                                        </Row>
                                      </Row>
                                    </div>
                                    {gender === "female" ? (
                                      <div className="col-md-4">
                                        <label htmlFor="" className="fs-5">
                                          Menstrual cycle
                                        </label>
                                        <input
                                          type="text"
                                          onChange={e =>
                                            setMensCycle(e.target.value)
                                          }
                                          placeholder="Enter Menstrual cycle"
                                          className="form-field dynamic-field"
                                        />
                                      </div>
                                    ) : (
                                      <div className="col-md-4"></div>
                                    )}

                                    <div className="col-md-3  mt-4">
                                      <label htmlFor="" className="fs-5">
                                        Email{" "}
                                        <span className="text-danger">*</span>
                                      </label>
                                      <input
                                        type="text"
                                        value={email}
                                        onChange={e => setEmail(e.target.value)}
                                        placeholder="Enter Email"
                                        className="form-field"
                                        required
                                      />
                                    </div>

                                    <div className="col-md-3  mt-4">
                                      <label htmlFor="" className="fs-5">
                                        Password{" "}
                                        <span className="text-danger">*</span>
                                      </label>
                                      <input
                                        type={!show_pass ? "text" : "password"}
                                        value={password}
                                        onChange={e =>
                                          setPassword(e.target.value)
                                        }
                                        placeholder="Enter Password"
                                        className="form-field"
                                        required
                                      />

                                      <div
                                        className="bd-eye-pass pe-3"
                                        onClick={() => setShowPass(!show_pass)}
                                      >
                                        <i
                                          className={
                                            show_pass
                                              ? "fa fa-eye"
                                              : "fa fa-eye-slash"
                                          }
                                        ></i>
                                      </div>
                                    </div>

                                    <div className="col-md-3  mt-4">
                                      <label htmlFor="" className="fs-5">
                                        Phone{" "}
                                        <span className="text-danger">*</span>
                                      </label>
                                      <input
                                        type="text"
                                        value={phone_number}
                                        onChange={e =>
                                          setPhoneNumber(e.target.value)
                                        }
                                        placeholder="Enter Phone Number"
                                        className="form-field"
                                        required
                                      />
                                    </div>
                                    <div className="col-md-3 mt-4">
                                      <label
                                        htmlFor=""
                                        className="fs-5 text-danger"
                                      >
                                        Client Goal{" "}
                                        <span className="text-danger">*</span>
                                      </label>
                                      <select
                                        className="form-field border border-danger"
                                        value={client_goal}
                                        onChange={e =>
                                          setClientGoal(e.target.value)
                                        }
                                        required
                                      >
                                        <option value="">
                                          -- Select Your Goal --
                                        </option>
                                        <option value="weight loss">
                                          Weight Loss
                                        </option>
                                        <option value="weight gain">
                                          Weight Gain
                                        </option>
                                        <option value="lifestyle">
                                          Lifestyle
                                        </option>
                                      </select>
                                    </div>

                                    <div className="col-md-4 mt-4">
                                      <label htmlFor="" className="fs-5">
                                        Country{" "}
                                        <span className="text-danger">*</span>
                                      </label>

                                      <select
                                        onChange={getStatesList}
                                        className="form-field"
                                        required
                                      >
                                        <option value="">
                                          {" "}
                                          Nothing Selected{" "}
                                        </option>
                                        {countries.map(country => {
                                          return (
                                            <option
                                              key={country.id}
                                              name={country.name}
                                              value={country.id}
                                            >
                                              {country.name}{" "}
                                            </option>
                                          )
                                        })}
                                      </select>
                                    </div>

                                    <div className="col-md-4 mt-4">
                                      <label htmlFor="" className="fs-5">
                                        State{" "}
                                        <span className="text-danger">*</span>
                                      </label>

                                      <select
                                        name="states"
                                        onChange={getCitiesList}
                                        required
                                        className="form-field"
                                      >
                                        <option value="">
                                          {" "}
                                          Nothing Selected{" "}
                                        </option>
                                        {states.map(state => {
                                          return (
                                            <option
                                              key={state.id}
                                              value={state.id}
                                            >
                                              {state.name}{" "}
                                            </option>
                                          )
                                        })}
                                      </select>
                                    </div>

                                    <div className="col-md-4 mt-4">
                                      <label htmlFor="" className="fs-5">
                                        City{" "}
                                        <span className="text-danger">*</span>
                                      </label>
                                      <select
                                        name="cities"
                                        onChange={e => setCity(e.target.value)}
                                        className="form-field"
                                        required
                                      >
                                        <option value="">
                                          {" "}
                                          Nothing Selected{" "}
                                        </option>
                                        {cities.map(city => {
                                          return (
                                            <option
                                              key={city.id}
                                              value={city.id}
                                            >
                                              {city.name}{" "}
                                            </option>
                                          )
                                        })}
                                      </select>
                                    </div>

                                    <div className="col-12 mt-4">
                                      <label htmlFor="" className="fs-5">
                                        Address{" "}
                                        <span className="text-danger">*</span>
                                      </label>
                                      <input
                                        type="text"
                                        value={address}
                                        onChange={e =>
                                          setAddress(e.target.value)
                                        }
                                        placeholder="Enter Address"
                                        className="form-field"
                                        required
                                      />
                                    </div>
                                  </Row>
                                </div>
                              </Row>
                            </div>
                          </Row>
                        </CardBody>
                      </Card>

                      <Card>
                        <CardBody>
                          <Row className="py-2 align-items-end">
                            <div className="col-md-4 mt-md-0 mt-4">
                              <label htmlFor="" className="fs-5">
                                Start Date of Package{" "}
                                <span className="text-danger">*</span>
                              </label>
                              <input
                                type="date"
                                onChange={e =>
                                  setStartdateOfPackage(e.target.value)
                                }
                                required
                                className="form-field"
                              />
                            </div>

                            <div className="col-md-4 mt-md-0 mt-4">
                              <label htmlFor="" className="fs-5">
                                Packages <span className="text-danger">*</span>
                              </label>
                              <select
                                value={packageName}
                                onChange={e => setPackageName(e.target.value)}
                                className="form-field"
                                required
                              >
                                <option value=""> Select Package</option>
                                {allPackages?.map(pack => {
                                  return (
                                    <option key={pack.id} value={pack.id}>
                                      {" "}
                                      {getDecryptedData(pack.package_name)}{" "}
                                    </option>
                                  )
                                })}
                              </select>
                            </div>

                            <div className="col-md-4 mt-4 text-end">
                              <button
                                type="submit"
                                className="btn btn-green btn-yellow border-radius"
                              >
                                {" "}
                                <i className="bx bx-send me-1"></i> Save Data{" "}
                              </button>
                            </div>
                          </Row>
                        </CardBody>
                      </Card>
                    </form>
                  </TabPane>

                  <TabPane tabId={2}>
                    <form onSubmit={handleFormSubmit2} className="px-0">
                      <Card>
                        <CardBody>
                          <Row className="py-2">
                            <div className="col-md-6">
                              <h4 className="fw-bold text-purple">
                                Health Related Information{" "}
                              </h4>
                            </div>

                            <div className="col-md-6 text-end">
                              <button
                                type="button"
                                onClick={() => navigate(-1)}
                                className="btn btn-dark border-radius me-3"
                              >
                                <i className="fas fa-arrow-left me-3"></i>
                                Back
                              </button>
                              <button
                                type="submit"
                                className="btn btn-green btn-yellow border-radius"
                              >
                                {" "}
                                <i className="bx bx-send me-1"></i> Submit{" "}
                              </button>
                            </div>

                            <div className="col-12 mt-2 mb-5">
                              <Row>
                                <div className="col-md-2 mt-4">
                                  <label htmlFor="" className="fs-5">
                                    Age{" "}
                                  </label>
                                  <input
                                    type="text"
                                    value={age}
                                    onChange={e => setAge(e.target.value)}
                                    placeholder="Enter Age"
                                    className="form-field"
                                  />
                                </div>

                                <div className="col-md-2 mt-4">
                                  <label htmlFor="" className="fs-5">
                                    Height{" "}
                                  </label>
                                  <input
                                    type="text"
                                    value={height}
                                    onChange={e => setHeight(e.target.value)}
                                    placeholder="Enter Height"
                                    className="form-field"
                                  />
                                </div>

                                <div className="col-md-2 mt-4">
                                  <label htmlFor="" className="fs-5">
                                    Weight{" "}
                                  </label>
                                  <input
                                    type="text"
                                    value={weight}
                                    onChange={e => setWeight(e.target.value)}
                                    placeholder="kgs / lbs"
                                    className="form-field"
                                  />
                                </div>

                                <div className="col-md-3 mt-4">
                                  <label htmlFor="" className="fs-5">
                                    BMI Number{" "}
                                  </label>
                                  <input
                                    type="text"
                                    value={bmi_number}
                                    onChange={e => setBmiNumber(e.target.value)}
                                    placeholder="Enter BMI Number"
                                    className="form-field"
                                  />
                                </div>

                                <div className="col-md-3 mt-4">
                                  <label htmlFor="" className="fs-5">
                                    Ideal Body Weight{" "}
                                  </label>
                                  <input
                                    type="text"
                                    value={ideal_bodyweight}
                                    onChange={e =>
                                      setIdealBodyweight(e.target.value)
                                    }
                                    placeholder="Enter Ideal Body Weight"
                                    className="form-field"
                                  />
                                </div>

                                <div className="col-md-3 mt-4">
                                  <label htmlFor="" className="fs-5">
                                    Type of job{" "}
                                  </label>
                                  <select
                                    className="form-field"
                                    value={job_type}
                                    onChange={e => setJobType(e.target.value)}
                                  >
                                    <option value="">Select Job type</option>
                                    <option value="Desk job">Desk job</option>
                                    <option value="Field work">
                                      Field work
                                    </option>
                                  </select>
                                </div>

                                <div className="col-md-3 mt-4">
                                  <label htmlFor="" className="fs-5">
                                    Working hours{" "}
                                  </label>
                                  <input
                                    type="text"
                                    value={working_hours}
                                    onChange={e =>
                                      setWorkingHours(e.target.value)
                                    }
                                    placeholder="Enter Working hours"
                                    className="form-field"
                                  />
                                </div>
                              </Row>
                            </div>

                            <div className="col-md-4 mt-4">
                              <label htmlFor="" className="fs-5">
                                Supplements/Medicines{" "}
                              </label>
                              <input
                                type="text"
                                value={supplements}
                                onChange={e => setSupplements(e.target.value)}
                                placeholder="Enter Supplements/Medicines"
                                className="form-field"
                              />
                            </div>

                            <div className="col-md-4 mt-4">
                              <label htmlFor="" className="fs-5">
                                Family history{" "}
                              </label>
                              <input
                                type="text"
                                value={family_history}
                                onChange={e => setFamilyHistory(e.target.value)}
                                placeholder="Enter Family history"
                                className="form-field"
                              />
                            </div>

                            <div className="col-md-4 mt-4">
                              <label htmlFor="" className="fs-5">
                                Is this highest weight?{" "}
                              </label>
                              <input
                                type="text"
                                value={heighest_weight}
                                onChange={e =>
                                  setHeighestWeight(e.target.value)
                                }
                                placeholder="Enter Answer"
                                className="form-field"
                              />
                            </div>

                            <div className="col-12 mt-4">
                              <Row>
                                <div className={`col-md-4`}>
                                  <label htmlFor="" className="fs-5">
                                    Stomach issue
                                  </label>
                                  <select
                                    className="form-field"
                                    value={stomach_issue}
                                    onChange={e =>
                                      setStomachIssue(e.target.value)
                                    }
                                  >
                                    <option value="">Select Issue</option>
                                    <option value="Yes">Yes</option>
                                    <option value="No">No</option>
                                  </select>
                                </div>

                                <div className="col-md-8">
                                  <label htmlFor="" className="fs-5">
                                    Issue
                                  </label>
                                  <input
                                    type="text"
                                    disabled={
                                      stomach_issue === "Yes" ? false : true
                                    }
                                    placeholder="Enter Issue"
                                    value={s_issue}
                                    onChange={e => setSIssue(e.target.value)}
                                    className={`form-field ${
                                      stomach_issue === "Yes" && "dynamic-field"
                                    } `}
                                  />
                                </div>
                              </Row>
                            </div>

                            <div className="col-md-4 mt-4">
                              <label htmlFor="" className="fs-5">
                                Medical issue
                              </label>
                              <Select
                                placeholder="Select Issue"
                                className="basic-multi-select"
                                classNamePrefix="border-radius select"
                                styles={colourStyles}
                                isMulti
                                onChange={e => setMedicalIssue(e)}
                                options={medicalIssues}
                              />
                            </div>

                            <div className="col-md-8 mt-4">
                              <label htmlFor="" className="fs-5">
                                Issue
                              </label>
                              <input
                                type="text"
                                value={m_issue}
                                onChange={e => setMIssue(e.target.value)}
                                placeholder="Enter Issue"
                                className={`form-field ${
                                  medical_issue.length &&
                                  medical_issue[0]?.value === "Other" &&
                                  "dynamic-field"
                                } `}
                                disabled={
                                  medical_issue.length &&
                                  medical_issue[0]?.value === "Other"
                                    ? false
                                    : true
                                }
                              />
                            </div>

                            <div className="col-md-4 mt-4">
                              <label htmlFor="" className="fs-5">
                                Tried losing weight
                              </label>
                              <select
                                className="form-field"
                                value={tried_losing_weight}
                                onChange={e =>
                                  setTriedLosingWeight(e.target.value)
                                }
                              >
                                <option value="">Select Choice</option>
                                <option value="Yes">Yes</option>
                                <option value="No">No</option>
                              </select>
                            </div>

                            <div className="col-md-8 mt-4">
                              <label htmlFor="" className="fs-5">
                                What you have tried?
                              </label>
                              <input
                                type="text"
                                placeholder="Enter Description"
                                onChange={e => setTriedActivity(e.target.value)}
                                value={tried_activity}
                                disabled={
                                  tried_losing_weight === "Yes" ? false : true
                                }
                                className={`form-field ${
                                  tried_losing_weight === "Yes" &&
                                  "dynamic-field"
                                } `}
                              />
                            </div>

                            <div className="col-md-4 mt-4">
                              <label htmlFor="" className="fs-5">
                                Body fat distribution
                              </label>
                              <Select
                                placeholder="Select Cheat Meal"
                                className="basic-multi-select"
                                classNamePrefix="border-radius select"
                                styles={colourStyles}
                                isMulti
                                onChange={e => setBodyFats(e)}
                                options={bodyFatsOptions}
                              />
                            </div>

                            <div className="col-md-8 mt-4">
                              <label htmlFor="" className="fs-5">
                                Describe
                              </label>
                              <input
                                type="text"
                                placeholder="Enter Answer"
                                value={bodyFatsDesc}
                                onChange={e => setBodyFatsDesc(e.target.value)}
                                className={`form-field ${
                                  bodyFats.length &&
                                  bodyFats[0]?.value === "Other" &&
                                  "dynamic-field"
                                } `}
                                disabled={
                                  bodyFats.length &&
                                  bodyFats[0]?.value === "Other"
                                    ? false
                                    : true
                                }
                              />
                            </div>

                            <div className={`col-md-4 mt-4`}>
                              <label htmlFor="" className="fs-5">
                                Physical Activity
                              </label>
                              <select
                                className="form-field"
                                value={physical_activity}
                                onChange={e =>
                                  setPhysicalActivity(e.target.value)
                                }
                              >
                                <option value="">Select Choice</option>
                                <option value="Yes">Yes</option>
                                <option value="No">No</option>
                              </select>
                            </div>
                            <div className="col-md-8 mt-4">
                              <label htmlFor="" className="fs-5">
                                Activity
                              </label>
                              <input
                                type="text"
                                placeholder="Enter Activity"
                                value={activity}
                                onChange={e => setActivity(e.target.value)}
                                className={`form-field ${
                                  physical_activity === "Yes" && "dynamic-field"
                                } `}
                                disabled={
                                  physical_activity === "Yes" ? false : true
                                }
                              />
                            </div>

                            <div className="col-md-4 mt-4">
                              <label htmlFor="" className="fs-5">
                                Food preference
                              </label>
                              <select
                                className="form-field"
                                value={food_preference}
                                onChange={e =>
                                  setFoodPreference(e.target.value)
                                }
                              >
                                <option value="">Select Choice</option>
                                <option value="Vegetarian">Vegetarian</option>
                                <option value="Non vegetarian">
                                  Non vegetarian
                                </option>
                                <option value="Other">Other</option>
                              </select>
                            </div>

                            <div className="col-md-8 mt-4">
                              <label htmlFor="" className="fs-5">
                                What food you prefers?
                              </label>
                              <input
                                type="text"
                                value={food_prefer_detail}
                                onChange={e =>
                                  setFoodPreferDetail(e.target.value)
                                }
                                placeholder="Enter Food Preference"
                                disabled={food_preference !== "" ? false : true}
                                className={`form-field ${
                                  food_preference !== "" && "dynamic-field"
                                } `}
                              />
                            </div>

                            <div className="col-md-4 mt-4">
                              <label htmlFor="" className="fs-5">
                                Any Surgery
                              </label>
                              <select
                                className="form-field"
                                value={surgery}
                                onChange={e => setSurgery(e.target.value)}
                              >
                                <option value="">Select Choice</option>
                                <option value="Yes">Yes</option>
                                <option value="No">No</option>
                              </select>
                            </div>

                            <div className="col-md-8 mt-4">
                              <label htmlFor="" className="fs-5">
                                Surgery
                              </label>
                              <input
                                type="text"
                                placeholder="Enter Surgery"
                                value={surgery_detail}
                                onChange={e => setSurgeryDetail(e.target.value)}
                                disabled={surgery === "Yes" ? false : true}
                                className={`form-field ${
                                  surgery === "Yes" && "dynamic-field"
                                }`}
                              />
                            </div>

                            <div className="col-md-12 mt-4 text-end">
                              <button
                                type="submit"
                                className="btn btn-green btn-yellow border-radius"
                              >
                                {" "}
                                <i className="bx bx-send me-1"></i> Save Data{" "}
                              </button>
                            </div>
                          </Row>
                        </CardBody>
                      </Card>
                    </form>
                  </TabPane>

                  <TabPane tabId={3}>
                    <form onSubmit={handleFormSubmit3} className="px-0">
                      <Card>
                        <CardBody>
                          <Row className="py-2">
                            <div className="col-md-6">
                              <h4 className="fw-bold text-purple">
                                Food Information{" "}
                              </h4>
                            </div>

                            <div className="col-md-6 text-end">
                              <button
                                type="button"
                                onClick={() => navigate(-1)}
                                className="btn btn-dark border-radius me-3"
                              >
                                <i className="fas fa-arrow-left me-3"></i>
                                Back
                              </button>
                              <button
                                type="submit"
                                className="btn btn-green btn-yellow border-radius"
                              >
                                {" "}
                                <i className="bx bx-send me-1"></i> Submit{" "}
                              </button>
                            </div>

                            <div className="col-md-4 mt-4">
                              <label htmlFor="" className="fs-5">
                                Likes{" "}
                              </label>
                              <input
                                type="text"
                                value={likes}
                                onChange={e => setLikes(e.target.value)}
                                placeholder="Enter Likes"
                                className="form-field"
                              />
                            </div>

                            <div className="col-md-4 mt-4">
                              <label htmlFor="" className="fs-5">
                                Dislikes{" "}
                              </label>
                              <input
                                type="text"
                                value={dislikes}
                                onChange={e => setDislikes(e.target.value)}
                                placeholder="Enter Dislikes"
                                className="form-field"
                              />
                            </div>

                            <div className="col-md-4 mt-4">
                              <label htmlFor="" className="fs-5">
                                Allergies{" "}
                              </label>
                              <input
                                type="text"
                                value={allergies}
                                onChange={e => setAllergies(e.target.value)}
                                placeholder="Enter Allergies"
                                className="form-field"
                              />
                            </div>

                            <div className="col-md-4 mt-4">
                              <label htmlFor="" className="fs-5">
                                Cravings{" "}
                              </label>
                              <input
                                type="text"
                                value={cravings}
                                onChange={e => setCravings(e.target.value)}
                                placeholder="Enter Cravings"
                                className="form-field"
                              />
                            </div>

                            <div className="col-md-4 mt-4">
                              <label htmlFor="" className="fs-5">
                                Cheat Meal
                              </label>
                              <Select
                                placeholder="Select Cheat Meal"
                                className="basic-multi-select"
                                classNamePrefix="border-radius select"
                                styles={colourStyles}
                                isMulti
                                onChange={e => setCheatMeal(e)}
                                options={weekDays}
                              />
                            </div>

                            <div className="col-md-4 mt-4">
                              <label htmlFor="" className="fs-5">
                                How often eat outside{" "}
                              </label>
                              <input
                                type="text"
                                value={outside_food_detail}
                                onChange={e =>
                                  setOutsideFoodDetail(e.target.value)
                                }
                                placeholder="Enter Answer"
                                maxLength={7}
                                className="form-field"
                              />
                            </div>

                            <div className="col-md-4 mt-4">
                              <label htmlFor="" className="fs-5">
                                Alcohol/smokes
                              </label>
                              <select
                                className="form-field"
                                value={smokeAlcohol}
                                onChange={e => setSmokeAlcohol(e.target.value)}
                              >
                                <option value="">Select Choice</option>
                                <option value="Yes">Yes</option>
                                <option value="No">No</option>
                              </select>
                            </div>

                            <div className="col-md-4 mt-4">
                              <label htmlFor="" className="fs-5">
                                Describe
                              </label>
                              <input
                                type="text"
                                placeholder="Enter description"
                                value={addiction_detail}
                                onChange={e =>
                                  setAddictionDetail(e.target.value)
                                }
                                disabled={smokeAlcohol === "Yes" ? false : true}
                                className={`form-field ${
                                  smokeAlcohol === "Yes" && "dynamic-field"
                                }`}
                              />
                            </div>

                            <div className="col-md-4 mt-4">
                              <label htmlFor="" className="fs-5">
                                Water intake{" "}
                              </label>
                              <input
                                type="text"
                                value={water_intake}
                                onChange={e => setWaterIntake(e.target.value)}
                                placeholder="Enter as per glass / ltr "
                                className="form-field"
                              />
                            </div>

                            <div className="col-md-4 mt-4">
                              <label htmlFor="" className="fs-5">
                                Sleep cycle{" "}
                              </label>
                              <select
                                value={sleep_cycle}
                                onChange={e => setSleepCycle(e.target.value)}
                                className="form-field"
                              >
                                <option value="">Select Choice</option>
                                <option value="sound">sound</option>
                                <option value="disturbed">disturbed </option>
                              </select>
                            </div>

                            <div className="col-md-4 mt-4">
                              <label htmlFor="" className="fs-5">
                                Oil
                              </label>
                              <input
                                type="text"
                                value={oil}
                                onChange={e => setOil(e.target.value)}
                                placeholder="Enter Oil"
                                className="form-field"
                              />
                            </div>

                            <div className="col-md-4 mt-4">
                              <label htmlFor="" className="fs-5">
                                Salt
                              </label>
                              <input
                                type="text"
                                value={salt}
                                onChange={e => setSalt(e.target.value)}
                                placeholder="Enter Salt"
                                className="form-field"
                              />
                            </div>

                            <div className="col-md-4 mt-4">
                              <label htmlFor="" className="fs-5">
                                Flour
                              </label>
                              <input
                                type="text"
                                value={flour}
                                onChange={e => setFlour(e.target.value)}
                                placeholder="Enter Flour"
                                className="form-field"
                              />
                            </div>

                            <div className="col-md-4 mt-4">
                              <label htmlFor="" className="fs-5">
                                Stress level{" "}
                              </label>
                              <select
                                value={stress_level}
                                onChange={e => setStressLevel(e.target.value)}
                                className="form-field"
                              >
                                <option value="">Select Choice</option>
                                <option value="Low">Low</option>
                                <option value="Moderate">Moderate </option>
                                <option value="High">High </option>
                                <option value="Very High">Very High </option>
                              </select>
                            </div>

                            <div className="col-md-4 mt-4">
                              <Row>
                                <div className="col-md-6">
                                  <label htmlFor="" className="fs-5">
                                    Sleeps at{" "}
                                  </label>
                                  <input
                                    type="time"
                                    // value={sleeps_at}
                                    onChange={e => setSleepsAt(e.target.value)}
                                    className="form-field"
                                  />
                                </div>
                                <div className="col-md-6">
                                  <label htmlFor="" className="fs-5">
                                    Wake up{" "}
                                  </label>
                                  <input
                                    type="time"
                                    onChange={e => setWakeUp(e.target.value)}
                                    className="form-field"
                                  />
                                </div>
                              </Row>
                            </div>
                          </Row>
                        </CardBody>
                      </Card>

                      <Card>
                        <CardBody>
                          <Row className="py-2">
                            <h4 className="fw-bold text-purple">
                              Food Consumption
                            </h4>

                            <div className="col-md-12 mt-4">
                              <Row className="align-items-end">
                                <div className="col-md-2">
                                  <label htmlFor="" className="fs-5">
                                    Breakfast{" "}
                                  </label>
                                  <input
                                    type="time"
                                    onChange={e =>
                                      setBreakfast({
                                        ...breakfast,
                                        time: e.target.value,
                                      })
                                    }
                                    className="form-field"
                                  />
                                </div>

                                <div className="col-md-10">
                                  <input
                                    type="text"
                                    onChange={e =>
                                      setBreakfast({
                                        ...breakfast,
                                        foods: e.target.value,
                                      })
                                    }
                                    placeholder="Enter Food Items"
                                    className="form-field"
                                  />
                                </div>
                              </Row>
                            </div>

                            <div className="col-md-12 mt-4">
                              <Row className="align-items-end">
                                <div className="col-md-2">
                                  <label htmlFor="" className="fs-5">
                                    Mid morning
                                  </label>
                                  <input
                                    type="time"
                                    onChange={e =>
                                      setMidMorning({
                                        ...mid_morning,
                                        time: e.target.value,
                                      })
                                    }
                                    className="form-field"
                                  />
                                </div>

                                <div className="col-md-10">
                                  <input
                                    type="text"
                                    onChange={e =>
                                      setMidMorning({
                                        ...mid_morning,
                                        foods: e.target.value,
                                      })
                                    }
                                    placeholder="Enter Food Items"
                                    className="form-field"
                                  />
                                </div>
                              </Row>
                            </div>

                            <div className="col-md-12 mt-4">
                              <Row className="align-items-end">
                                <div className="col-md-2">
                                  <label htmlFor="" className="fs-5">
                                    Lunch
                                  </label>
                                  <input
                                    type="time"
                                    onChange={e =>
                                      setLunch({
                                        ...lunch,
                                        time: e.target.value,
                                      })
                                    }
                                    className="form-field"
                                  />
                                </div>

                                <div className="col-md-10">
                                  <input
                                    type="text"
                                    onChange={e =>
                                      setLunch({
                                        ...lunch,
                                        foods: e.target.value,
                                      })
                                    }
                                    placeholder="Enter Food Items"
                                    className="form-field"
                                  />
                                </div>
                              </Row>
                            </div>

                            <div className="col-md-12 mt-4">
                              <Row className="align-items-end">
                                <div className="col-md-2">
                                  <label htmlFor="" className="fs-5">
                                    Evening snack
                                  </label>
                                  <input
                                    type="time"
                                    onChange={e =>
                                      setEveningSnack({
                                        ...evening_snack,
                                        time: e.target.value,
                                      })
                                    }
                                    className="form-field"
                                  />
                                </div>

                                <div className="col-md-10">
                                  <input
                                    type="text"
                                    onChange={e =>
                                      setEveningSnack({
                                        ...evening_snack,
                                        foods: e.target.value,
                                      })
                                    }
                                    placeholder="Enter Food Items"
                                    className="form-field"
                                  />
                                </div>
                              </Row>
                            </div>

                            <div className="col-md-12 mt-4">
                              <Row className="align-items-end">
                                <div className="col-md-2">
                                  <label htmlFor="" className="fs-5">
                                    Dinner
                                  </label>
                                  <input
                                    type="time"
                                    onChange={e =>
                                      setDinner({
                                        ...dinner,
                                        time: e.target.value,
                                      })
                                    }
                                    className="form-field"
                                  />
                                </div>

                                <div className="col-md-10">
                                  <input
                                    type="text"
                                    onChange={e =>
                                      setDinner({
                                        ...dinner,
                                        foods: e.target.value,
                                      })
                                    }
                                    placeholder="Enter Food Items"
                                    className="form-field"
                                  />
                                </div>
                              </Row>
                            </div>

                            <div className="col-md-12 mt-4">
                              <Row className="align-items-end">
                                <div className="col-md-2">
                                  <label htmlFor="" className="fs-5">
                                    Post dinner
                                  </label>
                                  <input
                                    type="time"
                                    onChange={e =>
                                      setPostDinner({
                                        ...post_dinner,
                                        time: e.target.value,
                                      })
                                    }
                                    className="form-field"
                                  />
                                </div>

                                <div className="col-md-10">
                                  <input
                                    type="text"
                                    onChange={e =>
                                      setPostDinner({
                                        ...post_dinner,
                                        foods: e.target.value,
                                      })
                                    }
                                    placeholder="Enter Food Items"
                                    className="form-field"
                                  />
                                </div>
                              </Row>
                            </div>

                            <div className="col-md-12 mt-4 text-end">
                              <button
                                type="submit"
                                className="btn btn-green btn-yellow border-radius"
                              >
                                {" "}
                                <i className="bx bx-send me-1"></i> Save Data{" "}
                              </button>
                            </div>
                          </Row>
                        </CardBody>
                      </Card>
                    </form>
                  </TabPane>
                  {/* <TabPane tabId={4}>
                                                
                                            </TabPane>
                                            <TabPane tabId={5}>
                                                
                                            </TabPane> */}
                </TabContent>
              </div>
              <div className="actions clearfix mb-5">
                <ul
                  className="d-flex justify-content-center"
                  style={{ listStyle: "none" }}
                >
                  <li
                    className={
                      (activeTab === 1 ? "previous disabled" : "previous") +
                      " me-4"
                    }
                  >
                    <Link
                      to="#"
                      onClick={() => {
                        window.scrollTo(0, 0)
                        toggleTab(activeTab - 1)
                      }}
                      className="btn btn-purple"
                    >
                      <i className="fas fa-arrow-left me-2"></i>
                      Previous
                    </Link>
                  </li>
                  <li className={activeTab === 3 ? "next disabled" : "next"}>
                    <Link
                      to="#"
                      onClick={() => {
                        toggleTab(activeTab + 1)
                        window.scrollTo(0, 0)
                      }}
                      className="btn btn-purple"
                    >
                      Next
                      <i className="fas fa-arrow-right ms-2"></i>
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default AddClient
