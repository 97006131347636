import PropTypes from "prop-types"
import React, { useState, useEffect } from "react"
import { Row, Col, Collapse } from "reactstrap"
import { Link } from "react-router-dom"
import withRouter from "components/Common/withRouter"
import classname from "classnames"
import "../../assets/css/Layout/Header.css"
//i18n
import { withTranslation } from "react-i18next"

import { connect } from "react-redux"
import { ConfigUrl } from "ConfigUrl"

const Navbar = props => {
  const [dashboard, setdashboard] = useState(false)
  const [setting, setSetting] = useState(false)
  const [expense, setExpense] = useState(false)

  useEffect(() => {
    var matchingMenuItem = null
    var ul = document.getElementById("navigation")
    var items = ul.getElementsByTagName("a")
    removeActivation(items)
    for (var i = 0; i < items.length; ++i) {
      if (window.location.pathname === items[i].pathname) {
        matchingMenuItem = items[i]
        break
      }
    }
    if (matchingMenuItem) {
      activateParentDropdown(matchingMenuItem)
    }
  })

  const removeActivation = items => {
    for (var i = 0; i < items.length; ++i) {
      var item = items[i]
      const parent = items[i].parentElement
      if (item && item.classList.contains("active")) {
        item.classList.remove("active")
      }
      if (parent) {
        if (parent.classList.contains("active")) {
          parent.classList.remove("active")
        }
      }
    }
  }

  function activateParentDropdown(item) {
    item.classList.add("active")
    const parent = item.parentElement
    if (parent) {
      parent.classList.add("active") // li
      const parent2 = parent.parentElement
      parent2.classList.add("active") // li
      const parent3 = parent2.parentElement
      if (parent3) {
        parent3.classList.add("active") // li
        const parent4 = parent3.parentElement
        if (parent4) {
          parent4.classList.add("active") // li
          const parent5 = parent4.parentElement
          if (parent5) {
            parent5.classList.add("active") // li
            const parent6 = parent5.parentElement
            if (parent6) {
              parent6.classList.add("active") // li
            }
          }
        }
      }
    }
    return false
  }

  return (
    <React.Fragment>
      <div className="topnav ps-1 pe-0" style={{ zIndex: "1001" }}>
        <div className="container-fluid px-0">
          <nav
            className="navbar navbar-light navbar-expand-lg topnav-menu"
            id="navigation"
          >
            <Collapse
              isOpen={props.leftMenu}
              className="navbar-collapse"
              id="topnav-menu-content"
            >
              <ul className="navbar-nav d-flex justify-content-between w-100">
                <div className="d-flex">
                  {/* dashboard */}
                  <li className="nav-item dropdown">
                    <Link
                      className="nav-link dropdown-toggle arrow-none text-dark"
                      to={`${
                        localStorage.getItem("adminUser")
                          ? `${ConfigUrl.appUrl}admin-dashboard`
                          : `${ConfigUrl.appUrl}dashboard`
                      }`}
                    >
                      <i className="bx bx-home-circle me-2 "></i>
                      {props.t("Dashboard")} {props.menuOpen}
                    </Link>
                  </li>
                  {localStorage.getItem("adminUser") && (
                    <li className="nav-item dropdown">
                      <Link
                        className="nav-link dropdown-toggle arrow-none text-dark"
                        to={`${ConfigUrl.appUrl}all-clients`}
                      >
                        <i className="bx bx-tone me-2"></i>
                        {props.t("All Clients")} {props.menuOpen}
                      </Link>
                    </li>
                  )}

                  {localStorage.getItem("adminUser") && (
                    <li className="nav-item dropdown">
                      <Link
                        className="nav-link dropdown-toggle arrow-none text-dark"
                        to={`${ConfigUrl.appUrl}add-diet-plan`}
                      >
                        <i className="fas fa-list fs-6 me-2"></i>
                        {props.t("Diet Plans")} {props.menuOpen}
                      </Link>
                    </li>
                  )}

                  {localStorage.getItem("adminUser") && (
                    <li className="nav-item dropdown">
                      <Link
                        className="nav-link dropdown-toggle arrow-none text-dark"
                        to={`${ConfigUrl.appUrl}invoices`}
                      >
                        <i className="fas fa-book fs-6 me-2"></i>
                        {props.t("Invoices")} {props.menuOpen}
                      </Link>
                    </li>
                  )}

                  {localStorage.getItem("adminUser") && (
                    <li className="nav-item dropdown">
                      <Link
                        className="nav-link dropdown-toggle arrow-none text-dark"
                        to={`${ConfigUrl.appUrl}packages`}
                      >
                        <i className="fas fa-box fs-6 me-2"></i>
                        {props.t("Packages")} {props.menuOpen}
                      </Link>
                    </li>
                  )}

                  {localStorage.getItem("adminUser") && (
                    <li className="nav-item dropdown">
                      <Link
                        className="nav-link dropdown-toggle arrow-none text-dark"
                        to={`${ConfigUrl.appUrl}admin-dashboard`}
                      >
                        <i className="fas fa-list fs-6 me-2"></i>
                        {props.t("Reports")} {props.menuOpen}
                      </Link>
                    </li>
                  )}

                  {/* sales */}
                  {/* <li className="nav-item dropdown">
                    <Link
                      className="nav-link dropdown-toggle arrow-none text-dark"
                      to="#"
                    >
                      <i className="bx bx-tone me-2"></i>
                      {props.t("Sales")} {props.menuOpen}
                    </Link>
                    <div
                      className="dropdown-menu"
                      id="sales-dropdown"
                    >
                      <Link to={`${ConfigUrl.appUrl}sales/lead`} className="dropdown-item">Lead</Link>
                      <Link to={`${ConfigUrl.appUrl}sales/Introducer`} className="dropdown-item">Introducer</Link>
                      <Link to={`${ConfigUrl.appUrl}sales/proposal`} className="dropdown-item">Proposal</Link>
                      <Link to={`${ConfigUrl.appUrl}sales/contract`} className="dropdown-item">Contract</Link>
                      <Link to={`${ConfigUrl.appUrl}sales/customer`} className="dropdown-item">Customers</Link>
                    </div>
                  </li> */}
                </div>
              </ul>
            </Collapse>
          </nav>
        </div>
      </div>
    </React.Fragment>
  )
}

Navbar.propTypes = {
  leftMenu: PropTypes.any,
  location: PropTypes.any,
  menuOpen: PropTypes.any,
  t: PropTypes.any,
}

const mapStatetoProps = state => {
  const { leftMenu } = state.Layout
  return { leftMenu }
}

export default withRouter(
  connect(mapStatetoProps, {})(withTranslation()(Navbar))
)
