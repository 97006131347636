import React, { useEffect, useRef, useState } from "react"
import { Container } from "reactstrap"
import Breadcrumbs from "../../../../components/Common/Breadcrumb"
import { useNavigate, useParams } from "react-router-dom"
import { Row } from "reactstrap"
import axios from "axios"
import jsPDF from "jspdf"
import html2canvas from "html2canvas"
import Loader from "pages/Loader"
import { getDecryptedData } from "pages/Config"

const ViewInvoice = () => {
  const [InvoiceData, setInvoiceData] = useState({})
  const [serviceDetails, setServiceDetails] = useState([])

  const [inv_structure, setInvStructure] = useState({})
  const [loading, setLoading] = useState(false)

  const navigate = useNavigate()

  useEffect(() => window.scrollTo(0, 0), [])

  const { invoice_id } = useParams()

  document.title = "View Invoice | Nutri Connect Pro"

  const divRef = useRef(null)

  // Getting Invoice detail
  useEffect(() => {
    setLoading(true)

    const formData = new FormData()
    formData.append("invoice_id", invoice_id)
    formData.append("added_by", localStorage.getItem("adminId"))

    axios
      .post(
        "https://api.nutriconnectpro.com/api/view_client_invoice",
        formData,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "multipart/form-data",
          },
        }
      )
      .then(response => {
        const oriData = {
          ...response.data.data.invoice_details,
          account_name: getDecryptedData(
            response.data.data.invoice_details.account_name
          ),
          account_no: getDecryptedData(
            response.data.data.invoice_details.account_no
          ),
          address: getDecryptedData(response.data.data.invoice_details.address),
          bank_name: getDecryptedData(
            response.data.data.invoice_details.bank_name
          ),
          branch_name: getDecryptedData(
            response.data.data.invoice_details.branch_name
          ),
          ifsc_code: getDecryptedData(
            response.data.data.invoice_details.ifsc_code
          ),
          name: getDecryptedData(response.data.data.invoice_details.name),
          terms: getDecryptedData(response.data.data.invoice_details.terms),
        }

        setInvStructure(oriData)

        const oriData2 = {
          ...response.data.data,
          customer_name: getDecryptedData(response.data.data.customer_name),
          customer_note: getDecryptedData(response.data.data.customer_note),
          discount: getDecryptedData(response.data.data.discount),
          subtotal: getDecryptedData(response.data.data.subtotal),
          terms: getDecryptedData(response.data.data.terms),
          service_details: getDecryptedData(response.data.data.service_details),
          invoice_date: getDecryptedData(response.data.data.invoice_date),
          duedate: getDecryptedData(response.data.data.duedate),
          startdate: getDecryptedData(response.data.data.startdate),
          enddate: getDecryptedData(response.data.data.enddate),
          total: Number(getDecryptedData(response.data.data.total)),
          unpaid_amount: Number(
            getDecryptedData(response.data.data.unpaid_amount)
          ),
        }
        setInvoiceData(oriData2)

        setServiceDetails(JSON.parse(oriData2.service_details))
        setLoading(false)
      })
      .catch(err => {
        swal("Something went wrong", "", "error")
        console.log(err)
      })
  }, [])

  const handleSavePDF = () => {
    // setPrinting(true)
    const barcodesDiv = divRef.current

    // Use html2canvas to convert the div content to a canvas
    html2canvas(barcodesDiv).then(canvas => {
      const divWidth = barcodesDiv.offsetWidth
      const divHeight = barcodesDiv.offsetHeight
      const ratio = divWidth / divHeight

      // Calculate the PDF page size and orientation based on the div's aspect ratio
      const pdfWidth = 210 // A4 page width in mm
      const pdfHeight = pdfWidth / ratio
      const orientation = "p" // Landscape or portrait

      // Create a new jsPDF instance
      const pdf = new jsPDF(orientation, "mm", "A4")

      // Add the canvas to the PDF document
      pdf.addImage(
        canvas.toDataURL("image/jpeg"),
        "JPEG",
        0,
        0,
        pdfWidth,
        pdfHeight
      )

      // Save the PDF with a specified name (e.g., 'div-content.pdf')
      pdf.save("INVOICE.pdf")
      // setPrinting(false)
    })
  }

  return (
    <React.Fragment>
      {loading && <Loader />}
      <div className="page-content mb-3">
        <Container fluid>
          <Breadcrumbs title={"Dashboard"} breadcrumbItem={"New Invoice"} />
          <Row className="px-1 justify-content-center mb-5">
            <div className="col-8 text-end mb-4">
              <button
                onClick={e => {
                  e.preventDefault()
                  navigate(-1)
                }}
                className="border-radius btn ms-md-2 btn-dark"
              >
                <i className="fas fa-arrow-left me-3"></i>Back
              </button>

              <button
                onClick={handleSavePDF}
                className="btn btn-green bg-light-yellow border-radius ms-3"
              >
                <i className="fas fa-download me-2"></i>
                Download Invoice
              </button>
            </div>

            {/* invoice page */}
            <div className="col-md-8">
              <div className="invoice-page bg-white ">
                <div ref={divRef} className="p-4 pb-5">
                  <div className="row justify-content-between">
                    <div className="col-4">
                      {/* <img src="https://mars.ztpl.net/assets/images/zithas-p.png" className='w-100 mt-2' alt="" /> */}
                      <h2 className="fw-bold">{inv_structure?.name}</h2>
                    </div>

                    <div className="col-5 text-end">
                      {/* {
                                                inv_structure?.address
                                            } */}
                      {inv_structure?.address && (
                        <>
                          <p className="mb-0">
                            {JSON.parse(inv_structure?.address).line1}
                          </p>
                          <p className="mb-0">
                            {JSON.parse(inv_structure?.address).line2}
                          </p>
                          <p className="mb-0">
                            {JSON.parse(inv_structure?.address).line3}
                          </p>
                        </>
                      )}
                    </div>
                  </div>

                  <div className="row ">
                    <div className="col-4 pe-0">
                      <div className="invoice-line"></div>
                    </div>
                    <div className="col-4">
                      <p className="fs-4 py-3 text-center bg-white mb-0">
                        INVOICE
                      </p>
                    </div>
                    <div className="col-4 ps-0">
                      <div className="invoice-line"></div>
                    </div>
                  </div>

                  <div className="row justify-content-between">
                    <div className="col-3 ">
                      <p className="mb-1">{InvoiceData?.customer_name},</p>
                      <p className="mb-1">
                        {getDecryptedData(InvoiceData?.client?.address)}
                      </p>
                      <p className="mb-1">
                        {getDecryptedData(InvoiceData?.client?.city) +
                          ", " +
                          getDecryptedData(InvoiceData?.client?.state)}
                      </p>
                      <p className="mb-1">
                        {getDecryptedData(InvoiceData?.client?.country)},
                      </p>
                    </div>
                    <div className="col-5 ps-0">
                      <table className="table ">
                        <tbody>
                          <tr>
                            <th className="border border-secondary py-1">
                              Invoice#
                            </th>
                            <td className="border border-secondary py-1">
                              {InvoiceData?.invoice_number}
                            </td>
                          </tr>
                          <tr>
                            <th className="border border-secondary py-1">
                              Invoice Date
                            </th>
                            <td className="border border-secondary py-1">
                              {InvoiceData?.invoice_date}
                            </td>
                          </tr>
                          <tr>
                            <th className="border border-secondary py-1">
                              Terms
                            </th>
                            <td className="border border-secondary py-1">
                              {InvoiceData?.terms}
                            </td>
                          </tr>
                          <tr>
                            <th className="border border-secondary py-1">
                              Due Date
                            </th>
                            <td className="border border-secondary py-1">
                              {InvoiceData?.duedate}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>

                  <div className="mt-4">
                    <table className="table ">
                      <thead className="table-green">
                        <tr>
                          <th className="border border-secondary">ID</th>
                          <th className="border border-secondary">
                            Package & Description
                          </th>
                          <th className="border border-secondary">Qty</th>
                          <th className="border border-secondary">Rate</th>
                          <th className="border border-secondary">Tax</th>
                          <th className="border border-secondary">Amount</th>
                        </tr>
                      </thead>

                      <tbody>
                        {serviceDetails?.map((sdata, i) => {
                          return (
                            <tr key={i}>
                              <td className="border border-secondary">
                                {i + 1}
                              </td>
                              <td className="border border-secondary">
                                {sdata.service}
                              </td>
                              <td className="border border-secondary">
                                {sdata.quantity}
                              </td>
                              <td className="border border-secondary">
                                {sdata.rate}
                              </td>
                              <td className="border border-secondary">
                                {sdata.tax}
                              </td>
                              <td className="border border-secondary">
                                ₹ {sdata.amount}
                              </td>
                            </tr>
                          )
                        })}
                      </tbody>
                    </table>
                    <p>
                      Thank you for giving us an opportunity! Hoping work from
                      you in future
                    </p>
                  </div>

                  <div className="row justify-content-end">
                    <div className="col-5">
                      <div className="d-flex py-2 mt-3 justify-content-between">
                        <span>Sub Total</span>
                        <span>₹ {InvoiceData?.subtotal}</span>
                      </div>
                      <div className="d-flex py-2 justify-content-between">
                        <span>Discount (%)</span>
                        <span>
                          {InvoiceData?.discount &&
                            InvoiceData?.discount != "undefined" &&
                            InvoiceData?.discount}
                          %
                        </span>
                      </div>
                      <div className="d-flex pt-2 border-top border-secondary pb-2 justify-content-between">
                        <strong> Total (₹)</strong>
                        <span>₹ {Number(InvoiceData?.total)?.toFixed(2)}</span>
                      </div>
                    </div>
                  </div>

                  <div className="mt-4 row pb-5 pt-4">
                    {/* <p>Total in Words : <strong>Rupees Seventeen Thousand and Seven Hundred</strong></p> */}

                    <h4 className="fw-bold">Bank Details</h4>

                    <div className="col-5">
                      <table className="table">
                        <tr>
                          <th>Bank Name : </th>
                          <td>{inv_structure?.bank_name}</td>
                        </tr>
                        <tr>
                          <th>Branch Name :</th>
                          <td>{inv_structure?.branch_name} </td>
                        </tr>
                        <tr>
                          <th>Account Name :</th>
                          <td>{inv_structure?.account_name}</td>
                        </tr>
                        <tr>
                          <th>Account No. :</th>
                          <td>{inv_structure?.account_no}</td>
                        </tr>
                        <tr>
                          <th>IFSC Code : </th>
                          <td> {inv_structure?.ifsc_code} </td>
                        </tr>
                      </table>
                    </div>

                    <div className="mt-4">
                      <h4 className="fw-bold">Terms &amp; Conditions</h4>
                      <div
                        className="terms"
                        dangerouslySetInnerHTML={{
                          __html: inv_structure?.terms,
                        }}
                      />
                      {/* <p className='mb-0'>1. If payment made by crossed cheque in favour of Nutri Connect Pro.</p>
                                            <p className='mb-0'>2. If NEFT / RTGS / Online Transfer should be made to given bank details.</p>
                                            <p className='mb-0'>3. Late payment charges will be applicable.</p> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default ViewInvoice
