import React, { useEffect, useState } from "react"
import { Container } from "reactstrap"
import Breadcrumbs from "../../../../components/Common/Breadcrumb"
import { useNavigate, useParams } from "react-router-dom"
import { Row, Form, Table } from "reactstrap"
import Select from "react-select"
import axios from "axios"
import { getDecryptedData, getEncryptedData } from "pages/Config"

// assets
// import '../../../../assets/css/Sales/Sales.css';

const AddClientInvoice = () => {
  const [fixButtons, setFixButtons] = useState(false)
  const [termsRows, setTermsRows] = useState([{ id: 1 }])
  const [discountType, setDiscountType] = useState("")

  const [invoice_number, setInvoiceNumber] = useState("")
  const [customer_name, setCustomerName] = useState("")
  const [packageName, setPackageName] = useState("")
  const [invoice_date, setInvoiceDate] = useState("")
  const [terms, setTerms] = useState("")
  const [duedate, setDueDate] = useState("")
  const [startdate, setStartDate] = useState("")
  const [enddate, setEndDate] = useState("")
  const [customer_note, setCustomerNote] = useState("")
  const [discount, setDiscount] = useState(0) // Assuming discount is a number
  const [subtotal, setSubtotal] = useState(0) // Assuming subtotal is a number
  const [total, setTotal] = useState(0) // Assuming total is a number

  const [gst_no, setGstNo] = useState("")

  const [serviceDetails, setServiceDetails] = useState([
    {
      id: 1,
      service: "",
      hsn: "",
      quantity: "",
      rate: "",
      tax: "18",
      amount: "0",
    },
  ])

  const navigate = useNavigate()
  document.title = "Add Invoice | Nutri Connect Pro"

  const [allClientData, setAllClientData] = useState([])
  const [allPackages, setAllPackages] = useState([])

  const { client_id } = useParams()

  // Getting client and packages
  useEffect(() => {
    const formData = new FormData()
    formData.append("added_by", localStorage.getItem("adminId"))

    axios
      .post("https://api.nutriconnectpro.com/api/client_listing", formData, {
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      })
      .then(response => {
        const originalData = response.data.data.map(clients => ({
          ...clients,
          fullname: getDecryptedData(clients.fullname),
        }))
        setAllClientData(originalData)

        const client = originalData.filter(client => client.id == client_id)
        setCustomerName(client[0].fullname)
        setPackageName(client[0].package)
      })

    const formData3 = new FormData()
    formData3.append("added_by", localStorage.getItem("adminId"))

    axios
      .post("https://api.nutriconnectpro.com/api/invoice_no", formData3, {
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      })
      .then(response => {
        // console.log(response.data)
        setInvoiceNumber(response.data.data)
        setGstNo(response.data.gst)
      })

    // getting Packages details
    const formData2 = new FormData()
    formData2.append("added_by", localStorage.getItem("adminId"))
    axios
      .post("https://api.nutriconnectpro.com/api/getpackages", formData2, {
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      })
      .then(response => {
        // setAllPackages(res.data.data)
        const originalData = response.data.data.map(packages => ({
          ...packages,
          package_name: getDecryptedData(packages.package_name),
        }))
        const client = originalData.filter(client => client.id == client_id)
        setAllPackages(originalData)
      })
      .catch(err => console.log(err))
  }, [])

  useEffect(() => {
    let sTotal = 0
    serviceDetails.forEach(sd => (sTotal += sd.amount))

    setSubtotal(sTotal)
    setTotal(sTotal - (sTotal * discount) / 100)
  }, [serviceDetails, discount, discountType])

  document.addEventListener("scroll", () => {
    if (window.scrollY > 130) {
      setFixButtons(true)
    } else {
      setFixButtons(false)
    }
  })

  // form repeater
  const onAddTermsRow = id => {
    const modifiedRows = [...termsRows]
    modifiedRows.push({ id: modifiedRows.length + 1 })
    setTermsRows(modifiedRows)

    setServiceDetails([
      ...serviceDetails,
      {
        id: id,
        service: "",
        hsn: "",
        quantity: "",
        rate: "",
        tax: "18",
        amount: "0",
      },
    ])
  }

  const onDeleteTermsRow = id => {
    var modifiedRows = [...termsRows]
    modifiedRows = modifiedRows.filter(x => x["id"] !== id)
    setTermsRows(modifiedRows)

    setServiceDetails(prevForms => {
      const updatedForms = [...prevForms]
      return updatedForms.filter((serv, i) => serv.id !== id)
    })
  }

  const colourStyles = {
    menuList: styles => ({
      ...styles,
      background: "white",
    }),
    menu: base => ({
      ...base,
      zIndex: 50,
    }),
  }

  // collect service data
  const handleFormChange = (index, field, value) => {
    // Update the value of a specific field in a form at the specified index
    setServiceDetails(prevForms => {
      const updatedForms = [...prevForms]
      updatedForms[index][field] = value

      if (field == "quantity" || field == "rate") {
        updatedForms[index]["amount"] =
          updatedForms[index]["rate"] * updatedForms[index]["quantity"] +
          (gst_no !== null && gst_no != "null" && gst_no !== "undefined"
            ? (updatedForms[index]["rate"] *
                updatedForms[index]["quantity"] *
                18) /
              100
            : 0)
      }

      return updatedForms
    })
  }

  const handleAddInvoice = async e => {
    e.preventDefault()

    try {
      const formData = new FormData()

      formData.append("client_id", client_id)
      formData.append("invoice_number", invoice_number)
      formData.append("customer_name", getEncryptedData(customer_name))
      formData.append("package", packageName)
      formData.append("invoice_date", getEncryptedData(invoice_date))
      formData.append("terms", getEncryptedData(terms))
      formData.append("duedate", getEncryptedData(duedate))
      formData.append("startdate", getEncryptedData(startdate))
      formData.append("enddate", getEncryptedData(enddate))
      formData.append("customer_note", getEncryptedData(customer_note))
      formData.append("discount", getEncryptedData(discount))
      formData.append("discount_type", getEncryptedData(discountType))
      formData.append("subtotal", getEncryptedData(subtotal))
      formData.append("total", getEncryptedData(total))
      formData.append("unpaid_amount", getEncryptedData(total))
      formData.append(
        "service_details",
        getEncryptedData(JSON.stringify(serviceDetails))
      )
      formData.append("added_by", localStorage.getItem("adminId"))

      const response = await axios.post(
        "https://api.nutriconnectpro.com/api/add_client_invoice",
        formData,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "multipart/form-data",
          },
        }
      )

      swal("Invoice added successfully", {
        icon: "success",
      }).then(() => {
        navigate(-1)
      })
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <React.Fragment>
      <div className="page-content mb-4">
        <Container fluid>
          <Breadcrumbs title={"Dashboard"} breadcrumbItem={"Add Invoice"} />
          <Row className="px-1">
            <Form onSubmit={handleAddInvoice}>
              {/* Fixed Buttons */}
              <div
                className={`flex-column fixed-buttons ${
                  fixButtons ? "d-flex" : "d-none"
                }`}
              >
                <button className="btn btn-yellow border-radius fix-btn">
                  <i className="bx bx-send me-2"></i>
                  <span>Submit</span>
                </button>
                <a
                  onClick={() => navigate(-1)}
                  className="btn btn-dark border-radius fix-btn"
                >
                  <i className="fas fa-arrow-left me-2"></i>
                  <span>Back</span>
                </a>
              </div>
              <Row className="bg-white px-2 pt-4 pb-5 shadow mb-4">
                <div className="col-md-12 mb-4 text-end">
                  <button
                    onClick={e => {
                      e.preventDefault()
                      navigate(-1)
                    }}
                    className="border-radius btn btn-dark"
                  >
                    <i className="fas fa-arrow-left me-3"></i>Back
                  </button>
                  <button className="border-radius btn ms-md-3 btn-yellow">
                    <i className="bx bx-send me-1"></i> Submit
                  </button>
                </div>
                <Row className="pe-0">
                  {/* <div className="col-md-3 mb-4">
                                        <label htmlFor="" className='fs-5'>Invoice Type <span className='text-danger'>*</span> </label>
                                        <Select
                                            placeholder="Select Invoice Type"
                                            className="basic-multi-select"
                                            classNamePrefix="border-radius select"
                                            styles={colourStyles}
                                            options={invoiceTypes} />
                                    </div> */}
                  <div className="col-md-3 mb-4">
                    <label htmlFor="" className="fs-5">
                      Invoice Number
                    </label>
                    <input
                      type="text"
                      // defaultValue="INV/734884734847"
                      value={invoice_number}
                      onChange={e => setInvoiceNumber(e.target.value)}
                      placeholder="Enter Invoice Number"
                      className="form-field border-radius"
                      disabled
                    />
                  </div>
                  <div className="col-md-3 mb-4">
                    <label htmlFor="" className="fs-5">
                      Customer Name <span className="text-danger">*</span>{" "}
                    </label>
                    <select
                      name=""
                      id=""
                      value={customer_name}
                      onChange={e => setCustomerName(e.target.value)}
                      className="form-field border-radius"
                    >
                      <option value="">Nothing Selected</option>
                      {allClientData?.map(pack => {
                        return (
                          <option key={pack.id} value={pack.fullname}>
                            {" "}
                            {pack.fullname}{" "}
                          </option>
                        )
                      })}
                    </select>
                  </div>
                  {/* {
                                        customer.introducer ?
                                            <div className='col-md-3 mb-4'>
                                                <label htmlFor="" className='fs-5'>Introducer</label>
                                                <input type="text"
                                                    value={customer.introducer}
                                                    readOnly
                                                    className='form-field border-radius' />
                                            </div> : null
                                    } */}
                  <div className={`col-md-6 mb-4`}>
                    <label htmlFor="" className="fs-5">
                      Package
                    </label>
                    <select
                      name=""
                      id=""
                      value={packageName}
                      onChange={e => setPackageName(e.target.value)}
                      className="form-field border-radius"
                    >
                      <option value="">Nothing Selected</option>
                      {allPackages?.map(pack => {
                        return (
                          <option key={pack.id} value={pack.id}>
                            {" "}
                            {pack.package_name}{" "}
                          </option>
                        )
                      })}
                    </select>
                  </div>

                  <div className="col-md-8">
                    <Row>
                      <div className="col-md-4">
                        <label htmlFor="" className="fs-5">
                          Invoice Date <span className="text-danger">*</span>{" "}
                        </label>
                        <input
                          type="date"
                          value={invoice_date}
                          onChange={e => setInvoiceDate(e.target.value)}
                          className="form-field border-radius"
                        />
                      </div>

                      <div className="col-md-4 mb-md-0">
                        <label htmlFor="" className="fs-5">
                          Terms <span className="text-danger">*</span>
                        </label>

                        <select
                          className="form-field"
                          value={terms}
                          onChange={e => {
                            if (e.target.value == "Due in 15 days") {
                              const newDate = new Date(invoice_date)
                              newDate.setDate(newDate.getDate() + 15)
                              setDueDate(newDate.toISOString().split("T")[0])
                            } else if (e.target.value == "Due on Receipt") {
                              setDueDate(invoice_date)
                            } else {
                              setDueDate("")
                            }
                            setTerms(e.target.value)
                          }}
                        >
                          <option value="">-- Select --</option>
                          <option value="Due on Receipt">Due on Receipt</option>
                          <option value="Due in 15 days">Due in 15 days</option>
                        </select>
                      </div>

                      <div className="col-md-4">
                        <label htmlFor="" className="fs-5">
                          Due Date{" "}
                        </label>
                        <input
                          type="date"
                          value={duedate}
                          className="form-field border-radius"
                          readOnly
                        />
                      </div>
                    </Row>
                  </div>

                  <div className="col-md-4 mb-4">
                    <Row>
                      <div className="col-6">
                        <label htmlFor="" className="fs-5">
                          Start Date{" "}
                        </label>
                        <input
                          type="date"
                          value={startdate}
                          onChange={e => setStartDate(e.target.value)}
                          className="form-field border-radius"
                        />
                      </div>
                      <div className="col-6">
                        <label htmlFor="" className="fs-5">
                          End Date{" "}
                        </label>
                        <input
                          type="date"
                          value={enddate}
                          onChange={e => setEndDate(e.target.value)}
                          className="form-field border-radius"
                        />
                      </div>
                    </Row>
                  </div>
                </Row>

                <div className="responsive-table mt-4">
                  <Table className="table table-bordered mb-0 mt-3">
                    <thead className="table-green">
                      <tr>
                        <th className="col-1 text-center">#</th>
                        <th>SERVICE DETAILS</th>
                        {gst_no !== null &&
                          gst_no != "null" &&
                          gst_no !== "undefined" && <th>HSN</th>}

                        <th>QUANTITY</th>
                        <th className="text-center">RATE</th>
                        {gst_no !== null &&
                          gst_no != "null" &&
                          gst_no !== "undefined" && (
                            <th className="text-center">TAX</th>
                          )}

                        <th className="text-center">AMOUNT</th>
                        <th className="text-center">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {(termsRows || []).map((termsRow, key) => (
                        <tr key={termsRow.id}>
                          <td className="text-center">{termsRow.id}</td>
                          <td className="col-4">
                            <input
                              type="text"
                              value={serviceDetails[key].service}
                              onChange={e =>
                                handleFormChange(key, "service", e.target.value)
                              }
                              placeholder="Enter Service Details"
                              className="form-field border-radius"
                            />
                          </td>
                          {gst_no !== null &&
                            gst_no != "null" &&
                            gst_no !== "undefined" && (
                              <td className="col-2">
                                <select
                                  value={serviceDetails[key].hsn}
                                  onChange={e =>
                                    handleFormChange(key, "hsn", e.target.value)
                                  }
                                  className="form-select form-field border-radius"
                                >
                                  <option value=""> -- Select -- </option>
                                  <option value="HSN 1">HSN 1</option>
                                  <option value="HSN 2">HSN 2</option>
                                  <option value="HSN 3">HSN 3</option>
                                </select>
                              </td>
                            )}
                          <td className="col-1">
                            <input
                              type="text"
                              value={serviceDetails[key].quantity}
                              onChange={e =>
                                handleFormChange(
                                  key,
                                  "quantity",
                                  e.target.value
                                )
                              }
                              placeholder="Qty."
                              className="form-field border-radius"
                            />
                          </td>
                          <td className="col-1">
                            <input
                              type="text"
                              value={serviceDetails[key].rate}
                              onChange={e =>
                                handleFormChange(key, "rate", e.target.value)
                              }
                              className="form-field border-radius"
                            />
                          </td>
                          {gst_no !== null &&
                            gst_no != "null" &&
                            gst_no !== "undefined" && (
                              <td className="col-1 text-center">
                                {serviceDetails[key].tax}
                              </td>
                            )}
                          <td className="col-1 text-center">
                            {serviceDetails[key].amount}
                          </td>
                          <td className="col-1 text-center">
                            {key === 0 ? (
                              <a
                                onClick={() =>
                                  onAddTermsRow(termsRows.length + 1)
                                }
                                className="btn btn-success"
                              >
                                <i className="fas fa-plus"></i>
                              </a>
                            ) : (
                              <a
                                onClick={() => onDeleteTermsRow(termsRow.id)}
                                className="btn btn-danger"
                              >
                                <i className="fas fa-trash-alt"></i>
                              </a>
                            )}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </div>

                <Row className="mt-5 justify-content-between">
                  <div className="col-md-7">
                    <label htmlFor="" className="fs-5">
                      Customer Notes
                    </label>
                    <textarea
                      name="customer_note"
                      value={customer_note}
                      onChange={e => setCustomerNote(e.target.value)}
                      className="form-field"
                      id=""
                      cols="35"
                      rows="5"
                    />
                  </div>

                  <div className="col-md-5">
                    <div className="total-box p-4 px-5 rounded">
                      <div className="d-flex py-2 justify-content-between">
                        <strong>Sub Total</strong>
                        <span>{Number(subtotal).toFixed(2)}</span>
                      </div>

                      <div className="py-2 d-flex align-items-center justify-content-between">
                        <select
                          onChange={e => {
                            if (e.target.value == "Fixed") {
                              setDiscount(4)
                            } else if (e.target.value == "") {
                              setDiscount(0)
                            }
                            setDiscountType(e.target.value)
                          }}
                          className="form-select form-field border-radius w-50 "
                          id="discount"
                        >
                          <option value="">Select Discount</option>
                          <option value="Percentage">Percentage(%)</option>
                          <option value="Fixed">Fixed</option>
                        </select>
                        {discountType !== "" ? (
                          <input
                            type="text"
                            value={discount}
                            onChange={e => setDiscount(e.target.value)}
                            className="form-field w-25 me-md-3"
                            disabled={discountType == "Fixed" ? true : false}
                          />
                        ) : null}
                        <span>
                          {(
                            subtotal -
                            (subtotal - (subtotal * discount) / 100)
                          ).toFixed(2)}
                        </span>
                      </div>

                      <div className="d-flex pt-2 mt-3 border-top border-secondary pb-2 justify-content-between">
                        <strong> Total (₹)</strong>
                        <span>{Number(total).toFixed(2)}</span>
                      </div>
                    </div>
                  </div>
                </Row>
              </Row>
            </Form>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default AddClientInvoice
