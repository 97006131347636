import React, { useState, useEffect } from "react"
import { Col, Container, Row, Table } from "reactstrap"
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { Card } from "reactstrap"

// Import Components
import MonthlyEarning from "../Dashboard/MonthlyEarning"
import WelcomeComp from "../Dashboard/WelcomeComp"
import { Link, useNavigate } from "react-router-dom"
import { CardBody } from "reactstrap"

import user from "../../assets/default-user.png"

import avatar from "../../assets/images/users/avatar-1.jpg"

//i18n
import { withTranslation } from "react-i18next"
import axios from "axios"
import Loader from "pages/Loader"
import AllInvoices from "./AllInvoices"
import AllFollowUps from "./AllFollowUps"
import { getDecryptedData, getEncryptedData } from "pages/Config"
import { ConfigUrl } from "../../ConfigUrl"

const ClientDashboard = props => {
  const [allCounts, setAllCounts] = useState({})
  const [loading, setLoading] = useState({})
  const [allInvoices, setAllInvoices] = useState([])

  const navigate = useNavigate()

  useEffect(() => {
    if (localStorage.getItem("sAdminUser") && localStorage.getItem("superId")) {
      navigate("/dashboard")
    }
  }, [])

  useEffect(() => {
    setLoading(true)
    const formData = new FormData()

    formData.append("added_by", localStorage.getItem("adminId"))

    axios
      .post(`${ConfigUrl.apiUrl}dashboard_admin`, formData, {
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      })
      .then(response => {
        // console.log(response.data)
        setAllCounts(response.data.data)
        setLoading(false)
      })
      .catch(err => console.log(err))
  }, [])

  // getting all invoices
  useEffect(() => {
    setLoading(true)

    const formData = new FormData()

    formData.append("added_by", localStorage.getItem("adminId"))

    axios
      .post(`${ConfigUrl.apiUrl}getinvoices`, formData, {
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      })
      .then(response => {
        setLoading(false)
        setAllInvoices(response.data.data)
      })
      .catch(err => console.log(err))
  }, [])

  //meta title
  document.title = "Dashboard | Nutri Connect Pro "

  const reports = [
    {
      title: "All Clients",
      iconClass: "fas fa-users",
      description: allCounts?.all_clients,
      color: "purple",
      link: `${ConfigUrl.appUrl}all-clients`,
      textColor: "#3b2d8e",
    },
    {
      title: "Active Clients",
      iconClass: "fas fa-check-circle ",
      description: allCounts?.active_clients,
      color: "cyan",
      link: `${ConfigUrl.appUrl}active-clients`,
      textColor: "#2a797d",
    },
    {
      title: "Deactivate Clients",
      iconClass: "bx bx-purchase-tag-alt",
      description: allCounts?.deactive_clients,
      color: "pink",
      link: `${ConfigUrl.appUrl}deactivate-clients`,
      textColor: "#911b91",
    },
    {
      title: "Invoices",
      iconClass: "fas fa-book fs-4",
      description: allCounts?.invoices,
      color: "yellow",
      link: `${ConfigUrl.appUrl}invoices`,
      textColor: "#735521",
    },
  ]

  return (
    <React.Fragment>
      {loading && <Loader />}

      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title={props.t("Dashboard")}
            breadcrumbItem={props.t("Dashboard")}
          />
          <Row className="px-md-0 px-3 mb-5">
            <Col
              xl={12}
              className="mb-4 px-0 d-md-flex justify-content-between align-items-center"
            >
              <div className="d-flex align-items-center">
                <img src={user} alt="" className="avatar-sm rounded" />
                <div className="ms-3 flex-grow-1">
                  <h5 className="mb-2 card-title">
                    Hello, {getDecryptedData(localStorage.getItem("adminUser"))}
                  </h5>
                  <p className="text-muted mb-0">Welcome to Admin Dashboard</p>
                </div>
              </div>
              <div className="mt-md-0 mt-4 text-">
                <Link
                  to={`${ConfigUrl.appUrl}add-client`}
                  className="btn btn-green btn-cyan border-radius px-4"
                >
                  <i className="fas fa-plus me-2"></i> Add New Client
                </Link>
              </div>
            </Col>

            <Col xl={12} className=" px-0">
              <Row>
                {/* Reports Render */}
                {reports.map((report, key) => (
                  <Col md="3" key={"_col_" + key}>
                    <Card className="mini-stats-wid">
                      <CardBody className="shadow">
                        <Link to={report.link} className="text-dark">
                          <div className="d-flex">
                            <div className="flex-grow-1 ">
                              <p className="text-muted fw-medium fs-5">
                                {report.title}
                              </p>
                              <h4 className="mb-0">{report.description}</h4>
                            </div>
                            <div className="avatar-sm rounded-circle  align-self-center mini-stat-icon">
                              <span
                                className={`avatar-title rounded-circle bg-light-${report.color}`}
                              >
                                <i
                                  className={report.iconClass + " fs-3"}
                                  style={{ color: report.textColor }}
                                ></i>
                              </span>
                            </div>
                          </div>
                        </Link>
                      </CardBody>
                    </Card>
                  </Col>
                ))}
              </Row>

              <Row>
                <div className="col-md-6">
                  <Card className="mini-stats-wid">
                    <CardBody className="shadow">
                      <AllFollowUps />
                    </CardBody>
                  </Card>
                </div>
                <div className="col-md-6">
                  <AllInvoices />
                </div>
              </Row>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withTranslation()(ClientDashboard)
