import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"

import {
  Col,
  Container,
  Form,
  Input,
  Label,
  Modal,
  ModalBody,
  Row,
  Table,
} from "reactstrap"
//css
import "@fullcalendar/bootstrap/main.css"

import "@fullcalendar/react/dist/vdom"
import FullCalendar from "@fullcalendar/react"
import dayGridPlugin from "@fullcalendar/daygrid"
import interactionPlugin, { Draggable } from "@fullcalendar/interaction"
import BootstrapTheme from "@fullcalendar/bootstrap"
import { useNavigate, useParams } from "react-router-dom"
import axios from "axios"
import swal from "sweetalert"
import moment from "moment"
import { getDecryptedData, getEncryptedData } from "pages/Config"
import Loader from "pages/Loader"

const AddDietPlan = props => {
  //meta title
  document.title = "Add Diet Plan | Nutri Connect Pro"

  const [dietEvents, setDietEvents] = useState([])

  // for form repeater
  const [wakingRows, setWakingRows] = useState([{ id: 1 }])
  const [breakfastRows, setBreakfastRows] = useState([{ id: 1 }])
  const [mealRows, setMealRows] = useState([{ id: 1 }])
  const [lunchRows, setLunchRows] = useState([{ id: 1 }])
  const [snackRows, setSnackRows] = useState([{ id: 1 }])
  const [DinnerRows, setDinnerRows] = useState([{ id: 1 }])
  const [postDinnerRows, setPostDinnerRows] = useState([{ id: 1 }])
  // { time: "", desc: "" }
  const [wakingUpFoods, setWakingUpFoods] = useState([
    { time: "", desc: "", isDone: 0, id: 1 },
  ])
  const [breakfastFoods, setBreakfastFoods] = useState([
    { time: "", desc: "", isDone: 0, id: 1 },
  ])
  const [midMealFoods, setMidMealFoods] = useState([
    { time: "", desc: "", isDone: 0, id: 1 },
  ])
  const [lunchFoods, setLunchFoods] = useState([
    { time: "", desc: "", isDone: 0, id: 1 },
  ])
  const [eveningSnackFoods, setEveningSnackFoods] = useState([
    { time: "", desc: "", isDone: 0, id: 1 },
  ])
  const [dinnerFoods, setDinnerFoods] = useState([
    { time: "", desc: "", isDone: 0, id: 1 },
  ])
  const [postDinnerFoods, setPostDinnerFoods] = useState([
    { time: "", desc: "", isDone: 0, id: 1 },
  ])

  const [planDate, setPlanDate] = useState("")
  const [planNote, setPlanNote] = useState("")
  const [plan_id, setPlanId] = useState("")
  // all clients
  const [allClientData, setAllClientData] = useState([])
  const [clientName, setClientName] = useState("")

  const [activePlans, setActivePlans] = useState([])
  const [prevPlan, setPrevPlan] = useState("")
  const [loading2, setLoading2] = useState(false)
  const [loading, setLoading] = useState(false)

  const { client_id } = useParams()
  // const client_id = 5;

  const navigate = useNavigate()

  useEffect(() => {
    if (localStorage.getItem("sAdminUser") && localStorage.getItem("superId")) {
      navigate("/dashboard")
    }
  }, [])

  // GET ACTIVE PLANS
  useEffect(() => {
    const getActivePlans = async () => {
      try {
        const formData = new FormData()
        formData.append("client_id", client_id)
        formData.append("added_by", localStorage.getItem("adminId"))
        // https://api.nutriconnectpro.com/api/client_dietplan

        const response = await axios.post(
          "https://api.nutriconnectpro.com/api/client_dietplan",
          formData,
          {
            headers: {
              Accept: "application/json",
              "Content-Type": "multipart/form-data",
            },
          }
        )
        const newPlans = response.data.filter(plan => plan.status === 1)

        setActivePlans(newPlans)
      } catch (error) {
        console.log(error)
      }
    }
    getActivePlans()
  }, [client_id])

  // generating Events to show plan on date
  useEffect(() => {
    setLoading(true)
    const formData = new FormData()
    formData.append("client_id", client_id)
    formData.append("added_by", localStorage.getItem("adminId"))

    axios
      .post("https://api.nutriconnectpro.com/api/client_dietplan", formData, {
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      })
      .then(response => {
        let planEvents = []

        const activePlans = response.data.filter(plan => plan.status === 1)

        activePlans.forEach(plan => {
          planEvents.push({
            id: Math.floor(Math.random() * 100),
            title: "Active Plan",
            start: new Date(plan.date),
            className: "bg-success text-white",
            planDate: plan.date,
          })
        })

        const InactivePlans = response.data.filter(plan => plan.status === 0)

        InactivePlans.forEach(plan => {
          planEvents.push({
            id: Math.floor(Math.random() * 100),
            title: "",
            start: new Date(plan.date),
            className: "d-inline in-active bg-transparent text-danger",
            planDate: plan.date,
          })
        })

        setDietEvents(planEvents)
        setLoading(false)
      })
      .catch(err => console.log(err))
  }, [client_id])

  // form repeater
  const onAddRow = (rows, setRows, setFoods) => {
    const modifiedRows = [...rows]
    modifiedRows.push({ id: modifiedRows.length + 1 })
    setRows(modifiedRows)

    setFoods(foods => [
      ...foods,
      { time: "", desc: "", isDone: 0, id: foods[foods.length - 1].id + 1 },
    ])
  }

  const onDeleteRow = (id, rows, setRows, setFoods, index) => {
    var modifiedRows = [...rows]
    modifiedRows = modifiedRows.filter(x => x["id"] !== id)
    setRows(modifiedRows)

    setFoods(foods => {
      const updatedForms = [...foods]
      const newdata = updatedForms.filter((serv, ind) => ind !== index)

      return newdata.map((data, i) => ({ ...data, id: i + 1 }))
    })
  }

  // changing food details accordian
  const handleChangeFoodData = (index, setFood, value, keyname) => {
    setFood(foods => {
      let oldFood = [...foods]

      oldFood[index][keyname] = value
      return oldFood
    })
  }

  // getting all clients
  useEffect(() => {
    const formData = new FormData()
    formData.append("added_by", localStorage.getItem("adminId"))

    axios
      .post("https://api.nutriconnectpro.com/api/client_listing", formData, {
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      })
      .then(response => {
        const originalData = response.data.data.map(clients => ({
          ...clients,
          fullname: getDecryptedData(clients.fullname),
        }))
        setAllClientData(originalData)

        const currClient = originalData.filter(
          clients => clients.id == client_id
        )

        // if (currClient.lenght > 0)
        setClientName(currClient[0].fullname)
      })
      .catch(err => console.log(err))
  }, [])

  // useEffect(() => console.log(events), [events])

  const [modal, setModal] = useState(false)

  const [selectedDay, setSelectedDay] = useState(0)
  const [isEdit, setIsEdit] = useState(false)

  /**
   * Handling the modal state
   */
  const toggle = () => {
    if (modal) {
      setModal(false)
      setIsEdit(false)
    } else {
      setModal(true)
    }
  }

  /**
   * Handling date click on calendar
   */
  const handleDateClick = arg => {
    const checkk = dietEvents.filter(test => test.planDate == arg.dateStr)

    if (!checkk[0].className.includes("in-active")) {
      handleEventClick(checkk[0].planDate)
      return
    }

    setPlanDate(arg.dateStr)

    setWakingUpFoods([{ time: "", desc: "" }])
    setBreakfastFoods([{ time: "", desc: "" }])
    setMidMealFoods([{ time: "", desc: "" }])
    setLunchFoods([{ time: "", desc: "" }])
    setEveningSnackFoods([{ time: "", desc: "" }])
    setDinnerFoods([{ time: "", desc: "" }])
    setPostDinnerFoods([{ time: "", desc: "" }])
    setPlanNote("")

    setWakingRows([{ id: 1 }])
    setBreakfastRows([{ id: 1 }])
    setMealRows([{ id: 1 }])
    setLunchRows([{ id: 1 }])
    setSnackRows([{ id: 1 }])
    setDinnerRows([{ id: 1 }])
    setPostDinnerRows([{ id: 1 }])
    // checking if date allowed or not ==========================================
    const formData = new FormData()

    formData.append("client_id", client_id)
    formData.append("date", arg.dateStr)

    axios
      .post("https://api.nutriconnectpro.com/api/check_date", formData, {
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      })
      .then(res => {
        if (res.data.message == "allowed" && res.data.data == "success") {
          toggle()
        } else {
          swal(
            "",
            "Sorry! You are not allowed to add plan on this date.",
            "error"
          )
        }
      })
      .catch(err => {
        console.log(err)
      })

    // ==============================================================================

    const date = arg["date"]
    const day = date.getDate()
    const month = date.getMonth()
    const year = date.getFullYear()

    const currectDate = new Date()
    const currentHour = currectDate.getHours()
    const currentMin = currectDate.getMinutes()
    const currentSec = currectDate.getSeconds()

    const modifiedDate = new Date(
      year,
      month,
      day,
      currentHour,
      currentMin,
      currentSec
    )
    const modifiedData = { ...arg, date: modifiedDate }
    // console.log(modifiedData)
    setSelectedDay(modifiedData)
  }

  // handle save dietplan data
  const handleSaveDietPlan = async e => {
    e.preventDefault()

    // console.log(planDate)

    try {
      let times = []

      wakingUpFoods.forEach(food => times.push(food.time))
      breakfastFoods.forEach(food => times.push(food.time))
      midMealFoods.forEach(food => times.push(food.time))
      lunchFoods.forEach(food => times.push(food.time))
      dinnerFoods.forEach(food => times.push(food.time))
      eveningSnackFoods.forEach(food => times.push(food.time))
      postDinnerFoods.forEach(food => times.push(food.time))

      const formData = new FormData()
      formData.append(
        "on_waking_up",
        getEncryptedData(JSON.stringify(wakingUpFoods))
      )
      formData.append(
        "breakfast",
        getEncryptedData(JSON.stringify(breakfastFoods))
      )
      formData.append(
        "mid_meal",
        getEncryptedData(JSON.stringify(midMealFoods))
      )
      formData.append("lunch", getEncryptedData(JSON.stringify(lunchFoods)))
      formData.append("dinner", getEncryptedData(JSON.stringify(dinnerFoods)))
      formData.append(
        "evening_snack",
        getEncryptedData(JSON.stringify(eveningSnackFoods))
      )
      formData.append(
        "post_dinner",
        getEncryptedData(JSON.stringify(postDinnerFoods))
      )
      formData.append("client_id", client_id)
      formData.append("date", planDate)
      formData.append("note", getEncryptedData(planNote))
      formData.append("time_data", JSON.stringify(times))

      formData.append("added_by", localStorage.getItem("adminId"))

      const response = await axios.post(
        "https://api.nutriconnectpro.com/api/add_dietplan",
        formData,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "multipart/form-data",
          },
        }
      )

      setModal(false)

      swal("", "Diet Plan added successfully", "success").then(() => {
        setWakingUpFoods([{ time: "", desc: "", isDone: 0, id: 1 }])
        setBreakfastFoods([{ time: "", desc: "", isDone: 0, id: 1 }])
        setMidMealFoods([{ time: "", desc: "", isDone: 0, id: 1 }])
        setLunchFoods([{ time: "", desc: "", isDone: 0, id: 1 }])
        setEveningSnackFoods([{ time: "", desc: "", isDone: 0, id: 1 }])
        setDinnerFoods([{ time: "", desc: "", isDone: 0, id: 1 }])
        setPostDinnerFoods([{ time: "", desc: "", isDone: 0, id: 1 }])

        setWakingRows([{ id: 1 }])
        setBreakfastRows([{ id: 1 }])
        setMealRows([{ id: 1 }])
        setLunchRows([{ id: 1 }])
        setSnackRows([{ id: 1 }])
        setDinnerRows([{ id: 1 }])
        setPostDinnerRows([{ id: 1 }])
        setPlanDate("")
        setPlanNote("")

        window.location.reload()
      })
    } catch (error) {
      console.log(error)
    }
  }

  // Edit Diet Plan =====================================================
  const handleEditDietPlan = async () => {
    try {
      const formData = new FormData()
      formData.append(
        "on_waking_up",
        getEncryptedData(JSON.stringify(wakingUpFoods))
      )
      formData.append(
        "breakfast",
        getEncryptedData(JSON.stringify(breakfastFoods))
      )
      formData.append(
        "mid_meal",
        getEncryptedData(JSON.stringify(midMealFoods))
      )
      formData.append("lunch", getEncryptedData(JSON.stringify(lunchFoods)))
      formData.append("dinner", getEncryptedData(JSON.stringify(dinnerFoods)))
      formData.append(
        "evening_snack",
        getEncryptedData(JSON.stringify(eveningSnackFoods))
      )
      formData.append(
        "post_dinner",
        getEncryptedData(JSON.stringify(postDinnerFoods))
      )
      formData.append("client_id", client_id)
      formData.append("date", planDate)
      formData.append("note", getEncryptedData(planNote))
      formData.append("plan_id", plan_id)

      const response = await axios.post(
        "https://api.nutriconnectpro.com/api/edit_dietplan",
        formData,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "multipart/form-data",
          },
        }
      )

      setModal(false)

      swal("", "Diet Plan Updated successfully", "success").then(() => {
        setWakingUpFoods([{ time: "", desc: "", isDone: 0, id: 1 }])
        setBreakfastFoods([{ time: "", desc: "", isDone: 0, id: 1 }])
        setMidMealFoods([{ time: "", desc: "", isDone: 0, id: 1 }])
        setLunchFoods([{ time: "", desc: "", isDone: 0, id: 1 }])
        setEveningSnackFoods([{ time: "", desc: "", isDone: 0, id: 1 }])
        setDinnerFoods([{ time: "", desc: "", isDone: 0, id: 1 }])
        setPostDinnerFoods([{ time: "", desc: "", isDone: 0, id: 1 }])

        setWakingRows([{ id: 1 }])
        setBreakfastRows([{ id: 1 }])
        setMealRows([{ id: 1 }])
        setLunchRows([{ id: 1 }])
        setSnackRows([{ id: 1 }])
        setDinnerRows([{ id: 1 }])
        setPostDinnerRows([{ id: 1 }])
        setPlanDate("")
        setPlanNote("")
        setPlanId("")
      })
    } catch (error) {
      console.log(error)
    }
  }

  /*
      Handling click on event on calendar
       */
  const handleEventClick = arg => {
    let PlanDate

    if (arg.event) {
      PlanDate = arg.event._def.extendedProps.planDate
    } else {
      PlanDate = arg
    }
    setPlanDate(PlanDate)

    const formData = new FormData()
    formData.append("client_id", client_id)
    formData.append("date", PlanDate)
    formData.append("added_by", localStorage.getItem("adminId"))

    axios
      .post("https://api.nutriconnectpro.com/api/view_dietplan", formData, {
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      })
      .then(response => {
        if (response.data.data.length > 0) {
          setWakingUpFoods(
            JSON.parse(getDecryptedData(response.data.data[0].on_waking_up))
          )
          setBreakfastFoods(
            JSON.parse(getDecryptedData(response.data.data[0].breakfast))
          )
          setMidMealFoods(
            JSON.parse(getDecryptedData(response.data.data[0].mid_meal))
          )
          setLunchFoods(
            JSON.parse(getDecryptedData(response.data.data[0].lunch))
          )
          setEveningSnackFoods(
            JSON.parse(getDecryptedData(response.data.data[0].evening_snack))
          )
          setDinnerFoods(
            JSON.parse(getDecryptedData(response.data.data[0].dinner))
          )
          setPostDinnerFoods(
            JSON.parse(getDecryptedData(response.data.data[0].post_dinner))
          )
          setPlanDate(response.data.data[0].date)
          setPlanNote(getDecryptedData(response.data.data[0].note))
          setPlanId(response.data.data[0].id)

          // console.log(response.data.data[0].id)
          let tempRows = []
          JSON.parse(
            getDecryptedData(response.data.data[0].on_waking_up)
          ).forEach((data, index) => {
            tempRows.push({ id: index + 1 })
          })
          setWakingRows(tempRows)

          tempRows = []
          JSON.parse(getDecryptedData(response.data.data[0].breakfast)).forEach(
            (data, index) => {
              tempRows.push({ id: index + 1 })
            }
          )
          setBreakfastRows(tempRows)

          tempRows = []
          JSON.parse(getDecryptedData(response.data.data[0].mid_meal)).forEach(
            (data, index) => {
              tempRows.push({ id: index + 1 })
            }
          )
          setMealRows(tempRows)

          tempRows = []
          JSON.parse(getDecryptedData(response.data.data[0].lunch)).forEach(
            (data, index) => {
              tempRows.push({ id: index + 1 })
            }
          )
          setLunchRows(tempRows)

          tempRows = []
          JSON.parse(
            getDecryptedData(response.data.data[0].evening_snack)
          ).forEach((data, index) => {
            tempRows.push({ id: index + 1 })
          })
          setSnackRows(tempRows)

          tempRows = []
          JSON.parse(getDecryptedData(response.data.data[0].dinner)).forEach(
            (data, index) => {
              tempRows.push({ id: index + 1 })
            }
          )
          setDinnerRows(tempRows)

          tempRows = []
          JSON.parse(
            getDecryptedData(response.data.data[0].post_dinner)
          ).forEach((data, index) => {
            tempRows.push({ id: index + 1 })
          })
          setPostDinnerRows(tempRows)

          setIsEdit(true)
        } else {
          setWakingUpFoods([{ time: "", desc: "", isDone: 0, id: 1 }])
          setBreakfastFoods([{ time: "", desc: "", isDone: 0, id: 1 }])
          setMidMealFoods([{ time: "", desc: "", isDone: 0, id: 1 }])
          setLunchFoods([{ time: "", desc: "", isDone: 0, id: 1 }])
          setEveningSnackFoods([{ time: "", desc: "", isDone: 0, id: 1 }])
          setDinnerFoods([{ time: "", desc: "", isDone: 0, id: 1 }])
          setPostDinnerFoods([{ time: "", desc: "", isDone: 0, id: 1 }])

          setWakingRows([{ id: 1 }])
          setBreakfastRows([{ id: 1 }])
          setMealRows([{ id: 1 }])
          setLunchRows([{ id: 1 }])
          setSnackRows([{ id: 1 }])
          setDinnerRows([{ id: 1 }])
          setPostDinnerRows([{ id: 1 }])
          setPlanDate("")
          setPlanNote("")
        }
        toggle()
      })
      .catch(err => console.log(err))

    // setEvent({
    //     id: event.id,
    //     title: event.title,
    //     title_category: event.title_category,
    //     start: event.start,
    //     className: event.classNames,
    //     category: event.classNames[0],
    //     event_category: event.classNames[0],
    // });
    // setIsEdit(true);
  }

  // Delete Plan Logic
  const onDeletePlan = () => {
    swal({
      title: "Are you sure?",
      text: "do you really want to delete this Diet Plan",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then(willDelete => {
      if (willDelete) {
        const formData = new FormData()

        formData.append("plan_id", plan_id)
        formData.append("client_id", client_id)
        formData.append("date", planDate)
        formData.append("added_by", localStorage.getItem("adminId"))

        axios
          .post(
            "https://api.nutriconnectpro.com/api/delete_dietplan",
            formData,
            {
              headers: {
                Accept: "application/json",
                "Content-Type": "multipart/form-data",
              },
            }
          )
          .then(response => {
            // console.log(response)

            swal("Diet Plan has been deleted successfully!", {
              icon: "success",
            }).then(() => {
              toggle()

              window.location.reload()
            })
          })
      }
    })
  }

  // get Previous plan
  const fetchDietPlan = () => {
    if (prevPlan != "" && prevPlan != "Copy form") {
      setLoading2(true)
      const formData = new FormData()
      formData.append("client_id", client_id)
      formData.append("date", prevPlan)

      axios
        .post("https://api.nutriconnectpro.com/api/view_dietplan", formData, {
          headers: {
            Accept: "application/json",
            "Content-Type": "multipart/form-data",
          },
        })
        .then(response => {
          let tempFoods = JSON.parse(
            getDecryptedData(response.data.data[0].on_waking_up)
          )
          setWakingUpFoods(tempFoods.map(food => ({ ...food, isDone: 0 })))

          tempFoods = JSON.parse(
            getDecryptedData(response.data.data[0].breakfast)
          )
          setBreakfastFoods(tempFoods.map(food => ({ ...food, isDone: 0 })))

          tempFoods = JSON.parse(
            getDecryptedData(response.data.data[0].mid_meal)
          )
          setMidMealFoods(tempFoods.map(food => ({ ...food, isDone: 0 })))

          tempFoods = JSON.parse(getDecryptedData(response.data.data[0].lunch))
          setLunchFoods(tempFoods.map(food => ({ ...food, isDone: 0 })))

          tempFoods = JSON.parse(
            getDecryptedData(response.data.data[0].evening_snack)
          )
          setEveningSnackFoods(tempFoods.map(food => ({ ...food, isDone: 0 })))

          tempFoods = JSON.parse(getDecryptedData(response.data.data[0].dinner))
          setDinnerFoods(tempFoods.map(food => ({ ...food, isDone: 0 })))

          tempFoods = JSON.parse(
            getDecryptedData(response.data.data[0].post_dinner)
          )
          setPostDinnerFoods(tempFoods.map(food => ({ ...food, isDone: 0 })))

          setPlanNote(getDecryptedData(response.data.data[0].note))

          let tempRows = []
          JSON.parse(
            getDecryptedData(response.data.data[0].on_waking_up)
          ).forEach((data, index) => {
            tempRows.push({ id: index + 1 })
          })
          setWakingRows(tempRows)

          tempRows = []
          JSON.parse(getDecryptedData(response.data.data[0].breakfast)).forEach(
            (data, index) => {
              tempRows.push({ id: index + 1 })
            }
          )
          setBreakfastRows(tempRows)

          tempRows = []
          JSON.parse(getDecryptedData(response.data.data[0].mid_meal)).forEach(
            (data, index) => {
              tempRows.push({ id: index + 1 })
            }
          )
          setMealRows(tempRows)

          tempRows = []
          JSON.parse(getDecryptedData(response.data.data[0].lunch)).forEach(
            (data, index) => {
              tempRows.push({ id: index + 1 })
            }
          )
          setLunchRows(tempRows)

          tempRows = []
          JSON.parse(
            getDecryptedData(response.data.data[0].evening_snack)
          ).forEach((data, index) => {
            tempRows.push({ id: index + 1 })
          })
          setSnackRows(tempRows)

          tempRows = []
          JSON.parse(getDecryptedData(response.data.data[0].dinner)).forEach(
            (data, index) => {
              tempRows.push({ id: index + 1 })
            }
          )
          setDinnerRows(tempRows)

          tempRows = []
          JSON.parse(
            getDecryptedData(response.data.data[0].post_dinner)
          ).forEach((data, index) => {
            tempRows.push({ id: index + 1 })
          })
          setPostDinnerRows(tempRows)

          setLoading2(false)
        })
        .catch(err => {
          setLoading2(false)
          console.log(err)
        })
    } else {
      swal("Please Select Date")
    }
  }

  return (
    <React.Fragment>
      {loading && <Loader />}
      <div className="page-content">
        <div className="d-flex px-5 mx-3 justify-content-between align-items-end mb-4">
          <h5 className="fw-bold text-uppercase">Diet Plan</h5>
          <div className="col-4">
            <select
              value={clientName}
              onChange={e => {
                setClientName(e.target.value)
              }}
              className="form-field"
            >
              <option onClick={() => navigate(`/add-diet-plan`)} value="">
                Select Client
              </option>
              {allClientData?.map(client => {
                return (
                  <option
                    key={client.id}
                    onClick={() => navigate(`/add-diet-plan/${client.id}`)}
                    value={client.fullname}
                  >
                    {" "}
                    {client.fullname}{" "}
                  </option>
                )
              })}
            </select>
          </div>
        </div>
        <Container fluid={true}>
          {/* Render Breadcrumb */}
          {/* <Breadcrumbs title="Dashboard" breadcrumbItem="Diet Plan" /> */}
          <Row>
            <Col className="col-12 px-0">
              <Row>
                <Col className="col-lg-12">
                  <div className="bg-white p-4">
                    <FullCalendar
                      plugins={[
                        BootstrapTheme,
                        dayGridPlugin,
                        interactionPlugin,
                      ]}
                      slotDuration={"00:15:00"}
                      handleWindowResize={true}
                      themeSystem="bootstrap"
                      headerToolbar={{
                        left: "prev,next today",
                        center: "title",
                        right: "dayGridMonth,dayGridWeek,dayGridDay",
                      }}
                      events={dietEvents}
                      editable={true}
                      droppable={true}
                      selectable={true}
                      dateClick={handleDateClick}
                      eventClick={handleEventClick}
                    />
                  </div>

                  {/* New/Edit event modal */}
                  <Modal isOpen={modal} className={"modal-fullscreen"} centered>
                    {/* <ModalHeader
                                            toggle={toggle}
                                            tag="h4"
                                            className=""
                                        // style={{backgroundColor: '#fad1fa'}}
                                        > */}

                    <div className="py-3 px-4 border-bottom-0 bg-light-purple d-flex justify-content-between align-items-center w-100 ">
                      <div className="d-flex align-items-center col-6 ">
                        <h4 className="fw-bold text-purple mb-0">
                          {" "}
                          {isEdit ? "Edit" : "Add"} Diet Plan{" "}
                        </h4>
                        <div className="ms-4">
                          <select
                            value={prevPlan}
                            onChange={e => setPrevPlan(e.target.value)}
                            className="form-field form-select py-2"
                            style={{ width: "200px" }}
                          >
                            <option value="Copy form">Select Date</option>
                            {activePlans?.map(plan => (
                              <option key={plan.date} value={plan.date}>
                                {moment(plan.date).format("DD-MM-YYYY") +
                                  " | " +
                                  plan.day}
                              </option>
                            ))}
                          </select>
                        </div>

                        <button
                          onClick={fetchDietPlan}
                          className="btn-success btn-green border-radius btn ms-3"
                        >
                          {loading2 ? "Getting Details..." : "Copy form"}
                        </button>
                      </div>

                      {/* {!!isEdit ? "Edit Event" : "Add Diet Plan"} */}
                      <div className="text-end ms-5 ps-5">
                        {isEdit ? (
                          <button
                            type="submit"
                            className="btn btn-green border-radius px-3 bg-light-yellow me-3"
                            id="btn-save-event"
                            onClick={handleEditDietPlan}
                          >
                            <i className="fas fa-save me-2 "></i>
                            Save Changes
                          </button>
                        ) : (
                          <button
                            type="submit"
                            className="btn btn-green border-radius px-3 bg-light-yellow me-3"
                            id="btn-save-event"
                            onClick={handleSaveDietPlan}
                          >
                            <i className="fas fa-save me-2 "></i>
                            Save
                          </button>
                        )}

                        {isEdit && (
                          <button
                            type="button"
                            onClick={onDeletePlan}
                            className="btn btn-red btn-green border-radius px-3 me-3"
                          >
                            <i className="fas fa-trash me-2 "></i>
                            Delete This Plan
                          </button>
                        )}

                        <button
                          type="button"
                          className="btn btn-dark btn-green border-radius px-3"
                          onClick={toggle}
                        >
                          <i className="fas fa-times me-2 "></i>
                          Close
                        </button>
                      </div>
                    </div>

                    {/* </ModalHeader> */}
                    <ModalBody className="p-4 pt-4">
                      <Form onSubmit={e => e.preventDefault()}>
                        {/* --------------- */}
                        <Row className="">
                          <div className="col-md-6 position-sticky top-0 h-100">
                            {/* Accordian Element */}
                            <div className="accordion" id="accordionExample">
                              <div className="accordion-item mb-4 rounded-0">
                                <h2
                                  className="accordion-header"
                                  id="headingOne"
                                >
                                  <button
                                    className="accordion-button text-purple fw-bold fs-5"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#collapseOne"
                                    aria-expanded="true"
                                    aria-controls="collapseOne"
                                  >
                                    Morning Diet Plan
                                  </button>
                                </h2>
                                <div
                                  id="collapseOne"
                                  className="accordion-collapse collapse show"
                                  aria-labelledby="headingOne"
                                  data-bs-parent="#accordionExample"
                                >
                                  <div className="accordion-body">
                                    <div>
                                      <Col className="">
                                        <div className="mb-3">
                                          <div className="form-label d-flex justify-content-between align-items-center">
                                            On waking up
                                            <button
                                              className="btn bg-success text-success bg-soft btn-sm"
                                              onClick={() =>
                                                onAddRow(
                                                  wakingRows,
                                                  setWakingRows,
                                                  setWakingUpFoods
                                                )
                                              }
                                            >
                                              <i className="fas fa-plus me-1"></i>{" "}
                                              Add
                                            </button>
                                          </div>
                                          {(wakingRows || []).map(
                                            (row, key) => (
                                              <div
                                                key={row.id}
                                                className=" position-relative"
                                              >
                                                <div className="row">
                                                  <div className="col-3 pe-4">
                                                    <input
                                                      type="time"
                                                      value={
                                                        wakingUpFoods[key].time
                                                      }
                                                      onChange={e =>
                                                        handleChangeFoodData(
                                                          key,
                                                          setWakingUpFoods,
                                                          e.target.value,
                                                          "time"
                                                        )
                                                      }
                                                      className="form-field px-1 text-center"
                                                    />
                                                  </div>
                                                  <div className="col-9 ps-md-0">
                                                    <Input
                                                      name="title"
                                                      type="text"
                                                      className="form-field mb-2 pe-5"
                                                      value={
                                                        wakingUpFoods[key].desc
                                                      }
                                                      onChange={e =>
                                                        handleChangeFoodData(
                                                          key,
                                                          setWakingUpFoods,
                                                          e.target.value,
                                                          "desc"
                                                        )
                                                      }
                                                      placeholder="Enter On waking up "
                                                    />

                                                    {key !== 0 && (
                                                      <button
                                                        className="btn bg-danger bg-soft text-danger rounded-circle me-3 btn-sm row-delete-btn"
                                                        onClick={() =>
                                                          onDeleteRow(
                                                            row.id,
                                                            wakingRows,
                                                            setWakingRows,
                                                            setWakingUpFoods,
                                                            key
                                                          )
                                                        }
                                                      >
                                                        <i className="fas fa-trash"></i>
                                                      </button>
                                                    )}
                                                  </div>
                                                </div>
                                              </div>
                                            )
                                          )}
                                        </div>
                                      </Col>
                                      <Col className="">
                                        <div className="mb-3">
                                          <div className="form-label d-flex justify-content-between align-items-center">
                                            Breakfast
                                            <button
                                              className="btn bg-success text-success bg-soft btn-sm"
                                              onClick={() =>
                                                onAddRow(
                                                  breakfastRows,
                                                  setBreakfastRows,
                                                  setBreakfastFoods
                                                )
                                              }
                                            >
                                              <i className="fas fa-plus me-1"></i>{" "}
                                              Add
                                            </button>
                                          </div>
                                          {(breakfastRows || []).map(
                                            (row, key) => (
                                              <div
                                                key={row.id}
                                                className=" position-relative"
                                              >
                                                <div className="row">
                                                  <div className="col-3 pe-4">
                                                    <input
                                                      type="time"
                                                      value={
                                                        breakfastFoods[key].time
                                                      }
                                                      onChange={e =>
                                                        handleChangeFoodData(
                                                          key,
                                                          setBreakfastFoods,
                                                          e.target.value,
                                                          "time"
                                                        )
                                                      }
                                                      className="form-field px-1 text-center"
                                                    />
                                                  </div>
                                                  <div className="col-9 ps-md-0">
                                                    <Input
                                                      name="title"
                                                      type="text"
                                                      value={
                                                        breakfastFoods[key].desc
                                                      }
                                                      onChange={e =>
                                                        handleChangeFoodData(
                                                          key,
                                                          setBreakfastFoods,
                                                          e.target.value,
                                                          "desc"
                                                        )
                                                      }
                                                      className="form-field mb-2 pe-5"
                                                      placeholder="Enter Breakfast"
                                                    />

                                                    {key !== 0 && (
                                                      <button
                                                        className="btn bg-danger bg-soft text-danger rounded-circle btn-sm me-3 row-delete-btn"
                                                        onClick={() =>
                                                          onDeleteRow(
                                                            row.id,
                                                            breakfastRows,
                                                            setBreakfastRows,
                                                            setBreakfastFoods,
                                                            key
                                                          )
                                                        }
                                                      >
                                                        <i className="fas fa-trash"></i>
                                                      </button>
                                                    )}
                                                  </div>
                                                </div>
                                              </div>
                                            )
                                          )}
                                        </div>
                                      </Col>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div className="accordion-item border-top border-2 mb-4">
                                <h2
                                  className="accordion-header"
                                  id="headingTwo"
                                >
                                  <button
                                    className="accordion-button text-purple fw-bold fs-5 collapsed"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#collapseTwo"
                                    aria-expanded="false"
                                    aria-controls="collapseTwo"
                                  >
                                    Afternoon Diet Plan
                                  </button>
                                </h2>
                                <div
                                  id="collapseTwo"
                                  className="accordion-collapse collapse"
                                  aria-labelledby="headingTwo"
                                  data-bs-parent="#accordionExample"
                                >
                                  <div className="accordion-body">
                                    <div>
                                      <Col className="">
                                        <div className="mb-3">
                                          <div className="form-label d-flex justify-content-between align-items-center">
                                            Mid meal
                                            <button
                                              className="btn bg-success text-success bg-soft btn-sm"
                                              onClick={() =>
                                                onAddRow(
                                                  mealRows,
                                                  setMealRows,
                                                  setMidMealFoods
                                                )
                                              }
                                            >
                                              <i className="fas fa-plus me-1"></i>{" "}
                                              Add
                                            </button>
                                          </div>
                                          {(mealRows || []).map((row, key) => (
                                            <div
                                              key={row.id}
                                              className=" position-relative"
                                            >
                                              <div className="row">
                                                <div className="col-3 pe-4">
                                                  <input
                                                    type="time"
                                                    value={
                                                      midMealFoods[key].time
                                                    }
                                                    onChange={e =>
                                                      handleChangeFoodData(
                                                        key,
                                                        setMidMealFoods,
                                                        e.target.value,
                                                        "time"
                                                      )
                                                    }
                                                    className="form-field px-1 text-center"
                                                  />
                                                </div>
                                                <div className="col-9 ps-md-0">
                                                  <Input
                                                    name="title"
                                                    type="text"
                                                    value={
                                                      midMealFoods[key].desc
                                                    }
                                                    onChange={e =>
                                                      handleChangeFoodData(
                                                        key,
                                                        setMidMealFoods,
                                                        e.target.value,
                                                        "desc"
                                                      )
                                                    }
                                                    className="form-field mb-2 pe-5"
                                                    placeholder="Enter Mid meal "
                                                  />

                                                  {key !== 0 && (
                                                    <button
                                                      className="btn bg-danger bg-soft text-danger rounded-circle btn-sm me-3 row-delete-btn"
                                                      onClick={() =>
                                                        onDeleteRow(
                                                          row.id,
                                                          mealRows,
                                                          setMealRows,
                                                          setMidMealFoods,
                                                          key
                                                        )
                                                      }
                                                    >
                                                      <i className="fas fa-trash"></i>
                                                    </button>
                                                  )}
                                                </div>
                                              </div>
                                            </div>
                                          ))}
                                        </div>
                                      </Col>
                                      <Col className="">
                                        <div className="mb-3">
                                          <div className="form-label d-flex justify-content-between align-items-center">
                                            Lunch
                                            <button
                                              className="btn bg-success text-success bg-soft btn-sm"
                                              onClick={() =>
                                                onAddRow(
                                                  lunchRows,
                                                  setLunchRows,
                                                  setLunchFoods
                                                )
                                              }
                                            >
                                              <i className="fas fa-plus me-1"></i>{" "}
                                              Add
                                            </button>
                                          </div>
                                          {(lunchRows || []).map((row, key) => (
                                            <div
                                              key={row.id}
                                              className="position-relative"
                                            >
                                              <div className="row">
                                                <div className="col-3 pe-4">
                                                  <input
                                                    type="time"
                                                    value={lunchFoods[key].time}
                                                    onChange={e =>
                                                      handleChangeFoodData(
                                                        key,
                                                        setLunchFoods,
                                                        e.target.value,
                                                        "time"
                                                      )
                                                    }
                                                    className="form-field px-1 text-center"
                                                  />
                                                </div>

                                                <div className="col-9 ps-md-0">
                                                  <Input
                                                    name="title"
                                                    type="text"
                                                    value={lunchFoods[key].desc}
                                                    onChange={e =>
                                                      handleChangeFoodData(
                                                        key,
                                                        setLunchFoods,
                                                        e.target.value,
                                                        "desc"
                                                      )
                                                    }
                                                    className="form-field mb-2 pe-5"
                                                    placeholder="Enter Lunch"
                                                  />

                                                  {key !== 0 && (
                                                    <button
                                                      className="btn bg-danger bg-soft text-danger rounded-circle btn-sm me-3 row-delete-btn"
                                                      onClick={() =>
                                                        onDeleteRow(
                                                          row.id,
                                                          lunchRows,
                                                          setLunchRows,
                                                          setLunchFoods,
                                                          key
                                                        )
                                                      }
                                                    >
                                                      <i className="fas fa-trash"></i>
                                                    </button>
                                                  )}
                                                </div>
                                              </div>
                                            </div>
                                          ))}
                                        </div>
                                      </Col>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div className="accordion-item border-top border-2 rounded-0">
                                <h2
                                  className="accordion-header"
                                  id="headingThree"
                                >
                                  <button
                                    className="accordion-button text-purple fw-bold fs-5 collapsed"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#collapseThree"
                                    aria-expanded="false"
                                    aria-controls="collapseThree"
                                  >
                                    Evening Diet Plan
                                  </button>
                                </h2>
                                <div
                                  id="collapseThree"
                                  className="accordion-collapse collapse"
                                  aria-labelledby="headingThree"
                                  data-bs-parent="#accordionExample"
                                >
                                  <div className="accordion-body">
                                    <div>
                                      <Col className="">
                                        <div className="mb-3">
                                          <div className="form-label d-flex justify-content-between align-items-center">
                                            Evening Snack
                                            <button
                                              className="btn bg-success text-success bg-soft btn-sm"
                                              onClick={() =>
                                                onAddRow(
                                                  snackRows,
                                                  setSnackRows,
                                                  setEveningSnackFoods
                                                )
                                              }
                                            >
                                              <i className="fas fa-plus me-1"></i>{" "}
                                              Add
                                            </button>
                                          </div>
                                          {(snackRows || []).map((row, key) => (
                                            <div
                                              key={row.id}
                                              className=" position-relative"
                                            >
                                              <div className="row">
                                                <div className="col-3 pe-4">
                                                  <input
                                                    type="time"
                                                    value={
                                                      eveningSnackFoods[key]
                                                        .time
                                                    }
                                                    onChange={e =>
                                                      handleChangeFoodData(
                                                        key,
                                                        setEveningSnackFoods,
                                                        e.target.value,
                                                        "time"
                                                      )
                                                    }
                                                    className="form-field px-1 text-center"
                                                  />
                                                </div>

                                                <div className="col-9 ps-md-0">
                                                  <Input
                                                    name="title"
                                                    type="text"
                                                    value={
                                                      eveningSnackFoods[key]
                                                        .desc
                                                    }
                                                    onChange={e =>
                                                      handleChangeFoodData(
                                                        key,
                                                        setEveningSnackFoods,
                                                        e.target.value,
                                                        "desc"
                                                      )
                                                    }
                                                    className="form-field mb-2 pe-5"
                                                    placeholder="Enter Evening Snack"
                                                  />

                                                  {key !== 0 && (
                                                    <button
                                                      className="btn bg-danger bg-soft text-danger rounded-circle btn-sm me-3 row-delete-btn"
                                                      onClick={() =>
                                                        onDeleteRow(
                                                          row.id,
                                                          snackRows,
                                                          setSnackRows,
                                                          setEveningSnackFoods,
                                                          key
                                                        )
                                                      }
                                                    >
                                                      <i className="fas fa-trash"></i>
                                                    </button>
                                                  )}
                                                </div>
                                              </div>
                                            </div>
                                          ))}
                                        </div>
                                      </Col>

                                      <Col className="">
                                        <div className="mb-3">
                                          <div className="form-label d-flex justify-content-between align-items-center">
                                            Dinner
                                            <button
                                              className="btn bg-success text-success bg-soft btn-sm"
                                              onClick={() =>
                                                onAddRow(
                                                  DinnerRows,
                                                  setDinnerRows,
                                                  setDinnerFoods
                                                )
                                              }
                                            >
                                              <i className="fas fa-plus me-1"></i>{" "}
                                              Add
                                            </button>
                                          </div>
                                          {(DinnerRows || []).map(
                                            (row, key) => (
                                              <div
                                                key={row.id}
                                                className="position-relative"
                                              >
                                                <div className="row">
                                                  <div className="col-3 pe-4">
                                                    <input
                                                      type="time"
                                                      value={
                                                        dinnerFoods[key].time
                                                      }
                                                      onChange={e =>
                                                        handleChangeFoodData(
                                                          key,
                                                          setDinnerFoods,
                                                          e.target.value,
                                                          "time"
                                                        )
                                                      }
                                                      className="form-field px-1 text-center"
                                                    />
                                                  </div>

                                                  <div className="col-9 ps-md-0">
                                                    <Input
                                                      name="title"
                                                      type="text"
                                                      value={
                                                        dinnerFoods[key].desc
                                                      }
                                                      onChange={e =>
                                                        handleChangeFoodData(
                                                          key,
                                                          setDinnerFoods,
                                                          e.target.value,
                                                          "desc"
                                                        )
                                                      }
                                                      className="form-field mb-2 pe-5"
                                                      placeholder="Enter Dinner"
                                                    />

                                                    {key !== 0 && (
                                                      <button
                                                        className="btn bg-danger bg-soft text-danger rounded-circle btn-sm me-3 row-delete-btn"
                                                        onClick={() =>
                                                          onDeleteRow(
                                                            row.id,
                                                            DinnerRows,
                                                            setDinnerRows,
                                                            setDinnerFoods,
                                                            key
                                                          )
                                                        }
                                                      >
                                                        <i className="fas fa-trash"></i>
                                                      </button>
                                                    )}
                                                  </div>
                                                </div>
                                              </div>
                                            )
                                          )}
                                        </div>
                                      </Col>

                                      <Col className="">
                                        <div className="mb-3">
                                          <div className="form-label d-flex justify-content-between align-items-center">
                                            Post Dinner
                                            <button
                                              className="btn bg-success text-success bg-soft btn-sm"
                                              onClick={() =>
                                                onAddRow(
                                                  postDinnerRows,
                                                  setPostDinnerRows,
                                                  setPostDinnerFoods
                                                )
                                              }
                                            >
                                              <i className="fas fa-plus me-1"></i>{" "}
                                              Add
                                            </button>
                                          </div>
                                          {(postDinnerRows || []).map(
                                            (row, key) => (
                                              <div
                                                key={row.id}
                                                className="position-relative"
                                              >
                                                <div className="row">
                                                  <div className="col-3 pe-4">
                                                    <input
                                                      type="time"
                                                      value={
                                                        postDinnerFoods[key]
                                                          .time
                                                      }
                                                      onChange={e =>
                                                        handleChangeFoodData(
                                                          key,
                                                          setPostDinnerFoods,
                                                          e.target.value,
                                                          "time"
                                                        )
                                                      }
                                                      className="form-field px-1 text-center"
                                                    />
                                                  </div>

                                                  <div className="col-9 ps-md-0">
                                                    <Input
                                                      name="title"
                                                      type="text"
                                                      value={
                                                        postDinnerFoods[key]
                                                          .desc
                                                      }
                                                      onChange={e =>
                                                        handleChangeFoodData(
                                                          key,
                                                          setPostDinnerFoods,
                                                          e.target.value,
                                                          "desc"
                                                        )
                                                      }
                                                      className="form-field mb-2 pe-5"
                                                      placeholder="Enter Post Dinner"
                                                    />
                                                    {key !== 0 && (
                                                      <button
                                                        className="btn bg-danger bg-soft text-danger rounded-circle btn-sm me-3 row-delete-btn"
                                                        onClick={() =>
                                                          onDeleteRow(
                                                            row.id,
                                                            postDinnerRows,
                                                            setPostDinnerRows,
                                                            setPostDinnerFoods,
                                                            key
                                                          )
                                                        }
                                                      >
                                                        <i className="fas fa-trash"></i>
                                                      </button>
                                                    )}
                                                  </div>
                                                </div>
                                              </div>
                                            )
                                          )}
                                        </div>
                                      </Col>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            {/* ========================================================== */}
                            <Col className="col-12 mt-4">
                              <div className="mb-3">
                                <Label className="form-label">Note</Label>
                                <textarea
                                  name="title"
                                  type="text"
                                  rows={5}
                                  className="form-field"
                                  value={planNote}
                                  onChange={e => setPlanNote(e.target.value)}
                                  placeholder="Enter Note"
                                />
                              </div>
                            </Col>
                          </div>

                          <div className="col-md-6 ps-md-5">
                            <div>
                              <h4 className="border-bottom text-purple border-secondary pb-2 mb-4 border-2">
                                Morning Diet Plan
                              </h4>
                              <Row>
                                <div className="col-12">
                                  <h5 className="">On waking up</h5>

                                  <Table bordered>
                                    <thead className="bg-light bg-soft">
                                      <tr>
                                        <th className="col-2 text-center">
                                          Time
                                        </th>
                                        <th className="col-10">Description</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {wakingUpFoods[0].desc.length > 0 &&
                                        wakingUpFoods.map((food, i) => {
                                          return (
                                            <tr key={i}>
                                              <td className="text-center">
                                                {food.time}
                                              </td>
                                              <td>{food.desc}</td>
                                            </tr>
                                          )
                                        })}
                                    </tbody>
                                  </Table>
                                </div>
                                <div className="col-12">
                                  <h5 className="">Breakfast</h5>
                                  <Table bordered>
                                    <thead className="bg-light bg-soft">
                                      <tr>
                                        <th className="col-2 text-center">
                                          Time
                                        </th>
                                        <th className="col-10">Description</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {breakfastFoods[0].desc.length > 0 &&
                                        breakfastFoods.map((food, i) => {
                                          return (
                                            <tr key={i}>
                                              <td className="text-center">
                                                {food.time}
                                              </td>
                                              <td>{food.desc}</td>
                                            </tr>
                                          )
                                        })}
                                    </tbody>
                                  </Table>
                                </div>
                              </Row>
                            </div>

                            <div className="mt-4">
                              <h4 className="border-bottom text-purple border-secondary pb-2 mb-4 border-2">
                                Afternoon Diet Plan
                              </h4>
                              <Row>
                                <div className="col-12">
                                  <h5 className="">Mid meal</h5>

                                  <Table bordered>
                                    <thead className="bg-light bg-soft">
                                      <tr>
                                        <th className="col-2 text-center">
                                          Time
                                        </th>
                                        <th className="col-10">Description</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {midMealFoods[0].desc.length > 0 &&
                                        midMealFoods.map((food, i) => {
                                          return (
                                            <tr key={i}>
                                              <td className="text-center">
                                                {food.time}
                                              </td>
                                              <td>{food.desc}</td>
                                            </tr>
                                          )
                                        })}
                                    </tbody>
                                  </Table>
                                </div>
                                <div className="col-12">
                                  <h5 className="">Lunch</h5>
                                  <Table bordered>
                                    <thead className="bg-light bg-soft">
                                      <tr>
                                        <th className="col-2 text-center">
                                          Time
                                        </th>
                                        <th className="col-10">Description</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {lunchFoods[0].desc.length > 0 &&
                                        lunchFoods.map((food, i) => {
                                          return (
                                            <tr key={i}>
                                              <td className="text-center">
                                                {food.time}
                                              </td>
                                              <td>{food.desc}</td>
                                            </tr>
                                          )
                                        })}
                                    </tbody>
                                  </Table>
                                </div>
                              </Row>
                            </div>

                            <div className="mt-4">
                              <h4 className="border-bottom text-purple border-secondary pb-2 mb-4 border-2">
                                Evening Diet Plan
                              </h4>
                              <Row>
                                <div className="col-12">
                                  <h5 className="">Evening Snack</h5>

                                  <Table bordered>
                                    <thead className="bg-light bg-soft">
                                      <tr>
                                        <th className="col-2 text-center">
                                          Time
                                        </th>
                                        <th className="col-10">Description</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {eveningSnackFoods[0].desc.length > 0 &&
                                        eveningSnackFoods.map((food, i) => {
                                          return (
                                            <tr key={i}>
                                              <td className="text-center">
                                                {food.time}
                                              </td>
                                              <td>{food.desc}</td>
                                            </tr>
                                          )
                                        })}
                                    </tbody>
                                  </Table>
                                </div>
                                <div className="col-12">
                                  <h5 className="">Dinner</h5>
                                  <Table bordered>
                                    <thead className="bg-light bg-soft">
                                      <tr>
                                        <th className="col-2 text-center">
                                          Time
                                        </th>
                                        <th className="col-10">Description</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {dinnerFoods[0].desc.length > 0 &&
                                        dinnerFoods.map((food, i) => {
                                          return (
                                            <tr key={i}>
                                              <td className="text-center">
                                                {food.time}
                                              </td>
                                              <td>{food.desc}</td>
                                            </tr>
                                          )
                                        })}
                                    </tbody>
                                  </Table>
                                </div>
                                <div className="col-12">
                                  <h5 className="">Post Dinner</h5>
                                  <Table bordered>
                                    <thead className="bg-light bg-soft">
                                      <tr>
                                        <th className="col-2 text-center">
                                          Time
                                        </th>
                                        <th className="col-10">Description</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {postDinnerFoods[0].desc.length > 0 &&
                                        postDinnerFoods.map((food, i) => {
                                          return (
                                            <tr key={i}>
                                              <td className="text-center">
                                                {food.time}
                                              </td>
                                              <td>{food.desc}</td>
                                            </tr>
                                          )
                                        })}
                                    </tbody>
                                  </Table>
                                </div>
                              </Row>
                            </div>
                          </div>
                        </Row>
                      </Form>
                    </ModalBody>
                  </Modal>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

AddDietPlan.propTypes = {
  events: PropTypes.array,
  categories: PropTypes.array,
  className: PropTypes.string,
  onGetEvents: PropTypes.func,
  onAddNewEvent: PropTypes.func,
  onUpdateEvent: PropTypes.func,
  onDeleteEvent: PropTypes.func,
  onGetCategories: PropTypes.func,
}

export default AddDietPlan
