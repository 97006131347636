import React, { useState, useEffect } from "react"
import { Col, Container, Row } from "reactstrap"
//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { Card } from "reactstrap"

import { Link, useNavigate, useParams } from "react-router-dom"
import { CardBody } from "reactstrap"
import axios from "axios"
import swal from "sweetalert"
import Loader from "pages/Loader"
import { getEncryptedData, getDecryptedData } from "pages/Config"

const EditDietician = () => {
  const [fname, setFname] = useState("")
  const [lname, setLname] = useState("")
  const [height, setHeight] = useState("")
  const [weight, setWeight] = useState("")
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const [phone, setPhone] = useState("")
  const [age, setAge] = useState("")
  const [gender, setGender] = useState("")
  const [additional_detail, setAdditional_detail] = useState("")
  const [packageName, setPackageName] = useState("")
  const [betaUser, setBetaUser] = useState("")

  const [loading, setLoading] = useState(false)

  const [fixedContent, setFixedContent] = useState(false)

  document.addEventListener("scroll", () => {
    if (window.scrollY > 150) {
      setFixedContent(true)
    } else {
      setFixedContent(false)
    }
  })

  const navigate = useNavigate()

  const { diet_id } = useParams()

  // https:///nutriconnect-pro/backend/iapi/
  // https://api.nutriconnectpro.com/aapi/

  useEffect(() => {
    if (localStorage.getItem("adminUser") && localStorage.getItem("adminId")) {
      navigate("/admin-dashboard")
    }

    const getDieticianDetails = async () => {
      try {
        const formData = new FormData()
        formData.append("dietician_id", diet_id)

        setLoading(true)

        const response = await axios.post(
          "https://api.nutriconnectpro.com/api/view_dietician",
          formData,
          {
            headers: {
              Accept: "application/json",
              "Content-Type": "multipart/form-data",
            },
          }
        )

        // console.log(response)
        if (response.data.message == "success") {
          setFname(getDecryptedData(response.data.data[0].firstname))
          setLname(getDecryptedData(response.data.data[0].lastname))
          setHeight(getDecryptedData(response.data.data[0].height))
          setWeight(getDecryptedData(response.data.data[0].weight))
          setEmail(response.data.data[0].email)
          setPassword(response.data.data[0].password)
          setPhone(getDecryptedData(response.data.data[0].phone))
          setAge(getDecryptedData(response.data.data[0].age))
          setGender(getDecryptedData(response.data.data[0].gender))
          setAdditional_detail(
            getDecryptedData(response.data.data[0].additional_detail)
          )
          setPackageName(getDecryptedData(response.data.data[0].package))
        }
        setLoading(false)
      } catch (error) {
        console.log(error)
        setLoading(false)
      }
    }

    getDieticianDetails()
  }, [])

  //meta title
  document.title = "Edit Dieticians | Nutri Connect Pro "

  const handleEditDietician = async e => {
    e.preventDefault()
    setLoading(true)
    try {
      const formData = new FormData()

      formData.append("firstname", getEncryptedData(fname))
      formData.append("lastname", getEncryptedData(lname))
      formData.append("height", getEncryptedData(height))
      formData.append("weight", getEncryptedData(weight))
      formData.append("email", email)
      formData.append("phone", getEncryptedData(phone))
      formData.append("age", getEncryptedData(age))
      formData.append("gender", getEncryptedData(gender))
      formData.append("password", password)
      formData.append("package", getEncryptedData(packageName))
      formData.append("additional_detail", getEncryptedData(additional_detail))
      formData.append("dietician_id", diet_id)

      const response = await axios.post(
        "https://api.nutriconnectpro.com/api/edit_dietician",
        formData,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "multipart/form-data",
          },
        }
      )

      // console.log(response)

      setLoading(false)

      swal("Dietician Updated successfully", {
        icon: "success",
      }).then(() => {
        navigate("/view-dietician")
      })
    } catch (error) {
      console.log(error)
      setLoading(false)
      swal("Something went wrong", "", "warning")
    }
  }

  return (
    <React.Fragment>
      {loading && <Loader />}

      {/* fixed buttons */}
      <div
        className={`flex-column fixed-buttons ${
          fixedContent ? "d-flex" : "d-none"
        }`}
      >
        <button className="btn btn-yellow border-radius fix-btn">
          <i className="bx bx-send fs-5 me-2"></i>
          <span>Submit</span>
        </button>
        <button
          onClick={() => navigate(-1)}
          className="btn btn-dark border-radius fix-btn"
        >
          <i className="fas fa-arrow-left me-2 icon-center"></i>
          <span>Back</span>
        </button>
      </div>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title={"Dashboard"} breadcrumbItem={"Add Dieticians "} />

          <Row>
            <Card>
              <CardBody>
                <form onSubmit={handleEditDietician} className="py-2">
                  <Row>
                    <div className="col-12 mb-4 text-end">
                      <button
                        type="button"
                        onClick={() => navigate(-1)}
                        className="btn btn-dark border-radius me-3"
                      >
                        <i className="fas fa-arrow-left me-3"></i>
                        Back
                      </button>
                      <button
                        type="submit"
                        className="btn btn-green btn-yellow border-radius"
                      >
                        {" "}
                        <i className="bx bx-send me-1"></i> Submit{" "}
                      </button>
                    </div>
                    <div className="col-md-4">
                      <label htmlFor="" className="fs-5">
                        Enter Name <span className="text-danger">*</span>
                      </label>
                      <Row>
                        <div className="col-6">
                          <input
                            type="text"
                            value={fname}
                            onChange={e => setFname(e.target.value)}
                            placeholder="First Name"
                            className="form-field"
                            required
                          />
                        </div>
                        <div className="col-6">
                          <input
                            type="text"
                            value={lname}
                            onChange={e => setLname(e.target.value)}
                            placeholder="Last Name"
                            className="form-field"
                          />
                        </div>
                      </Row>
                    </div>

                    <div className="col-md-4 ">
                      <label htmlFor="" className="fs-5">
                        Height
                      </label>
                      <input
                        type="text"
                        value={height}
                        onChange={e => setHeight(e.target.value)}
                        placeholder="Enter Your Height"
                        className="form-field"
                      />
                    </div>
                    <div className="col-md-4 ">
                      <label htmlFor="" className="fs-5">
                        Weight
                      </label>
                      <input
                        type="text"
                        value={weight}
                        onChange={e => setWeight(e.target.value)}
                        placeholder="Enter Your Weight"
                        className="form-field"
                      />
                    </div>

                    <div className="col-md-4 mt-4">
                      <Row>
                        <div className="col-md-6">
                          <label htmlFor="" className="fs-5">
                            Age <span className="text-danger">*</span>
                          </label>
                          <input
                            type="text"
                            value={age}
                            onChange={e => setAge(e.target.value)}
                            placeholder="Enter Age"
                            className="form-field"
                            required
                          />
                        </div>
                        <div className="col-md-6">
                          <label htmlFor="" className="fs-5">
                            Gender <span className="text-danger">*</span>
                          </label>
                          <Row className="mt-2 pt-1">
                            <div className="col-6">
                              <input
                                type="radio"
                                onClick={e => setGender(e.target.id)}
                                name="gender"
                                checked={gender == "male"}
                                id="male"
                                className="fs-5 form-check-input "
                              />
                              <label htmlFor="male" className="ps-2 pt-1">
                                Male
                              </label>
                            </div>

                            <div className="col-6">
                              <input
                                type="radio"
                                onClick={e => setGender(e.target.id)}
                                name="gender"
                                checked={gender == "female"}
                                id="female"
                                className="form-check-input fs-5 "
                              />
                              <label htmlFor="female" className="ps-2 pt-1">
                                Female
                              </label>
                            </div>
                          </Row>
                        </div>
                      </Row>
                    </div>

                    <div className="col-md-4 mt-4">
                      <label htmlFor="" className="fs-5">
                        Email Address <span className="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        value={email}
                        onChange={e => setEmail(e.target.value)}
                        placeholder="Enter Email Address"
                        className="form-field"
                        required
                      />
                    </div>

                    <div className="col-md-4 mt-4">
                      <label htmlFor="" className="fs-5">
                        Password{" "}
                        <span className="text-danger">
                          (only if you need to reset)
                        </span>
                      </label>
                      <input
                        type="text"
                        value={password}
                        onChange={e => setPassword(e.target.value)}
                        placeholder="Enter Password"
                        className="form-field"
                      />
                    </div>

                    <div className="col-md-4 mt-4">
                      <label htmlFor="" className="fs-5">
                        Phone Number <span className="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        value={phone}
                        onChange={e => setPhone(e.target.value)}
                        placeholder="Enter Phone Number"
                        className="form-field"
                        required
                      />
                    </div>

                    <div className="col-md-4 mt-4">
                      <label htmlFor="" className="fs-5">
                        Package <span className="text-danger">*</span>
                      </label>
                      <select
                        name=""
                        id=""
                        value={packageName}
                        onChange={e => setPackageName(e.target.value)}
                        className="form-field"
                      >
                        <option value="">Nothing Selected</option>
                        <option value="0">Trial Package (60 days)</option>
                      </select>
                    </div>

                    <div className="col-md-4 mt-4 align-items-end d-flex pb-3">
                      <input
                        type="checkbox"
                        name="gender"
                        id="beta"
                        className="fs-5 form-check-input"
                      />
                      <label
                        htmlFor="beta"
                        className="ps-3 fs-5 d-inline-block mb-0"
                      >
                        Beta User{" "}
                      </label>
                    </div>

                    {/* <div className="col-md-6 mt-4">
                                            <label htmlFor="" className="fs-5">Please select all of them below that describe you </label>
                                            <Row className="mt-2 pt-1">
                                                <div className="col-4">

                                                    <input type="checkbox" id="cb1" className="fs-5 form-check-input " />
                                                    <label htmlFor="cb1" className="ps-2 pt-1">I am Vegan</label>
                                                </div>

                                                <div className="col-4">
                                                    <input type="checkbox" id="cb2" className="form-check-input fs-5 " />
                                                    <label htmlFor="cb2" className="ps-2 pt-1">I am Vegetarian</label>
                                                </div>

                                                <div className="col-4">
                                                    <input type="checkbox" id="cb3" className="form-check-input fs-5 " />
                                                    <label htmlFor="cb3" className="ps-2 pt-1">I have food allergies</label>
                                                </div>
                                            </Row>
                                        </div> */}

                    <div className="col-12 mt-4">
                      <label htmlFor="" className="fs-5">
                        Please give additional detail about your diet here:{" "}
                      </label>
                      <textarea
                        type="text"
                        value={additional_detail}
                        onChange={e => setAdditional_detail(e.target.value)}
                        rows={4}
                        placeholder="Enter Here"
                        className="form-field"
                      />
                    </div>
                  </Row>
                </form>
              </CardBody>
            </Card>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default EditDietician
