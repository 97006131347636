import React, { useEffect, useState } from "react"
import { Link, useNavigate } from "react-router-dom"
import Dropzone from "react-dropzone"
import { Row, Form, Modal, Card, Col } from "reactstrap"
import Select from "react-select"

// editor
import { CKEditor } from "@ckeditor/ckeditor5-react"
import ClassicEditor from "@ckeditor/ckeditor5-build-classic"

// assets
import "../../../../assets/css/Notes.css"
import userImg from "../../../../assets/default-user.png"
import axios from "axios"
import moment from "moment/moment"
import { getEncryptedData, getDecryptedData } from "pages/Config"

const FollowUp = props => {
  const [fixButtons, setFixButtons] = useState(false)
  const [followUpSidebar, setfollowUpSidebar] = useState(false)
  const [editfollowUpSidebar, setEditfollowUpSidebar] = useState(false)

  // fields for add FollowUp
  const [addFollowUpDesc, setAddFollowUpDesc] = useState("")
  const [addDate, setAddDate] = useState("")

  // edit
  const [editFollowUpDesc, setEditFollowUpDesc] = useState("")
  const [editDate, setEditDate] = useState("")

  // for FollowUps view
  const [allFollowUp, setAllFollowUp] = useState([])
  const [loading, setLoading] = useState(false)

  const [followId, setFollowId] = useState("")

  const navigate = useNavigate()

  // edit follow up from dashboard
  useEffect(() => {
    if (
      props?.receivedData?.followup_id &&
      props?.receivedData?.noteId == "0"
    ) {
      setEditFollowUpDesc(props.receivedData.desc)
      setEditDate(props.receivedData.date)
      setFollowId(props.receivedData.followup_id)

      setEditfollowUpSidebar(true)
    }
  }, [props.receivedData])

  document.addEventListener("scroll", () => {
    if (window.scrollY > 160) {
      setFixButtons(true)
    } else {
      setFixButtons(false)
    }
  })

  // getting all follow ups
  useEffect(() => {
    const getAllFollowUps = async () => {
      setLoading(true)
      try {
        const formData = new FormData()
        formData.append("client_id", props.client_id)

        const response = await axios.post(
          "https://api.nutriconnectpro.com/api/followups",
          formData,
          {
            headers: {
              Accept: "application/json",
              "Content-Type": "multipart/form-data",
            },
          }
        )

        const originalData = response.data.data.map(followup => ({
          ...followup,
          description: getDecryptedData(followup.description),
        }))

        setAllFollowUp(originalData)

        setLoading(false)
      } catch (error) {
        console.log(error)
      }
    }
    if (props.verticalActiveTab == 4) {
      getAllFollowUps()
    }
  }, [props.verticalActiveTab])

  const onDeleteFollowUp = follow_id => {
    swal({
      title: "Are you sure?",
      text: "do you really want to remove this Follow Up",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then(willDelete => {
      if (willDelete) {
        const formData = new FormData()

        formData.append("followup_id", follow_id)
        formData.append("client_id", props.client_id)

        axios
          .post(
            "https://api.nutriconnectpro.com/api/delete_list_followup",
            formData,
            {
              headers: {
                Accept: "application/json",
                "Content-Type": "multipart/form-data",
              },
            }
          )
          .then(response => {
            const originalData = response.data.data.map(followup => ({
              ...followup,
              description: getDecryptedData(followup.description),
            }))

            setAllFollowUp(originalData)

            swal("FollowUp has been deleted successfully!", {
              icon: "success",
            })
          })
      }
    })
  }

  // add note
  const handleAddFollowUp = async () => {
    try {
      const formData = new FormData()
      formData.append("description", getEncryptedData(addFollowUpDesc))
      formData.append("date", addDate)
      formData.append("client_id", props.client_id)
      formData.append("added_by", localStorage.getItem("adminId"))

      const response = await axios.post(
        "https://api.nutriconnectpro.com/api/add_list_followup",
        formData,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "multipart/form-data",
          },
        }
      )

      const originalData = response.data.data.map(followup => ({
        ...followup,
        description: getDecryptedData(followup.description),
      }))

      setAllFollowUp(originalData)

      setfollowUpSidebar(false)

      swal("Followup added successfully!", {
        icon: "success",
      }).then(() => {
        setAddFollowUpDesc("<p></p>")
        setAddDate("")
      })
    } catch (error) {
      console.log(error)
    }
  }

  const handleEditFollowUp = async () => {
    try {
      const formData = new FormData()
      formData.append("description", getEncryptedData(editFollowUpDesc))
      formData.append("date", editDate)
      formData.append("client_id", props.client_id)
      formData.append("followup_id", followId)
      formData.append("added_by", localStorage.getItem("adminId"))

      const response = await axios.post(
        "https://api.nutriconnectpro.com/api/edit_list_followup",
        formData,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "multipart/form-data",
          },
        }
      )

      const originalData = response.data.data.map(followup => ({
        ...followup,
        description: getDecryptedData(followup.description),
      }))

      setAllFollowUp(originalData)

      swal("FollowUp Updated successfully!", {
        icon: "success",
      }).then(() => {
        setEditfollowUpSidebar(false)
      })
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <React.Fragment>
      <div className="mb-3">
        <div className="px-3 py-4 bg-white">
          <Form onSubmit={e => e.preventDefault()}>
            {/* Fixed Buttons */}
            <div
              className={`flex-column fixed-buttons ${
                fixButtons ? "d-flex" : "d-none"
              }`}
            >
              <a
                onClick={() => () => {
                  setAddDate(moment(new Date()).format("YYYY-MM-DD"))
                  setfollowUpSidebar(true)
                }}
                className="btn btn-yellow border-radius fix-btn"
              >
                <i className="fas fa-plus me-2"></i>
                <span>Follow Up</span>
              </a>
              <a
                onClick={() => navigate(-1)}
                className="btn btn-dark border-radius fix-btn"
              >
                <i className="fas fa-arrow-left me-2 icon-center"></i>
                <span>Back</span>
              </a>
            </div>

            <div className="col-12 mb-4 text-end">
              <button
                onClick={() => navigate(-1)}
                className="btn btn-dark border-radius me-3"
              >
                <i className="fas fa-arrow-left me-3"></i>
                Back
              </button>
              <button
                onClick={() => {
                  setAddDate(moment(new Date()).format("YYYY-MM-DD"))
                  setfollowUpSidebar(true)
                }}
                className="btn btn-green btn-yellow border-radius"
              >
                <i className="fas fa-plus me-1"></i> Add Follow Up{" "}
              </button>
            </div>

            {/* Add followup */}
            <div
              className={`notes-sidebar ${
                followUpSidebar ? "show-notes-sidebar" : ""
              }`}
            >
              <Row className="align-items-center justify-content-between">
                <div className="col-12 d-flex justify-content-between">
                  <h5 className="text-purple fw-bold">Add Follow Up</h5>
                  <div className="text-end">
                    <button
                      onClick={handleAddFollowUp}
                      className="btn btn-purple btn-green border-radius me-2"
                    >
                      <i className="fas fa-plus me-2"></i>Add Follow Up
                    </button>
                    <a
                      onClick={() => setfollowUpSidebar(false)}
                      className="btn btn-secondary text-uppercase btn-green border-radius"
                    >
                      <i className="fas fa-times fs-6 me-2 "></i>
                      Close
                    </a>
                  </div>
                </div>
                <div className="col-12">
                  <label htmlFor="">Description</label>
                  <CKEditor
                    editor={ClassicEditor}
                    data={addFollowUpDesc}
                    onReady={editor => {
                      // You can store the "editor" and use when it is needed.
                    }}
                    onChange={(event, editor) => {
                      const data = editor.getData()
                      setAddFollowUpDesc(data)
                    }}
                  />
                </div>
                <div className="col-md-4 mt-3 notes">
                  <label htmlFor="">Date</label>
                  <input
                    type="date"
                    value={addDate}
                    onChange={e => setAddDate(e.target.value)}
                    className="form-control border-radius"
                  />
                </div>
              </Row>
            </div>

            <div>
              {allFollowUp.length > 0 ? (
                <ul className="verti-timeline list-unstyled notes-box">
                  {/* Activities */}
                  {allFollowUp.map((followUp, key) => (
                    <li key={key} className="event-list position-relative">
                      <div className="event-timeline-dot">
                        <img src={userImg} className="userImg me-3 " alt="" />
                      </div>
                      <div className="d-flex">
                        <div className="flex-grow-1 ms-4">
                          <div className="note">
                            <div className=" d-flex justify-content-between py-2 rounded ps-4">
                              <div>
                                <p className="text-muted mb-2 mt-1">
                                  <h6 className="d-inline me-2 fw-bold text-purple">
                                    By{" "}
                                    {getDecryptedData(
                                      localStorage.getItem("adminUser")
                                    )}
                                  </h6>
                                  {moment(followUp.created_at).format(
                                    "DD-MM-YYYY | hh:mm:s"
                                  )}
                                </p>
                                <i className="bi bi-triangle-fill note-icon"></i>
                              </div>
                              <div className="me-4">
                                {followUp.is_done == 1 && (
                                  <span className="pe-3">
                                    completed on :{" "}
                                    {moment(followUp.updated_at).format(
                                      "DD-MM-YYYY"
                                    )}
                                  </span>
                                )}
                                {followUp.note_id != "0" && (
                                  <button className="btn btn-sm edit-delete-btn text-secondary me-2">
                                    <i
                                      className="fas fa-notes-medical "
                                      title="Added From Notes"
                                    ></i>
                                  </button>
                                )}
                                <button
                                  onClick={e => {
                                    e.preventDefault()

                                    setEditFollowUpDesc(followUp.description)
                                    setEditDate(followUp.date)
                                    setFollowId(followUp.id)

                                    setEditfollowUpSidebar(true)
                                  }}
                                  className="btn btn-sm edit-delete-btn text-success me-2"
                                >
                                  <i className="fas fa-edit" title="Edit"></i>
                                </button>
                                <button
                                  onClick={() => onDeleteFollowUp(followUp.id)}
                                  className="btn btn-sm edit-delete-btn text-danger"
                                >
                                  <i
                                    className="fas fa-trash-alt"
                                    title="Delete"
                                  ></i>
                                </button>
                              </div>
                            </div>
                            <div className="p-4 pt-0 pb-3">
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: followUp.description,
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </li>
                  ))}
                </ul>
              ) : (
                <h5 className="ms-4">
                  Follow Ups will be appear here once added{" "}
                </h5>
              )}
            </div>
          </Form>
          {/* Edit Notes Sidebar */}
          <div
            className={`notes-sidebar ${
              editfollowUpSidebar ? "show-notes-sidebar" : ""
            }`}
          >
            <Row className="align-items-center justify-content-between">
              <Form
                onSubmit={e => {
                  e.preventDefault()
                  handleEditFollowUp()
                }}
              >
                <div className="col-12 d-flex justify-content-between">
                  <h5 className="fw-bold text-purple">Edit Follow Up</h5>
                  <div className="text-end">
                    <button
                      type="submit"
                      className="btn btn-purple btn-green border-radius me-2"
                    >
                      <i className="fas fa-edit me-2"></i>Edit Follow Up
                    </button>
                    <a
                      onClick={() => setEditfollowUpSidebar(false)}
                      className="btn btn-secondary btn-green border-radius"
                    >
                      <i className="fas fa-times fs-6 me-2 "></i>
                      Close
                    </a>
                  </div>
                </div>
                <div className="col-12">
                  <label htmlFor="">Description</label>
                  <CKEditor
                    editor={ClassicEditor}
                    data={editFollowUpDesc}
                    onReady={editor => {
                      // You can store the "editor" and use when it is needed.
                    }}
                    onChange={(event, editor) => {
                      const data = editor.getData()

                      setEditFollowUpDesc(data)
                    }}
                  />
                </div>
                <div className="col-md-4 mt-3 notes">
                  <label htmlFor="">Date</label>
                  <input
                    type="date"
                    value={editDate}
                    onChange={e => setEditDate(e.target.value)}
                    className="form-control border-radius"
                  />
                </div>
              </Form>
            </Row>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

export default FollowUp
