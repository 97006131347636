import React, { useState, useEffect } from "react"
import { Col, Container, Row } from "reactstrap"
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { Card } from "reactstrap"

// Import Components
import MonthlyEarning from "./MonthlyEarning"
import WelcomeComp from "./WelcomeComp"
import { Link, useNavigate } from "react-router-dom"
import { CardBody } from "reactstrap"

//redux
import { getChartsData as onGetChartsData } from "../../store/actions"
import { useSelector, useDispatch } from "react-redux"

//i18n
import { withTranslation } from "react-i18next"
import axios from "axios"
import Loader from "pages/Loader"

const Dashboard = props => {
  const { chartsData } = useSelector(state => ({
    chartsData: state.Dashboard.chartsData,
  }))

  const [periodData, setPeriodData] = useState([])
  const [periodType, setPeriodType] = useState("yearly")

  const [allDietician, setAllDietician] = useState("")
  const [activeDietician, setActiveDietician] = useState("")
  const [inActiveDietician, setInActiveDietician] = useState("")
  const [loading, setLoading] = useState(false)

  const navigate = useNavigate()

  useEffect(() => {
    if (localStorage.getItem("adminUser") && localStorage.getItem("adminId")) {
      navigate("/admin-dashboard")
    }
  }, [])

  useEffect(() => {
    setPeriodData(chartsData)
  }, [chartsData])

  useEffect(() => {
    const getDieticianData = async () => {
      setLoading(true)
      try {
        const response = await axios.get(
          "https://api.nutriconnectpro.com/api/view_all_dietician",
          {
            headers: {
              Accept: "application/json",
              "Content-Type": "multipart/form-data",
            },
          }
        )

        setAllDietician(response.data.data.length)

        setActiveDietician(
          response.data.data.filter(diet => diet.is_active == 1).length
        )
        setInActiveDietician(
          response.data.data.filter(diet => diet.is_active == 0).length
        )
        setLoading(false)
      } catch (error) {
        console.log(error)
      }
    }
    getDieticianData()
  }, [])

  const onChangeChartPeriod = pType => {
    setPeriodType(pType)
    dispatch(onGetChartsData(pType))
  }

  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(onGetChartsData("yearly"))
  }, [dispatch])

  //meta title
  document.title = "Dashboard | Nutri Connect Pro "

  const reports = [
    {
      title: "All Dieticians",
      iconClass: "fas fa-users",
      description: allDietician,
      color: "purple",
      textColor: "#3b2d8e",
    },
    {
      title: "Active Dieticians",
      iconClass: "fas fa-check-circle ",
      description: activeDietician,
      color: "pink",
      textColor: "#911b91",
    },
    {
      title: "Inactive Dieticians",
      iconClass: "bx bx-purchase-tag-alt",
      description: inActiveDietician,
      color: "yellow",
      textColor: "#735521",
    },
  ]

  return (
    <React.Fragment>
      {loading && <Loader />}

      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title={props.t("Dashboard")}
            breadcrumbItem={props.t("Dashboard")}
          />

          <Row className="px-0">
            <Col xl="4" className="ps-md-0">
              <WelcomeComp />
              <MonthlyEarning />
            </Col>
            <Col xl="8" className="pe-md-0">
              <Row>
                {/* Reports Render */}
                {reports.map((report, key) => (
                  <Col md="4" key={"_col_" + key}>
                    <Card className="mini-stats-wid">
                      <CardBody>
                        <div className="d-flex">
                          <div className="flex-grow-1">
                            <p className="text-muted fw-medium">
                              {report.title}
                            </p>
                            <h4 className="mb-0">{report.description}</h4>
                          </div>
                          <div className="avatar-sm rounded-circle bg-primary align-self-center mini-stat-icon">
                            <span
                              className={`avatar-title rounded-circle bg-light-${report.color}`}
                            >
                              <i
                                className={report.iconClass + " fs-3"}
                                style={{ color: report.textColor }}
                              ></i>
                            </span>
                          </div>
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                ))}
              </Row>

              <Row>
                <div className="col-12">
                  <Card>
                    <CardBody>
                      <div className="row mx- text-center">
                        <div className="col-md-6">
                          <div
                            className="add-dieticians rounded p-4 "
                            style={{ backgroundColor: "#beb2ff20" }}
                          >
                            <h4 className="text-dark fw-bold mb-4">
                              <i className="fas fa-user-plus pe-3 border-end border-dark"></i>{" "}
                              <span className="ps-2">Add Dieticians</span>
                            </h4>

                            <Link
                              to="/add-dietician"
                              className="btn btn-green btn-yellow border-radius px-4 py-2"
                            >
                              <i className="fas fa-plus me-2"></i> Add Dietician
                            </Link>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div
                            className="view-dieticians rounded p-4 "
                            style={{ backgroundColor: "#beb2ff20" }}
                          >
                            <h4 className="text-dark fw-bold mb-4">
                              <i className="fas fa-users pe-3 border-end border-dark"></i>{" "}
                              <span className="ps-2">View Dieticians</span>
                            </h4>

                            <Link
                              to="/view-dietician"
                              className="btn btn-green btn-purple border-radius px-4 py-2"
                            >
                              <i className="fas fa-eye me-2"></i> View Dietician
                            </Link>
                          </div>
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </div>

                {/* <div className="col-md-6">
                  <Card>
                    <CardBody>

                    </CardBody>
                  </Card>
                </div> */}
              </Row>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withTranslation()(Dashboard)
