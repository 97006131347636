import axios from "axios"
import { getDecryptedData } from "pages/Config"
import React, { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"

const AllFollowUps = () => {
  const [allFollowUp, setAllFollowUp] = useState([])
  const [loading, setLoading] = useState(false)

  const navigate = useNavigate()

  useEffect(() => {
    const formData = new FormData()

    formData.append("added_by", localStorage.getItem("adminId"))

    axios
      .post("https://api.nutriconnectpro.com/api/followup_list", formData, {
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      })
      .then(response => {
        setLoading(false)

        const originalData = response.data.data.map(followup => ({
          ...followup,
          description: getDecryptedData(followup.description),
        }))

        setAllFollowUp(originalData)
      })
      .catch(err => console.log(err))
  }, [])

  const onDoneFollowUp = (added_by, follow_id) => {
    swal({
      title: "Are you sure?",
      text: "You are done with this follow up?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    })
      .then(willDelete => {
        if (willDelete) {
          const formData = new FormData()

          formData.append("followup_id", follow_id)
          formData.append("added_by", added_by)

          axios
            .post(
              "https://api.nutriconnectpro.com/api/done_followup",
              formData,
              {
                headers: {
                  Accept: "application/json",
                  "Content-Type": "multipart/form-data",
                },
              }
            )
            .then(response => {
              const originalData = response.data.data.map(followup => ({
                ...followup,
                description: getDecryptedData(followup.description),
              }))

              setAllFollowUp(originalData)

              swal("Follow up done successfully!", {
                icon: "success",
              })
            })
        }
      })
      .catch(err => {
        console.log(err)
      })
  }

  return (
    <>
      <h5 className="fw-bold mb-3">Follow Up</h5>

      <div>
        <ul className="ps-0">
          {allFollowUp.map((followup, i) => {
            return (
              <li
                key={followup.id}
                className={`fs-6 p-2 d-flex ${
                  i % 2 == 0 ? "bg-light" : ""
                }  justify-content-between align-items-center `}
              >
                <div className="d-flex justify-content-between align-items-center">
                  <button
                    onClick={() => {
                      navigate(`/edit-client/${followup.client_id}`, {
                        state: {
                          data: {
                            tabIndex: "4",
                            followup_id: followup.id,
                            desc: followup.description,
                            noteId: followup.note_id,
                            date: followup.date,
                          },
                        },
                      })
                    }}
                    className="btn btn-outline-secondary btn-sm"
                  >
                    <i className="fas fa-pen"></i>
                  </button>
                  {/* <i className="bx bx-right-arrow-circle font-size-18 me-2"></i> */}
                  <span
                    className="px-2 dash-followUp"
                    dangerouslySetInnerHTML={{ __html: followup.description }}
                  ></span>
                </div>

                <div>
                  <button
                    onClick={() =>
                      onDoneFollowUp(followup.added_by, followup.id)
                    }
                    className="btn btn-success btn-sm me-2"
                  >
                    <i className="fas fa-check me-1"></i> Done
                  </button>
                </div>
              </li>
            )
          })}
        </ul>
        <div>
          {allFollowUp.length == 0 && "Follow Ups will be appear here.."}
        </div>
      </div>
    </>
  )
}

export default AllFollowUps
