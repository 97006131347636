import React, { useState, useEffect, Fragment, useMemo } from "react"
import { Col, Container, Modal, Row } from "reactstrap"
//Import Breadcrumb
import Breadcrumbs from "../../../../components/Common/Breadcrumb"
import { Card, Button, Table } from "reactstrap"
import SearchFilter from "components/Common/SearchFilter"

import { Link, useNavigate } from "react-router-dom"
import { CardBody } from "reactstrap"

import {
  useTable,
  useGlobalFilter,
  useSortBy,
  useFilters,
  useExpanded,
  usePagination,
} from "react-table"
import swal from "sweetalert"
import Loader from "pages/Loader"
import axios from "axios"
import moment from "moment"

import { getEncryptedData, getDecryptedData } from "pages/Config"

const ActiveClient = () => {
  const [fixedContent, setFixedContent] = useState(false)

  const [allClientData, setAllClientData] = useState([])
  const [loading, setLoading] = useState(false)

  const [packages, setPackages] = useState([])
  const [viewModal, setViewModal] = useState(false)
  const [allProgress, setAllProgress] = useState([])
  const [gender, setGender] = useState("")

  const navigate = useNavigate()

  useEffect(() => {
    if (localStorage.getItem("sAdminUser") && localStorage.getItem("superId")) {
      navigate("/dashboard")
    }
  }, [])

  // getting client data
  useEffect(() => {
    const getClientsData = async () => {
      setLoading(true)
      try {
        const formData = new FormData()
        formData.append("added_by", localStorage.getItem("adminId"))

        const response = await axios.post(
          "https://api.nutriconnectpro.com/api/client_listing",
          formData,
          {
            headers: {
              Accept: "application/json",
              "Content-Type": "multipart/form-data",
            },
          }
        )

        const originalData = response.data.data.map(clients => ({
          ...clients,
          fullname: getDecryptedData(clients.fullname),
        }))
        const filteredData = originalData.filter(
          client => client.is_active === 1
        )
        setAllClientData(filteredData)
        setLoading(false)

        const formData2 = new FormData()
        formData2.append("added_by", localStorage.getItem("adminId"))
        // getting Packages details
        const res = await axios.post(
          "https://api.nutriconnectpro.com/api/getpackages",
          formData2
        )
        setPackages(res.data.data)
      } catch (error) {
        console.log(error)
      }
    }
    getClientsData()
  }, [])

  document.addEventListener("scroll", () => {
    if (window.scrollY > 150) {
      setFixedContent(true)
    } else {
      setFixedContent(false)
    }
  })
  //meta title
  document.title = "All Clients | Nutri Connect Pro "

  const onDeleteClient = client_id => {
    swal({
      title: "Are you sure?",
      text: "do you really want to delete this client",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then(willDelete => {
      if (willDelete) {
        const formData = new FormData()

        formData.append("client_id", client_id)
        formData.append("added_by", localStorage.getItem("adminId"))

        axios
          .post("https://api.nutriconnectpro.com/api/delete_client", formData, {
            headers: {
              Accept: "application/json",
              "Content-Type": "multipart/form-data",
            },
          })
          .then(response => {
            const originalData = response.data.data.map(clients => ({
              ...clients,
              fullname: getDecryptedData(clients.fullname),
            }))
            const filteredData = originalData.filter(
              client => client.is_active === 1
            )

            setAllClientData(filteredData)

            swal("Client has been deleted successfully!", {
              icon: "success",
            })
          })
      }
    })
  }

  const getProgress = client_Id => {
    const formData = new FormData()

    formData.append("client_id", client_Id)

    axios
      .post("https://api.nutriconnectpro.com/api/client_progress", formData, {
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      })
      .then(response => {
        const originalData = response.data.data.map(hData => ({
          ...hData,
          chest: getDecryptedData(hData.chest),
          breast: getDecryptedData(hData.breast),
          waist: getDecryptedData(hData.waist),
          arms: getDecryptedData(hData.arms),
          hips: getDecryptedData(hData.hips),
          thighs: getDecryptedData(hData.thighs),
          chest_unit: getDecryptedData(hData.chest_unit),
          breast_unit: getDecryptedData(hData.breast_unit),
          waist_unit: getDecryptedData(hData.waist_unit),
          arms_unit: getDecryptedData(hData.arms_unit),
          hips_unit: getDecryptedData(hData.hips_unit),
          thighs_unit: getDecryptedData(hData.thighs_unit),
        }))

        setAllProgress(originalData)
        setViewModal(true)
      })
  }

  // changeStatus
  const changeStatus = (status, d_id) => {
    swal({
      title: "Are you sure?",
      text: `you want to ${status == 1 ? "InActive" : "Active"} this Client`,
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then(willDelete => {
      if (willDelete) {
        const formData = new FormData()

        formData.append("status", status == 1 ? 0 : 1)
        formData.append("client_id", d_id)
        formData.append("added_by", localStorage.getItem("adminId"))

        axios
          .post("https://api.nutriconnectpro.com/api/client_status", formData, {
            headers: {
              Accept: "application/json",
              "Content-Type": "multipart/form-data",
            },
          })
          .then(response => {
            const originalData = response.data.data.map(clients => ({
              ...clients,
              fullname: getDecryptedData(clients.fullname),
            }))
            const filteredData = originalData.filter(
              client => client.is_active === 1
            )

            setAllClientData(filteredData)
          })
      } else {
        setAllClientData(prevData => [...prevData])
      }
    })
  }
  const handleNavigation = props => {
    navigate("/add-diet-plan/", {
      state: props,
    })
  }

  const columns = useMemo(
    () => [
      {
        Header: "ID",
        accessor: "id",
        filterable: true,
        disableFilters: true,
        className: "col-md-1 text-center",
        Cell: cellProps => {
          return <span>{cellProps.row.index + 1}</span>
        },
      },
      {
        Header: "Client Name",
        accessor: "fullname",
        disableFilters: true,
        filterable: true,
        className: "col-md-3",
      },
      {
        Header: "Package Name",
        accessor: "package",
        disableFilters: true,
        filterable: true,
        className: "text-center",
        Cell: cellProps => {
          return (
            <span>
              {getDecryptedData(
                packages.filter(
                  pack => cellProps.row.original.package == pack.id
                )[0]?.package_name
              )}
            </span>
          )
        },
      },
      {
        Header: "Start Date",
        accessor: "startdate_of_package",
        disableFilters: true,
        filterable: true,
        className: "col-md-1 text-center",
        Cell: cellProps => {
          return (
            <span>
              {moment(cellProps.row.original.startdate_of_package).format(
                "DD-MM-YYYY"
              )}
            </span>
          )
        },
      },
      {
        Header: "Status",
        accessor: "status",
        disableFilters: true,
        filterable: true,
        className: "col-md-1 text-center",
        Cell: cellProps => {
          return (
            <div className="text-center">
              <div className="form-switch form-switch-md">
                <input
                  type="checkbox"
                  className="form-check-input second_view"
                  onChange={() =>
                    changeStatus(
                      cellProps.row.original.is_active,
                      cellProps.row.original.id
                    )
                  }
                  style={{ cursor: "pointer" }}
                  defaultChecked={
                    cellProps.row.original.is_active == 1 ? true : false
                  }
                />
              </div>
              {/* <select name="" value={cellProps.data[cellProps.row.index].status} id=""
                                className={`btn ${cellProps.data[cellProps.row.index].status === "Active" ? "bg-green" : "btn-red"} btn-soft py-1`}>
                                <option value="Active">Active</option>
                                <option value="InActive">InActive</option>
                            </select> */}
            </div>
          )
        },
      },
      {
        Header: "Action",
        disableFilters: true,
        accessor: "view",
        className: " text-center",
        Cell: cellProps => {
          return (
            <div>
              <button
                // onClick={navigate("/add-diet-plan/", {
                //   state: cellProps.row.original,
                // })}
                onClick={() => handleNavigation(cellProps.row.original)}
                // to={`/add-diet-plan/${cellProps.row.original.id}`}
                className="btn bg-light-blue btn-sm"
              >
                <i className="fas fa-plus me-1"></i>{" "}
                <span className="d-md-inline d-none">Add Diet Plan</span>
                <span className="d-md-none d-inline">Diet</span>
              </button>
              <button
                onClick={() => {
                  setGender(getDecryptedData(cellProps.row.original.gender))
                  getProgress(cellProps.row.original.id)
                }}
                className="btn bg-success bg-soft btn-sm ml-10"
              >
                {" "}
                Progess
              </button>
              <Link
                to={`/edit-client/${cellProps.row.original.id}`}
                className="btn btn-outline-secondary btn-sm ml-10"
              >
                <i className="fas fa-pen"></i>
              </Link>
              <button
                onClick={() => onDeleteClient(cellProps.row.original.id)}
                className="btn btn-red btn-sm ml-10"
              >
                <i className="fas fa-trash"></i>
              </button>
            </div>
          )
        },
      },
    ],
    [allClientData, packages]
  )

  const data = useMemo(() => allClientData, [allClientData])

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state,
    preGlobalFilteredRows,
    setGlobalFilter,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
    },
    useGlobalFilter,
    useFilters,
    useSortBy,
    useExpanded,
    usePagination
  )

  const generateSortingIndicator = column => {
    return column.isSorted ? (column.isSortedDesc ? " 🔽" : " 🔼") : ""
  }

  const onChangeInSelect = event => {
    setPageSize(Number(event.target.value))
  }

  return (
    <React.Fragment>
      {loading && <Loader />}

      {/* fixed buttons */}
      <div
        className={`flex-column fixed-buttons ${
          fixedContent ? "d-flex" : "d-none"
        }`}
      >
        <button className="btn btn-yellow border-radius fix-btn">
          <i className="fas fa-plus me-2"></i>
          <span>Client</span>
        </button>
        <button
          onClick={() => navigate(-1)}
          className="btn btn-dark border-radius fix-btn"
        >
          <i className="fas fa-arrow-left me-2 icon-center"></i>
          <span>Back</span>
        </button>
      </div>

      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title={"Dashboard"} breadcrumbItem={"All Clients"} />

          <Row>
            <Card className="px-0 mx-0">
              <CardBody>
                <Row className=" justify-space-between flex-md-row flex-column-reverse">
                  <Col md={2}>
                    <select
                      className="form-select border-radius d-md-block d-none"
                      value={pageSize}
                      onChange={onChangeInSelect}
                    >
                      {[10, 20, 30, 40, 50].map(pageSize => (
                        <option key={pageSize} value={pageSize}>
                          Show {pageSize}
                        </option>
                      ))}
                    </select>

                    <div className="d-md-none mt-3">
                      <SearchFilter
                        preGlobalFilteredRows={preGlobalFilteredRows}
                        globalFilter={state.globalFilter}
                        setGlobalFilter={setGlobalFilter}
                      />
                    </div>
                  </Col>
                  <div className="d-flex col-md-8 justify-content-md-end justify-content-between align-items-start ">
                    <div className="d-md-block d-none">
                      <SearchFilter
                        preGlobalFilteredRows={preGlobalFilteredRows}
                        globalFilter={state.globalFilter}
                        setGlobalFilter={setGlobalFilter}
                      />
                    </div>

                    <select
                      className="form-select d-md-none w-50 border-radius"
                      value={pageSize}
                      onChange={onChangeInSelect}
                    >
                      {[10, 20, 30, 40, 50].map(pageSize => (
                        <option key={pageSize} value={pageSize}>
                          Show {pageSize}
                        </option>
                      ))}
                    </select>

                    <Link
                      to="/admin-dashboard"
                      className="btn btn-dark border-radius ms-4"
                    >
                      <i className="fas fa-arrow-left me-3"></i>
                      Back
                    </Link>
                    <Link
                      to="/add-client"
                      className="btn btn-green btn-yellow border-radius ms-3"
                    >
                      <i className="fas fa-plus me-2"></i>
                      Add New Client
                    </Link>
                  </div>
                </Row>

                <div className="table-responsive mt-4">
                  <Table
                    bordered
                    {...getTableProps()}
                    className="w-800 responsive-table responsive"
                    style={{ verticalAlign: "middle" }}
                  >
                    <thead className="table-green table-nowrap">
                      {headerGroups.map(headerGroup => (
                        <tr
                          key={headerGroup.id}
                          {...headerGroup.getHeaderGroupProps()}
                        >
                          {headerGroup.headers.map(column => (
                            <th key={column.id} className={column.className}>
                              <div {...column.getSortByToggleProps()}>
                                {column.render("Header")}
                                {generateSortingIndicator(column)}
                              </div>
                              {/* <Filter column={column} /> */}
                            </th>
                          ))}
                        </tr>
                      ))}
                    </thead>

                    <tbody className="row-hover" {...getTableBodyProps()}>
                      {page.map(row => {
                        prepareRow(row)
                        return (
                          <Fragment key={row.getRowProps().key}>
                            <tr>
                              {row.cells.map(cell => {
                                return (
                                  <td
                                    key={cell.id}
                                    data-label={cell.column.Header}
                                    {...cell.getCellProps()}
                                    className={cell.column.className}
                                  >
                                    {cell.render("Cell")}
                                  </td>
                                )
                              })}
                            </tr>
                          </Fragment>
                        )
                      })}

                      {page.length === 0 && (
                        <tr>
                          <td colSpan={7} className="text-center">
                            No Client Found
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </Table>
                </div>

                <Row className="justify-content-md-end justify-content-center align-items-center mt-3">
                  <Col className="col-md-auto">
                    <div className="d-flex gap-1">
                      <Button
                        className="btn-green bg-light-purple"
                        onClick={() => gotoPage(0)}
                        disabled={!canPreviousPage}
                      >
                        {"<<"}
                      </Button>
                      <Button
                        className="btn-green bg-light-purple"
                        onClick={previousPage}
                        disabled={!canPreviousPage}
                      >
                        {"<"}
                      </Button>
                    </div>
                  </Col>
                  <Col className="col-md-auto d-none d-md-block">
                    Page{" "}
                    <strong>
                      {pageIndex + 1} of {pageOptions.length}
                    </strong>
                  </Col>

                  <Col className="col-md-auto">
                    <div className="d-flex gap-1">
                      <Button
                        className="btn-green bg-light-purple"
                        onClick={nextPage}
                        disabled={!canNextPage}
                      >
                        {">"}
                      </Button>
                      <Button
                        className="btn-green bg-light-purple"
                        onClick={() => gotoPage(pageCount - 1)}
                        disabled={!canNextPage}
                      >
                        {">>"}
                      </Button>
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Row>
        </Container>
      </div>

      {/* View Progress Modal */}
      <Modal isOpen={viewModal} size="lg" className="bd-custom-md-3" centered>
        <div className="modal-header justify-content-between">
          <h5 className="modal-title fw-bold text-purple mt-0">
            View Progress
          </h5>
          <button
            type="button"
            onClick={() => {
              setViewModal(false)
            }}
            className="btn-modal-close"
          >
            <span aria-hidden="true" className="btn-modal-span">
              &times;
            </span>
          </button>
        </div>
        <div className="modal-body ">
          <Table className="text-center" bordered>
            <thead className="table-green">
              <tr>
                <th>Date</th>
                {gender === "male" ? <th>Chest</th> : <th>Breast</th>}
                <th>Waist</th>
                <th>Hips</th>
                <th>Thighs</th>
                <th>Arms</th>
              </tr>
            </thead>

            <tbody>
              {allProgress?.map((progress, i) => {
                return (
                  <tr key={i}>
                    <td>{moment(progress.created_at).format("DD-MM-YYYY")}</td>
                    {gender === "male" ? (
                      <td>{progress.chest + " " + progress.chest_unit}</td>
                    ) : (
                      <td>{progress.breast + " " + progress.breast_unit}</td>
                    )}
                    <td>{progress.waist + " " + progress.waist_unit}</td>
                    <td>{progress.hips + " " + progress.hips_unit}</td>
                    <td>{progress.thighs + " " + progress.thighs_unit}</td>
                    <td>{progress.arms + " " + progress.arms_unit}</td>
                  </tr>
                )
              })}

              {allProgress.length === 0 && (
                <tr>
                  <td colSpan={6} className="text-center">
                    {" "}
                    No Progress Found{" "}
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
        </div>
      </Modal>
      {/* -------------- */}
    </React.Fragment>
  )
}

export default ActiveClient
