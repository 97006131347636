import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap"

//i18n
import { withTranslation } from "react-i18next"
// Redux
import { connect } from "react-redux"
import { Link, Navigate, useNavigate } from "react-router-dom"
import withRouter from "components/Common/withRouter"

// users
import user1 from "../../../assets/default-user.png"
import { getDecryptedData } from "pages/Config"
import { ConfigUrl } from "ConfigUrl"

const ProfileMenu = props => {
  // Declare a new state variable, which we'll call "menu"
  const [menu, setMenu] = useState(false)

  const [username, setusername] = useState("Admin")

  useEffect(() => {
    if (localStorage.getItem("authUser")) {
      if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
        const obj = JSON.parse(localStorage.getItem("authUser"))
        setusername(obj.displayName)
      } else if (
        process.env.REACT_APP_DEFAULTAUTH === "fake" ||
        process.env.REACT_APP_DEFAULTAUTH === "jwt"
      ) {
        const obj = JSON.parse(localStorage.getItem("authUser"))
        setusername(obj.username)
      }
    }
  }, [props.success])

  const navigate = useNavigate()

  const logoutAdmin = () => {
    localStorage.removeItem("sAdminUser")
    localStorage.removeItem("superId")
    localStorage.removeItem("adminUser")
    localStorage.removeItem("adminId")
    localStorage.removeItem("created_at")
    navigate(`${ConfigUrl.appUrl}login`)
  }

  return (
    <React.Fragment>
      <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className="d-inline-block"
      >
        <DropdownToggle
          className="btn header-item "
          id="page-header-user-dropdown"
          tag="button"
        >
          <img
            className="rounded-circle header-profile-user border"
            src={user1}
            alt="Header Avatar"
          />
          <span className="d-none d-xl-inline-block ms-2 me-1 text-white">
            {localStorage.getItem("adminUser")
              ? getDecryptedData(localStorage.getItem("adminUser"))
              : localStorage.getItem("sAdminUser")}
          </span>
          <i className="mdi mdi-chevron-down d-none d-xl-inline-block text-white" />
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-end">
          {/* <div className="dropdown-divider" /> */}
          {localStorage.getItem("adminId") &&
            localStorage.getItem("adminUser") && (
              <Link
                to={`${ConfigUrl.appUrl}settings`}
                className="dropdown-item"
              >
                <i className="bx bx-cog bx-spin font-size-16 align-middle me-2" />
                <span>{props.t("Settings")}</span>
              </Link>
            )}
          {localStorage.getItem("superId") &&
            localStorage.getItem("sAdminUser") && (
              <Link
                to={`${ConfigUrl.appUrl}reset-password`}
                className="dropdown-item"
              >
                <i className="bx bx-cog font-size-16 align-middle me-2" />
                <span>{props.t("Reset Password")}</span>
              </Link>
            )}
          <button onClick={logoutAdmin} className="dropdown-item">
            <i className="bx bx-power-off  font-size-16 align-middle me-2 text-danger" />
            <span>{props.t("Logout")}</span>
          </button>
          {/* <Link to={`${ConfigUrl.appUrl}logout`} className="dropdown-item">
            <i className="bx bx-power-off font-size-16 align-middle me-1 text-danger" />
            <span>{props.t("Logout")}</span>
          </Link> */}
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  )
}

ProfileMenu.propTypes = {
  success: PropTypes.any,
  t: PropTypes.any,
}

const mapStatetoProps = state => {
  const { error, success } = state.Profile
  return { error, success }
}

export default withRouter(
  connect(mapStatetoProps, {})(withTranslation()(ProfileMenu))
)
