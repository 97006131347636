import React, { useState, useEffect, Fragment, useMemo } from "react"
import { Col, Container, Row, Table, Button, Modal, Form } from "reactstrap"
//Import Breadcrumb
import Breadcrumbs from "../../../../components/Common/Breadcrumb"
import { Card } from "reactstrap"

import { Link, useNavigate } from "react-router-dom"
import { CardBody } from "reactstrap"
import SearchFilter from "components/Common/SearchFilter"

import {
  useTable,
  useGlobalFilter,
  useSortBy,
  useFilters,
  useExpanded,
  usePagination,
} from "react-table"
import swal from "sweetalert"
import axios from "axios"
import Loader from "pages/Loader"
import { getEncryptedData, getDecryptedData } from "pages/Config"

const Packages = () => {
  const [addPackageModal, setAddPackageModal] = useState(false)
  const [editPackageModal, setEditPackageModal] = useState(false)

  // for add package
  const [packageName, setPackageName] = useState("")
  const [packageType, setPackageType] = useState("")
  const [duration, setDuration] = useState("")
  const [price, setPrice] = useState("")
  const [package_id, setPackageId] = useState("")

  const navigate = useNavigate()

  useEffect(() => {
    if (localStorage.getItem("sAdminUser") && localStorage.getItem("superId")) {
      navigate("/dashboard")
    }
  }, [])

  document.title = "Packages | Nutri Connect Pro"

  const [loading, setLoading] = useState(false)
  const [allPackages, setAllPackages] = useState([])

  useEffect(() => {
    setLoading(true)

    // getting Packages details
    const formData = new FormData()
    formData.append("added_by", localStorage.getItem("adminId"))

    axios
      .post("https://api.nutriconnectpro.com/api/getpackages", formData, {
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      })
      .then(response => {
        const originalData = response.data.data.map(packagee => ({
          ...packagee,
          package_name: getDecryptedData(packagee.package_name),
          price: getDecryptedData(packagee.price),
        }))

        setAllPackages(originalData)
        setLoading(false)
      })
      .catch(err => console.log(err))
  }, [])

  // Adding new package
  const handleAddPackage = async e => {
    e.preventDefault()

    try {
      const formData = new FormData()

      formData.append("package_name", getEncryptedData(packageName))
      formData.append("package_type", packageType)
      formData.append("duration", duration)
      formData.append("price", getEncryptedData(price))
      formData.append("added_by", localStorage.getItem("adminId"))

      const response = await axios.post(
        "https://api.nutriconnectpro.com/api/add_package",
        formData,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "multipart/form-data",
          },
        }
      )

      const originalData = response.data.data.map(packagee => ({
        ...packagee,
        package_name: getDecryptedData(packagee.package_name),
        price: getDecryptedData(packagee.price),
      }))

      setAllPackages(originalData)

      setAddPackageModal(false)

      swal("Package has been Added successfully!", {
        icon: "success",
      }).then(() => {
        setPackageName("")
        setPackageType("")
        setDuration("")
        setPrice("")
      })
    } catch (error) {
      console.log(error)
      swal("Something went wrong", "", "error")
    }
  }

  // Edit package
  const handleEditPackage = async e => {
    e.preventDefault()

    try {
      const formData = new FormData()

      formData.append("package_name", getEncryptedData(packageName))
      formData.append("package_type", packageType)
      formData.append("duration", duration)
      formData.append("price", getEncryptedData(price))
      formData.append("package_id", package_id)
      formData.append("added_by", localStorage.getItem("adminId"))

      const response = await axios.post(
        "https://api.nutriconnectpro.com/api/edit_package",
        formData,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "multipart/form-data",
          },
        }
      )

      const originalData = response.data.data.map(packagee => ({
        ...packagee,
        package_name: getDecryptedData(packagee.package_name),
        price: getDecryptedData(packagee.price),
      }))

      setAllPackages(originalData)

      setEditPackageModal(false)
      swal("Package has been Updated successfully!", {
        icon: "success",
      }).then(() => {
        setPackageName("")
        setPackageType("")
        setDuration("")
        setPrice("")
      })
    } catch (error) {
      console.log(error)
      swal("Something went wrong", "", "error")
    }
  }

  const onDeletePackage = packageId => {
    swal({
      title: "Are you sure?",
      text: "do you really want to remove this Package",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then(willDelete => {
      if (willDelete) {
        const formData = new FormData()

        formData.append("package_id", packageId)
        formData.append("added_by", localStorage.getItem("adminId"))

        axios
          .post(
            "https://api.nutriconnectpro.com/api/delete_package",
            formData,
            {
              headers: {
                Accept: "application/json",
                "Content-Type": "multipart/form-data",
              },
            }
          )
          .then(response => {
            const originalData = response.data.data.map(packagee => ({
              ...packagee,
              package_name: getDecryptedData(packagee.package_name),
              price: getDecryptedData(packagee.price),
            }))

            setAllPackages(originalData)

            swal("Package has been deleted successfully!", {
              icon: "success",
            })
          })
      }
    })
  }

  const columns = useMemo(
    () => [
      {
        Header: "ID",
        accessor: "id",
        filterable: true,
        disableFilters: true,
        className: "col-md-1 text-center",
        Cell: cellProps => <span>{cellProps.row.index + 1}</span>,
      },
      {
        Header: "Package Name",
        accessor: "package_name",
        disableFilters: true,
        filterable: true,
      },
      {
        Header: "Package Type",
        accessor: "package_type",
        disableFilters: true,
        filterable: true,
        className: "col-md-2 text-center",
      },
      {
        Header: "Duration",
        accessor: "duration",
        disableFilters: true,
        filterable: true,
        className: "col-md-1 text-center",
      },
      {
        Header: "Price",
        accessor: "price",
        disableFilters: true,
        filterable: true,
        className: "col-md-1 text-center",
      },
      {
        Header: "Action",
        disableFilters: true,
        accessor: "view",
        className: "col-md-2 text-center",
        Cell: cellProps => {
          return (
            <div>
              <button
                onClick={() => {
                  setPackageName(cellProps.row.original.package_name)
                  setPackageType(cellProps.row.original.package_type)
                  setDuration(cellProps.row.original.duration)
                  setPrice(cellProps.row.original.price)
                  setPackageId(cellProps.row.original.id)
                  setEditPackageModal(true)
                }}
                className="btn btn-sm me-1 bg-light-blue me-2"
              >
                <i className="fas fa-pencil-alt me-1"></i> Edit
              </button>
              <button
                onClick={() => onDeletePackage(cellProps.row.original.id)}
                className="btn btn-sm btn-red"
              >
                <i className="fas fa-trash-alt"></i>
              </button>
            </div>
          )
        },
      },
    ],
    []
  )

  const data = useMemo(() => allPackages, [allPackages])

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state,
    preGlobalFilteredRows,
    setGlobalFilter,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      initialState: {
        sortBy: [
          {
            desc: true,
          },
        ],
      },
    },
    useGlobalFilter,
    useFilters,
    useSortBy,
    useExpanded,
    usePagination
  )

  const generateSortingIndicator = column => {
    return column.isSorted ? (column.isSortedDesc ? " 🔽" : " 🔼") : ""
  }

  const onChangeInSelect = event => {
    setPageSize(Number(event.target.value))
  }

  return (
    <React.Fragment>
      <div className="page-content">
        {loading && <Loader />}

        <Container fluid>
          <Breadcrumbs title={"Dashboard"} breadcrumbItem={"Packages"} />
          <Row className="">
            <Card>
              <CardBody className="py-4 px-3">
                <Row className=" justify-space-between flex-md-row flex-column-reverse">
                  <Col md={2}>
                    <select
                      className="form-select border-radius d-md-block d-none"
                      value={pageSize}
                      onChange={onChangeInSelect}
                    >
                      {[10, 20, 30, 40, 50].map(pageSize => (
                        <option key={pageSize} value={pageSize}>
                          Show {pageSize}
                        </option>
                      ))}
                    </select>

                    <div className="d-md-none mt-3">
                      <SearchFilter
                        preGlobalFilteredRows={preGlobalFilteredRows}
                        globalFilter={state.globalFilter}
                        setGlobalFilter={setGlobalFilter}
                      />
                    </div>
                  </Col>
                  <div className="d-flex col-md-8 justify-content-md-end justify-content-between align-items-start ">
                    <div className="d-md-block d-none">
                      <SearchFilter
                        preGlobalFilteredRows={preGlobalFilteredRows}
                        globalFilter={state.globalFilter}
                        setGlobalFilter={setGlobalFilter}
                      />
                    </div>

                    <select
                      className="form-select d-md-none w-50 border-radius"
                      value={pageSize}
                      onChange={onChangeInSelect}
                    >
                      {[10, 20, 30, 40, 50].map(pageSize => (
                        <option key={pageSize} value={pageSize}>
                          Show {pageSize}
                        </option>
                      ))}
                    </select>

                    <Link
                      to="/admin-dashboard"
                      className="btn btn-dark border-radius ms-4"
                    >
                      <i className="fas fa-arrow-left me-3"></i>
                      Back
                    </Link>
                    <button
                      onClick={() => {
                        setPackageName("")
                        setPackageType("")
                        setDuration("")
                        setPrice("")
                        setAddPackageModal(true)
                      }}
                      className="btn btn-green bg-light-yellow border-radius ms-3"
                    >
                      <i className="fas fa-plus me-2"></i>
                      Add Package
                    </button>
                  </div>
                </Row>

                <div className="table-responsive mt-4">
                  <Table
                    bordered
                    {...getTableProps()}
                    className="w-800 responsive"
                    style={{ verticalAlign: "middle" }}
                  >
                    <thead className="table-green table-nowrap">
                      {headerGroups.map(headerGroup => (
                        <tr
                          key={headerGroup.id}
                          {...headerGroup.getHeaderGroupProps()}
                        >
                          {headerGroup.headers.map(column => (
                            <th key={column.id} className={column.className}>
                              <div {...column.getSortByToggleProps()}>
                                {column.render("Header")}
                                {generateSortingIndicator(column)}
                              </div>
                              {/* <Filter column={column} /> */}
                            </th>
                          ))}
                        </tr>
                      ))}
                    </thead>

                    <tbody className="row-hover" {...getTableBodyProps()}>
                      {page.map(row => {
                        prepareRow(row)
                        return (
                          <Fragment key={row.getRowProps().key}>
                            <tr>
                              {row.cells.map(cell => {
                                return (
                                  <td
                                    key={cell.id}
                                    data-label={cell.column.Header}
                                    {...cell.getCellProps()}
                                    className={cell.column.className}
                                  >
                                    {cell.render("Cell")}
                                  </td>
                                )
                              })}
                            </tr>
                          </Fragment>
                        )
                      })}

                      {page.length === 0 && (
                        <tr>
                          <td colSpan={7} className="text-center">
                            No Package Found
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </Table>
                </div>

                <Row className="justify-content-md-end justify-content-center align-items-center mt-3">
                  <Col className="col-md-auto">
                    <div className="d-flex gap-1">
                      <Button
                        className="btn-green bg-light-purple"
                        onClick={() => gotoPage(0)}
                        disabled={!canPreviousPage}
                      >
                        {"<<"}
                      </Button>
                      <Button
                        className="btn-green bg-light-purple"
                        onClick={previousPage}
                        disabled={!canPreviousPage}
                      >
                        {"<"}
                      </Button>
                    </div>
                  </Col>
                  <Col className="col-md-auto d-none d-md-block">
                    Page{" "}
                    <strong>
                      {pageIndex + 1} of {pageOptions.length}
                    </strong>
                  </Col>

                  <Col className="col-md-auto">
                    <div className="d-flex gap-1">
                      <Button
                        className="btn-green bg-light-purple"
                        onClick={nextPage}
                        disabled={!canNextPage}
                      >
                        {">"}
                      </Button>
                      <Button
                        className="btn-green bg-light-purple"
                        onClick={() => gotoPage(pageCount - 1)}
                        disabled={!canNextPage}
                      >
                        {">>"}
                      </Button>
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Row>
        </Container>
      </div>

      {/* Add Package Modal */}
      <Modal
        isOpen={addPackageModal}
        size="md"
        className="bd-custom-md-3"
        centered
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0 fw-bold text-purple">Add Package</h5>
          <button
            type="button"
            onClick={() => {
              setAddPackageModal(false)
            }}
            className="btn-modal-close"
          >
            <span aria-hidden="true" className="btn-modal-span">
              &times;
            </span>
          </button>
        </div>
        <div className="modal-body">
          <Form onSubmit={handleAddPackage} className="row align-items-end">
            <div className="col-12 mb-3">
              <label htmlFor="" className="fs-5">
                Package Name
              </label>
              <input
                type="text"
                className="form-field border-radius"
                value={packageName}
                onChange={e => setPackageName(e.target.value)}
                placeholder="Enter Package Name"
              />
            </div>
            <div className="mb-3 col-8">
              <label htmlFor="" className="fs-5">
                Package Type
              </label>
              <select
                className="form-field"
                value={packageType}
                onChange={e => setPackageType(e.target.value)}
                name=""
                id=""
              >
                <option value="Select Type">Select Type</option>
                <option value="Days">Days</option>
                <option value="Weekly">Weekly</option>
                <option value="Monthly">Monthly</option>
              </select>
            </div>
            <div className="mb-3 col-4">
              <label htmlFor="" className="fs-5">
                Duration
              </label>
              <input
                type="text"
                className="form-field border-radius"
                placeholder="Enter "
                value={duration}
                onChange={e => setDuration(e.target.value)}
              />
            </div>
            <div className="col-2 mb-3">
              <label htmlFor="" className="fs-5">
                Price
              </label>
              <input
                type="text"
                className="form-field border-radius"
                defaultValue="£"
                disabled
              />
            </div>
            <div className="col-10 mb-3 ps-0">
              {/* <label htmlFor="" className="fs-5">Price</label> */}
              <input
                type="text"
                className="form-field border-radius"
                placeholder="Enter Price"
                value={price}
                onChange={e => setPrice(e.target.value)}
              />
            </div>
            <div className="mt-2 text-end">
              <button
                type="button"
                onClick={() => setAddPackageModal(false)}
                className="border-radius btn btn-green btn-dark me-3"
              >
                <i className="fas fa-times me-2"></i>
                Close
              </button>

              <button
                type="submit"
                className="border-radius btn btn-green btn-yellow"
              >
                <i className="bx bx-send me-1"></i>
                Submit
              </button>
            </div>
          </Form>
        </div>
      </Modal>
      {/* ---------------------------------============================------------------------------------- */}

      {/* Edit Package Modal */}
      <Modal
        isOpen={editPackageModal}
        size="md"
        className="bd-custom-md-3"
        centered
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0 fw-bold text-purple">Edit Package</h5>
          <button
            type="button"
            onClick={() => {
              setEditPackageModal(false)
            }}
            className="btn-modal-close"
          >
            <span aria-hidden="true" className="btn-modal-span">
              &times;
            </span>
          </button>
        </div>
        <div className="modal-body">
          <Form onSubmit={handleEditPackage} className="row align-items-end">
            <div className="col-12 mb-3">
              <label htmlFor="" className="fs-5">
                Package Name
              </label>
              <input
                type="text"
                className="form-field border-radius"
                value={packageName}
                onChange={e => setPackageName(e.target.value)}
                placeholder="Enter Package Name"
              />
            </div>
            <div className="mb-3 col-8">
              <label htmlFor="" className="fs-5">
                Package Type
              </label>
              <select
                className="form-field"
                value={packageType}
                onChange={e => setPackageType(e.target.value)}
                name=""
                id=""
              >
                <option value="Select Type">Select Type</option>
                <option value="Days">Days</option>
                <option value="Weekly">Weekly</option>
                <option value="Monthly">Monthly</option>
              </select>
            </div>
            <div className="mb-3 col-4">
              <label htmlFor="" className="fs-5">
                Duration
              </label>
              <input
                type="text"
                className="form-field border-radius"
                placeholder="Enter "
                value={duration}
                onChange={e => setDuration(e.target.value)}
              />
            </div>
            <div className="col-2 mb-3">
              <label htmlFor="" className="fs-5">
                Price
              </label>
              <input
                type="text"
                className="form-field border-radius"
                defaultValue="£"
                disabled
              />
            </div>
            <div className="col-10 mb-3 ps-0">
              {/* <label htmlFor="" className="fs-5">Price</label> */}
              <input
                type="text"
                className="form-field border-radius"
                placeholder="Enter Price"
                value={price}
                onChange={e => setPrice(e.target.value)}
              />
            </div>
            <div className="mt-2 text-end">
              <button
                type="button"
                onClick={() => setEditPackageModal(false)}
                className="border-radius btn btn-green btn-dark me-3"
              >
                <i className="fas fa-times me-2"></i>
                Close
              </button>

              <button
                type="submit"
                className="border-radius btn btn-green btn-yellow"
              >
                <i className="bx bx-send me-1"></i>
                Submit
              </button>
            </div>
          </Form>
        </div>
      </Modal>
      {/* -------------- */}
    </React.Fragment>
  )
}

export default Packages
