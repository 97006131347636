import React, { useState, useEffect } from "react"
import { Col, Container, Row } from "reactstrap"
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { Card } from "reactstrap"

// editor
import { CKEditor } from "@ckeditor/ckeditor5-react"
import ClassicEditor from "@ckeditor/ckeditor5-build-classic"

import { Link, useNavigate } from "react-router-dom"
import { CardBody } from "reactstrap"
import swal from "sweetalert"
import Loader from "pages/Loader"
import axios from "axios"
import { getDecryptedData, getEncryptedData } from "pages/Config"

const InvoiceDetails = () => {
  const [logo, setLogo] = useState("") // Set the initial value for the logo URL
  const [company_name, setCompanyName] = useState("")
  const [name, setName] = useState("")
  const [invoice_prefix, setInvPrefix] = useState("")
  const [bank_name, setBankName] = useState("")
  const [branch_name, setBranchName] = useState("")
  const [account_name, setAccountName] = useState("")
  const [account_no, setAccountNo] = useState("")
  const [address, setAddress] = useState({
    line1: "",
    line2: "",
    line3: "",
  })
  // const [address2, setAddress2] = useState('');
  // const [address3, setAddress3] = useState('');

  const [loading, setLoading] = useState(false)

  const [ifsc_code, setIfscCode] = useState([])
  const [terms, setTerms] = useState("<p></p>")

  const navigate = useNavigate()

  useEffect(() => {
    setLoading(true)
    const formData = new FormData()

    formData.append("added_by", localStorage.getItem("adminId"))

    axios
      .post("https://api.nutriconnectpro.com/api/view_company", formData, {
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      })
      .then(response => {
        // console.log(response)

        if (response.data.data.invoice != null) {
          setName(getDecryptedData(response.data.data.invoice.name))
          setInvPrefix(response.data.data.invoice.invoice_prefix)
          setBankName(getDecryptedData(response.data.data.invoice.bank_name))
          setBranchName(
            getDecryptedData(response.data.data.invoice.branch_name)
          )
          setAccountName(
            getDecryptedData(response.data.data.invoice.account_name)
          )
          setAccountNo(getDecryptedData(response.data.data.invoice.account_no))
          setIfscCode(getDecryptedData(response.data.data.invoice.ifsc_code))
          setTerms(getDecryptedData(response.data.data.invoice.terms))
          setAddress(
            JSON.parse(getDecryptedData(response.data.data.invoice.address))
          )
        }

        setLoading(false)
      })
  }, [])

  const handleSubmitForm = async e => {
    e.preventDefault()
    setLoading(true)
    try {
      const formData = new FormData()
      formData.append("name", getEncryptedData(name))
      formData.append("invoice_prefix", invoice_prefix)
      formData.append("address", getEncryptedData(JSON.stringify(address)))
      formData.append("bank_name", getEncryptedData(bank_name))
      formData.append("branch_name", getEncryptedData(branch_name))
      formData.append("account_name", getEncryptedData(account_name))
      formData.append("account_no", getEncryptedData(account_no))
      formData.append("ifsc_code", getEncryptedData(ifsc_code))
      formData.append("terms", getEncryptedData(terms))
      formData.append("added_by", localStorage.getItem("adminId"))

      const response = await axios.post(
        "https://api.nutriconnectpro.com/api/add_invoice_details",
        formData,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "multipart/form-data",
          },
        }
      )

      setLoading(false)
      // console.log(response)

      swal("", "Invoice Details Saved Successfully", "success")
    } catch (error) {
      setLoading(false)
      console.log(error)
      swal("", "Something Went Wrong", "error")
    }
  }

  return (
    <React.Fragment>
      {loading && <Loader />}
      <Row className="mx-0">
        <form onSubmit={handleSubmitForm} className="px-0">
          <Card>
            <CardBody>
              <Row className="pb-2">
                <div className="col-md-6">
                  <h4 className="fw-bold">Invoice Details</h4>
                </div>
                <div className="col-6 mb-4 text-end">
                  <button
                    type="button"
                    onClick={() => navigate(-1)}
                    className="btn btn-dark border-radius me-3"
                  >
                    <i className="fas fa-arrow-left me-3"></i>
                    Back
                  </button>
                  <button
                    type="submit"
                    className="btn btn-green bg-light-yellow border-radius"
                  >
                    {" "}
                    <i className="bx bx-send me-1"></i> Submit{" "}
                  </button>
                </div>

                <div className="col-md-4">
                  <label htmlFor="" className="fs-5">
                    Name
                  </label>
                  <input
                    type="text"
                    value={name}
                    onChange={e => setName(e.target.value)}
                    placeholder="Enter Company Name"
                    className="form-field"
                  />
                </div>

                <div className="col-md-4">
                  <label htmlFor="" className="fs-5">
                    Invoice Prefix
                  </label>
                  <input
                    type="text"
                    value={invoice_prefix}
                    onChange={e => setInvPrefix(e.target.value)}
                    placeholder="Enter Invoice Prefix"
                    className="form-field"
                    required
                  />
                </div>

                <div className="col-md-6 mt-4">
                  <label htmlFor="" className="fs-5">
                    Address ( Line 1 )
                  </label>
                  <input
                    type="text"
                    value={address.line1}
                    onChange={e =>
                      setAddress(prev => ({ ...prev, line1: e.target.value }))
                    }
                    placeholder="Enter Full Address"
                    className="form-field"
                  />
                </div>

                <div className="col-md-6 mt-4">
                  <label htmlFor="" className="fs-5">
                    Address ( Line 2 )
                  </label>
                  <input
                    type="text"
                    value={address.line2}
                    onChange={e =>
                      setAddress(prev => ({ ...prev, line2: e.target.value }))
                    }
                    placeholder="Enter Full Address"
                    className="form-field"
                  />
                </div>

                <div className="col-md-6 mt-4">
                  <label htmlFor="" className="fs-5">
                    Address ( Line 3 )
                  </label>
                  <input
                    type="text"
                    value={address.line3}
                    onChange={e =>
                      setAddress(prev => ({ ...prev, line3: e.target.value }))
                    }
                    placeholder="Enter Full Address"
                    className="form-field"
                  />
                </div>
              </Row>
            </CardBody>
          </Card>

          <Card>
            <CardBody>
              <Row className="pb-2">
                <h4 className="fw-bold">Bank Details</h4>

                <div className="col-md-4 mt-4">
                  <label htmlFor="" className="fs-5">
                    Bank Name
                  </label>
                  <input
                    type="text"
                    value={bank_name}
                    onChange={e => setBankName(e.target.value)}
                    placeholder="Enter Bank Name"
                    className="form-field"
                  />
                </div>

                <div className="col-md-4 mt-4">
                  <label htmlFor="" className="fs-5">
                    Branch Name
                  </label>
                  <input
                    type="text"
                    value={branch_name}
                    onChange={e => setBranchName(e.target.value)}
                    placeholder="Enter Branch Name"
                    className="form-field"
                  />
                </div>

                <div className="col-md-4 mt-4">
                  <label htmlFor="" className="fs-5">
                    Account Name
                  </label>
                  <input
                    type="text"
                    value={account_name}
                    onChange={e => setAccountName(e.target.value)}
                    placeholder="Enter Account Name"
                    className="form-field"
                  />
                </div>

                <div className="col-md-4 mt-4">
                  <label htmlFor="" className="fs-5">
                    Account No
                  </label>
                  <input
                    type="number"
                    value={account_no}
                    onChange={e => setAccountNo(e.target.value)}
                    placeholder="Enter Account No"
                    className="form-field"
                  />
                </div>

                <div className="col-md-4 mt-4">
                  <label htmlFor="" className="fs-5">
                    IFSC Code
                  </label>
                  <input
                    type="text"
                    value={ifsc_code}
                    onChange={e => setIfscCode(e.target.value)}
                    placeholder="Enter IFSC Code"
                    className="form-field"
                  />
                </div>

                <div className="col-12 mt-4">
                  <label htmlFor="" className="fs-5">
                    Terms & Conditions
                  </label>
                  <CKEditor
                    editor={ClassicEditor}
                    data={terms}
                    onReady={editor => {
                      // You can store the "editor" and use when it is needed.
                    }}
                    onChange={(event, editor) => {
                      const data = editor.getData()
                      setTerms(data)
                    }}
                  />
                </div>
              </Row>
            </CardBody>
          </Card>
        </form>
      </Row>
    </React.Fragment>
  )
}

export default InvoiceDetails
