import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"

import { connect } from "react-redux"

import { Link, useLocation } from "react-router-dom"

// Redux Store
import { showRightSidebarAction, toggleLeftmenu } from "../../store/actions"
import ProfileMenu from "../CommonForBoth/TopbarDropdown/ProfileMenu"

// import Css
import "../../assets/css/Layout/Header.css"

//i18n
import { withTranslation } from "react-i18next"
import axios from "axios"
import { ConfigUrl } from "ConfigUrl"

const Header = props => {
  const [isSearch, setSearch] = useState(false)
  const [daysLeft, setDaysLeft] = useState(60)
  const createdDate = new Date(localStorage.getItem("created_at"))

  useEffect(() => {
    const intervalId = setInterval(() => {
      const currentDate = new Date()
      const timeDifference = currentDate.getTime() - createdDate.getTime()
      const elapsedDays = Math.floor(timeDifference / (1000 * 3600 * 24))

      const remainingDays = Math.max(0, 60 - elapsedDays)

      setDaysLeft(remainingDays)

      // If you want to stop the countdown after a certain number of days,
      // you can add a condition to clear the interval.
      if (remainingDays <= 0) {
        clearInterval(intervalId)
      }
    }, 1000) // Update every second

    // Cleanup the interval on component unmount
    return () => clearInterval(intervalId)
  }, [])

  const path = useLocation()

  // checking if admin Active or not
  useEffect(() => {
    const isActiveAdmin = async () => {
      try {
        const formData = new FormData()
        formData.append("dietician_id", localStorage.getItem("adminId"))

        const response = await axios.post(
          "https://api.nutriconnectpro.com/api/view_dietician",
          formData,
          {
            headers: {
              Accept: "application/json",
              "Content-Type": "multipart/form-data",
            },
          }
        )

        if (response.data.data[0].is_active == 0) {
          localStorage.removeItem("adminUser")
          localStorage.removeItem("adminId")
          localStorage.removeItem("created_at")
          navigate(`${ConfigUrl.appUrl}login`)
        }
      } catch (error) {
        console.log(error)
      }
    }
    if (
      localStorage.getItem("adminId") &&
      localStorage.getItem("adminUser") &&
      localStorage.getItem("created_at")
    ) {
      isActiveAdmin()
    }
  }, [path])

  return (
    <React.Fragment>
      <header id="page-topbar" className="Header">
        <div className="navbar-header px-0">
          <div className="d-flex">
            <div className="navbar-brand-box ps-1">
              <Link to={`${ConfigUrl.appUrl}`} className="logo logo-light">
                <span>
                  {/* <img src={logoLight} alt="JobFitMeter logo" className="job-logo" height="48" /> */}
                  <h2
                    className="fw-bold text-white text-uppercase mb-0"
                    style={{ textShadow: "1px 1px 2px gray" }}
                  >
                    Nutri Connect{" "}
                  </h2>
                </span>
              </Link>
            </div>

            <button
              type="button"
              className="btn btn-sm px-3 font-size-16 d-lg-none header-item"
              data-toggle="collapse"
              onClick={() => {
                props.toggleLeftmenu(!props.leftMenu)
              }}
              data-target="#topnav-menu-content"
            >
              <i className="fa fa-fw fa-bars" />
            </button>
          </div>

          <div className="d-flex">
            {/* Dropdowns */}
            <div className="d-flex align-items-center me-4">
              {localStorage.getItem("adminId") &&
                localStorage.getItem("adminUser") && (
                  <h5
                    className="mb-0 text-red"
                    style={{ color: "#e60404", fontSize: "18px" }}
                  >
                    {" "}
                    {daysLeft > 0 ? (
                      `${daysLeft} days left of Free trial`
                    ) : (
                      <span>Free Trial Expired</span>
                    )}{" "}
                  </h5>
                )}
            </div>

            <ProfileMenu />
            {/* <NotificationDropdown /> */}
          </div>
        </div>
      </header>
    </React.Fragment>
  )
}

Header.propTypes = {
  leftMenu: PropTypes.any,
  showRightSidebar: PropTypes.any,
  showRightSidebarAction: PropTypes.func,
  t: PropTypes.any,
  toggleLeftmenu: PropTypes.func,
}

const mapStatetoProps = state => {
  const { layoutType, showRightSidebar, leftMenu } = state.Layout
  return { layoutType, showRightSidebar, leftMenu }
}

export default connect(mapStatetoProps, {
  showRightSidebarAction,
  toggleLeftmenu,
})(withTranslation()(Header))
