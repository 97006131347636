import React, { useEffect, useState } from "react"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import {
    Container,
    Row,
    Col,
    Nav,
    NavItem,
    NavLink,
    TabContent,
    TabPane,
} from "reactstrap"
import classnames from "classnames"
import { useLocation, useNavigate, useParams } from "react-router-dom"
import Settings from "./Settings"
import InvoiceDetails from "./InvoiceDetails"

// import Loader from "pages/Separate/Loader"

// components

const SettingIndex = () => {
    const [fixedSideTab, setFixedSideTab] = useState(false)

    const [verticalActiveTab, setverticalActiveTab] = useState("1")

    const navigate = useNavigate()

    useEffect(() => {
        if (localStorage.getItem("sAdminUser") && localStorage.getItem("superId")) {
            navigate('/dashboard')
        }
    }, [])


    document.addEventListener("scroll", () => {
        if (window.scrollY > 68) {
            setFixedSideTab(true)
        } else {
            setFixedSideTab(false)
        }
    })

    document.title = "Settings | Nutri Connect Pro"

    const toggleVertical = tab => {
        if (verticalActiveTab !== tab) {
            setverticalActiveTab(tab)
        }
    }

    return (
        <React.Fragment>
            {/* {loading && <Loader />} */}
            <div className="page-content mb-4">
                <Container fluid>
                    <Breadcrumbs title={"Dashboard"} breadcrumbItem={"Settings"} />

                    <Row className="gx-4 justify-content-between di-flex align-items-top">
                        <Col md="2" className="px-0">
                            <Nav
                                pills
                                className={`side-tab flex-column px-2 py-3
                            ${fixedSideTab ? "fixed-side-tab" : ""} h-100`}
                            >
                                <NavItem>
                                    <NavLink
                                        style={{ cursor: "pointer" }}
                                        className={classnames({
                                            "mb-2": true,
                                            active: verticalActiveTab === "1",
                                        })}
                                        onClick={() => {
                                            toggleVertical("1")
                                        }}
                                    >
                                        General Details
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink
                                        style={{ cursor: "pointer" }}
                                        className={classnames({
                                            "mb-2": true,
                                            active: verticalActiveTab === "2",
                                        })}
                                        onClick={() => {
                                            toggleVertical("2")
                                        }}
                                    >
                                        Invoice Details
                                    </NavLink>
                                </NavItem>
                            </Nav>
                        </Col>
                        <Col md="10 pe-0">
                            <div className=" bg-transparent mb-0">
                                <TabContent
                                    activeTab={verticalActiveTab}
                                    className="text-muted mt-4 mt-md-0"
                                >
                                    <TabPane tabId="1">
                                        <Settings />
                                    </TabPane>
                                    <TabPane tabId="2">
                                        <InvoiceDetails />
                                    </TabPane>
                                </TabContent>
                            </div>
                        </Col>
                    </Row>
                </Container>

            </div>
        </React.Fragment>
    )
}

export default SettingIndex
