import { ConfigUrl } from "ConfigUrl"
import React from "react"
import { Navigate } from "react-router-dom"

const Authmiddleware = props => {
  if (
    !localStorage.getItem("adminUser") &&
    !localStorage.getItem("sAdminUser")
  ) {
    return (
      <Navigate
        to={{
          pathname: `${ConfigUrl.appUrl}login`,
          state: { from: props.location },
        }}
      />
    )
  }
  return <React.Fragment>{props.children}</React.Fragment>
}

export default Authmiddleware
